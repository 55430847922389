import * as userApi from "../../apis/UserApi";
import { IViewUserDto } from "./interfaces/IViewUserDto";

export async function getViewUserObj(userid: string) {
    const userDto = await userApi.GetUser(userid);
    const userObj: IViewUserDto = {
        id: userDto.id,
        bscId: userDto.bscId,
        firstName: userDto.firstName,
        lastName: userDto.lastName,
        emailAddress: userDto.emailAddress,
        isActive: userDto.isActive,
        approvedRequestCount: userDto.approvedRequestCount,
        deniedRequestCount: userDto.deniedRequestCount,
        cannotVerifyRequestCount: userDto.cannotVerifyRequestCount,
        roleList: userDto.roleList.map((role) => {
            return role.id;
        }),
        verifiedRequests: userDto.verifiedRequests,
    }
    return userObj;
}