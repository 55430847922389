import axios from "axios";
import { IDelayRequestDto } from "./interfaces/IDelayRequestDto";
import { IDelayRequestUpdateModel } from "./interfaces/IDelayRequestUpdateModel";
import { IDelayCommentsUpdateModel } from "./interfaces/IDelayCommentsUpdateModel";
import { IDelayRequestCreateModel } from "./interfaces/IDelayRequestCreateModel";
import { IRefNumbersDto } from "./interfaces/IRefNumbersDto";

export async function GetDelayRequestById(requestId: string) {
    const url = `/admin-delay-requests/GetByRequestId/?requestId=${requestId}`;
    const response = await axios.get<IDelayRequestDto>(url);
    return response.data;
}

export async function GetRequestsByReferenceNum(referenceNum: string) {
    const url = `/delay-request-list/GetByReferenceNumber/?referenceNumber=${referenceNum}`;
    const response = await axios.get<IDelayRequestDto>(url);
    return response.data;
}

export async function UpdateDelayRequest(delayRequestUpdate: IDelayRequestUpdateModel) {
    const url = `/admin-delay-requests/Update`;
    const response = await axios.post<IDelayRequestDto>(url, delayRequestUpdate);
    return response.data;
}

export async function UpdateDelayComments(delayRequestUpdate: IDelayCommentsUpdateModel) {
    const url = `/admin-delay-requests/UpdateComments`;
    const response = await axios.post<IDelayRequestDto>(url, delayRequestUpdate);
    return response.data;
}

export async function AddDelayRequest(requestToAdd: IDelayRequestCreateModel) {
    const url = `/admin-delay-requests/CreateWithAuth`;
    var response = await axios.post<IDelayRequestDto>(url, requestToAdd);
    return response.data;
}

export async function UpdateHardCopy(requestToUpdate: string) {
    var refDto: IRefNumbersDto = {
        RefNumbers: requestToUpdate.split(",")
    }
    const url = `/admin-delay-requests/UpdateHardCopyConfirmation/`;
    var response = await axios.post<IRefNumbersDto>(url, refDto);
    return response.status;
}

export async function UpdateAutoverified(requestToUpdate: string) {
    var refDto: IRefNumbersDto = {
        RefNumbers: requestToUpdate.split(",")
    }
    const url = `/admin-delay-requests/UpdateAutoverifiedEmail/`;
    var response = await axios.post<IRefNumbersDto>(url, refDto);
    return response.status;
}

export async function UpdateDispositionMultiRecords(requestToUpdate: string, dispositionValue: string, adminComments: string) {
    const url = `/admin-delay-requests/UpdateDispositionMultiRecords/?RefNumberString=${requestToUpdate}&dispositionValue=${dispositionValue}&comment=${adminComments}`;
    var response = await axios.post(url);
    return response.data;
}