import React from "react";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import moment from "moment";
import { DelayRouteCard } from "./DelayRouteCard";
import { EditAddressModal } from "./EditAddressModal";
import { IDelayRequestUpdateModel } from "../../apis/interfaces/IDelayRequestUpdateModel";
import { IDelayRouteListDto } from "../../apis/interfaces/IDelayRouteListDto";
// import { RouteComponentProps } from "react-router-dom";

export interface IRequestCardProps {
    RequestObj: IDelayRequestDto;
    updateRequestInfo: (requestUpdateModel: IDelayRequestUpdateModel) => void;
}

interface IRequestCardState {
    isAddressEditModalOpen: boolean;
    checkEmailSent: boolean;
    checkMailSent: boolean;
    minApprovalTime: string;
    maxDenialTime: string;
    timeWindowParam: string;
}

export class RequestCard extends React.Component<IRequestCardProps, IRequestCardState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isAddressEditModalOpen: false,
            checkEmailSent: false,
            checkMailSent: false,
            minApprovalTime: '',
            maxDenialTime: '',
            timeWindowParam: '',
        }
    }

    public componentWillMount() {
        if (this.props.RequestObj.responseEmailSentdate && this.props.RequestObj.responseEmailSentdate !== this.props.RequestObj.requestDate) {
            this.setState({ checkEmailSent: true });
        }
        if (this.props.RequestObj.hardCopyDownloadBy) {
            this.setState({ checkMailSent: true });
        }
        this.setState({
            minApprovalTime: this.props.RequestObj.processApprovalTime,
            maxDenialTime: this.props.RequestObj.processDenialTime,
            timeWindowParam: this.props.RequestObj.timeWindow,
        })
    }

    public render() {
        return (
            <div>
                <div className="card mt-2" >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-6 float-right">
                                <h5 className="card-title">Reference Number: {this.props.RequestObj.referenceNumber}</h5>
                            </div>
                            <div className="col-lg-6">
                                <h6 className="card-title">Service Date: {this.getFormatedServiceDate()}</h6>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-6 float-right">
                                <h6 className="card-subtitle mb-2 text-muted">{this.props.RequestObj.firstName} {this.props.RequestObj.lastName}</h6>
                            </div>
                            <div className="col-lg-6 float-right">
                                <h6 className="card-title">Request Date: {this.getFormatedRequestDate()} </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <h6 className="card-subtitle mb-2 text-muted">{this.props.RequestObj.emailAddress}</h6>
                                {/* {this.props.RequestObj.requestingHardCopyResponse && <mark>Customer is requesting hard copy response </mark>} */}
                            </div>
                            <div className="col-lg-6 float-right" id={`email_${this.props.RequestObj.requestingHardCopyResponse}${this.state.checkEmailSent}`}>
                                <h6 className="card-title">Email Sent Date: {this.getFormatedEmailSentDate()} </h6>
                            </div>
                            <div className="col-lg-6 float-right" id={`mail_${this.props.RequestObj.requestingHardCopyResponse}${this.state.checkMailSent}`}>
                                <h6 className="card-title">Document Sent Date: {this.getFormatedMailSentDate()} </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                {this.getAddressInfo()}
                                <button type="button" className="btn btn-info"
                                    onClick={this.toggleEditAddressModal}
                                >Edit Address</button>
                            </div>
                            <div className="col-lg-6">
                                <button id={`resendMail_${this.state.checkEmailSent}`} type="button" className="btn btn-info"
                                    onClick={this.resendEmailModal}
                                >Resend Email</button>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-lg-12">
                                <p className="mb-0">User Delay: <b> {this.props.RequestObj.lenthOfDelay} minutes</b></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <p className="mb-0">User Comments: {this.props.RequestObj.userComments}</p>
                            </div>
                        </div>
                        <div className="row">
                            {this.renderApprovedDeniedTime()}
                        </div>
                    </div>
                </div>
                <EditAddressModal isModalOpen={this.state.isAddressEditModalOpen}
                    toggleModal={this.toggleEditAddressModal}
                    requestObj={this.props.RequestObj}
                    updateRequestInfo={this.props.updateRequestInfo}
                />
            </div>
        )
    }

    private renderApprovedDeniedTime() {
        return (
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-sm-4">
                        <span className="mb-0">{this.state.minApprovalTime !== '' ? `Min. Approval Time: ${this.state.minApprovalTime}` : ''}</span>
                    </div>
                    <div className="col-sm-4">
                        <span className="mb-0">{this.state.maxDenialTime !== '' ? `Max Denied Time: ${this.state.maxDenialTime}` : ''}</span>
                    </div>
                    <div className="col-sm-4">
                        <span className="mb-0">{this.state.timeWindowParam !== '' ? `Start/End Time Window: ${this.state.timeWindowParam}` : ''}</span>
                    </div>
                </div>
            </div>
        )
    }
    private toggleEditAddressModal = () => {
        this.setState((state) => {
            return { isAddressEditModalOpen: !state.isAddressEditModalOpen };
        })
    }

    private getFormatedServiceDate = () => {
        const serviceDateStr = this.props.RequestObj.serviceDate;
        const serviceDateFormated = moment(serviceDateStr).format("MM/DD/YYYY dddd");
        return serviceDateFormated;
    }

    private getFormatedRequestDate = () => {
        const requestDateStr = this.props.RequestObj.requestDate;
        const requestDateFormated = moment(requestDateStr).format("MM/DD/YYYY dddd hh:mm A");
        return requestDateFormated;
    }

    private getFormatedEmailSentDate = () => {
        const requestDateStr = this.props.RequestObj.responseEmailSentdate;
        const requestDateFormated = moment(requestDateStr).format("MM/DD/YYYY dddd hh:mm A");
        return requestDateFormated;
    }

    private getFormatedMailSentDate = () => {
        const requestDateStr = this.props.RequestObj.hardCopyDownloadDate;
        const requestDateFormated = moment(requestDateStr).format("MM/DD/YYYY dddd hh:mm A");
        return requestDateFormated;
    }

    private getAddressInfo = () => {
        let { RequestObj } = this.props;
        return (
            <div>
                {RequestObj.addressStreet1 && <div className="card-subtitle mb-2 text-muted">{RequestObj.addressStreet1}</div>}
                {RequestObj.addressStreet2 && <div className="card-subtitle mb-2 text-muted">{RequestObj.addressStreet2}</div>}
                {RequestObj.addressStreet1 && <div className="card-subtitle mb-2 text-muted">{RequestObj.addressCity}, {RequestObj.addressState}, {RequestObj.addressZip}</div>}

            </div>
        );
    }

    private resendEmailModal = () => {
        const requestDateStr = this.props.RequestObj.responseEmailSentdate;
        const requestDateFormated = moment(requestDateStr).format("MM/DD/YYYY hh:mm A");
        const appendEmail = this.props.RequestObj.transitComments + '\n Email Sent on ' + requestDateFormated;
        var isDownloaded = false;
        if (this.props.RequestObj.requestingHardCopyResponse) {
            isDownloaded = true;
        }
        console.log(this.state.isAddressEditModalOpen);
        var requestUpdateModel: IDelayRequestUpdateModel = {
            requestId: this.props.RequestObj.id,
            dispositionId: Number(this.props.RequestObj.dispositionId),
            statusID: Number(this.props.RequestObj.statusId),
            emailAddress: this.props.RequestObj.emailAddress,
            transitComments: appendEmail,
            addressStreet1: this.props.RequestObj.addressStreet1,
            addressStreet2: this.props.RequestObj.addressStreet2,
            addressCity: this.props.RequestObj.addressCity,
            addressState: this.props.RequestObj.addressState,
            addressZip: this.props.RequestObj.addressZip,
            requestingHardCopyResponse: this.props.RequestObj.requestingHardCopyResponse,
            lastTransitUpdateDateKey: this.props.RequestObj.lastTransitUpdateDateKey,
            isHardCopyDownloaded: isDownloaded,
            dispositionSubmitBy: Number(this.props.RequestObj.dispositionSubmitBy),
            isAddressEdited: true,
            initialDispositionId: Number(this.props.RequestObj.initialDispositionId),
        };
        this.props.updateRequestInfo(requestUpdateModel);
    }

}