import React, { Component } from "react";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { RouteComponentProps } from "react-router-dom";
import { RequestDateForm } from "../RequestDateSearch/RequestDateForm";
import { ReferenceNumForm } from "../ReferenceNumSearch/ReferenceNumForm";
import { EmailSearchForm } from "../EmailSearch/EmailSearchForm";
import { ManualVerifySummary } from "./ManuaVerifySummary";

import moment from "moment";
import { DispositionDateForm } from "../DispositionDateSearch/DispositionDateForm";

export class SearchDashboardComponent extends Component<RouteComponentProps, {}> {

    public render() {
        const toDate = moment().toISOString();
        const fromDate = moment().add(-1, "d").toISOString();
        return (
            <div>
                <NavbarComponent activeCheck= "idSearchRequests"/>
                <div className="container">
                    <div className="card mt-4" >
                        <div className="card-body">
                            <h5 className="card-subtitle mb-2 text-muted">Search by Request Date</h5>
                            <RequestDateForm onReportGet={this.onRequestDateSearch} startDate={fromDate} endDate={toDate} />
                        </div>
                    </div>
                    <div className="card mt-2" >
                        <div className="card-body">
                            <h5 className="card-subtitle mb-2 text-muted">Search Using Reference Number</h5>
                            <ReferenceNumForm onSearchClick={this.onReferenceNumSearch} />
                        </div>
                    </div>
                    <div className="card mt-4" >
                        <div className="card-body">
                            <h5 className="card-subtitle mb-2 text-muted">Search Using Name</h5>
                            <EmailSearchForm onReportGet={this.onEmailSearch} emailAddress="" firstName="" lastName=""/>
                        </div>
                    </div>
                    <div className="card mt-4" >
                        <div className="card-body">
                            <h5 className="card-subtitle mb-2 text-muted">Search by Disposition Submit Date</h5>
                            <DispositionDateForm onReportGet={this.onDispositionDateSearch} startDate={fromDate} endDate={toDate} />
                        </div>
                    </div>
                    <div className="mt-2">
                        <ManualVerifySummary/>
                    </div>
                </div>
            </div>
        );


    }

    private onRequestDateSearch = (fromDateString: string, toDateIsoString: string) => {
        localStorage.setItem('searchGrid', `/RequestList/${fromDateString}/${toDateIsoString}`);
        this.props.history.push(`/RequestList/${fromDateString}/${toDateIsoString}`)
    }

    private onDispositionDateSearch = (fromDateString: string, toDateIsoString: string) => {
        localStorage.setItem('searchGrid', `/AdminDispositionList/${fromDateString}/${toDateIsoString}`);
        this.props.history.push(`/AdminDispositionList/${fromDateString}/${toDateIsoString}`)
    }

    private onReferenceNumSearch = (referenceNum: string) => {
        this.props.history.push(`/referenceNumSearch/${referenceNum}`)
    }

    private onEmailSearch = (emailAddress: string, firstName: string, lastName: string) => {
        if (!emailAddress){
            emailAddress = 'null'
        }
        if (!firstName){
            firstName = 'null'
        }
        localStorage.setItem('searchGrid', `/searchByName/${emailAddress}/${firstName}/${lastName}`);
        this.props.history.push(`/searchByName/${emailAddress}/${firstName}/${lastName}`)
    }

}