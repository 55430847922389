import React from "react";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";

import { ITableColumn } from "./gridTypes/ITableColumn";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import * as GridColumnFormaters from "./GridColumnFormaters";
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as DelayRequestApi from "../../apis/DelayRequestApi";
import * as delayRequestListApi from "../../apis/DelayRequestListApi";
import fileDownload from "js-file-download";
// import * as GridRowExpander from "./GridRowExpander";

export interface IRequestListGridProps {
    RequestDtoList: IDelayRequestDto[];
}
interface IHardCopyRequestComponentState {
    btnValue: string;
    btnValue0: string;
    selectList: Document | undefined;
}
export const requestStatusOptions = {
    New: 'New',
    AutoVerified: 'Verified (Auto)',
    PendingManualVerify: 'Pending manual',
    ManualVerified: 'Verified (Manual)'
};
export const requestDispositionOptions = {
    New: 'New',
    Approved: 'Approved',
    Denied: 'Denied',
    CannotVerify: 'CannotVerify'
};
  
export class RequestListGrid extends  React.Component<IRequestListGridProps, IHardCopyRequestComponentState> {

    constructor(props: any){
        super(props);
        this.state = {
            btnValue: '',
            btnValue0: '',
            selectList: undefined,
        }
        this.enableCheckout =  this.enableCheckout.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
    }


private gridColumns: ITableColumn[] = [
        {
            dataField: "id",
            text: "Id",
            hidden: true
        },
        {
            dataField:"referenceNumber",
            text:"Reference No",
            formatter: GridColumnFormaters.referenceNumFormater,
            filter: textFilter(),
            sort: true
        }, 
        {
            dataField: "requestDate",
            text: "Request Date",
            formatter: GridColumnFormaters.serviceDateFormater,
            filter: dateFilter(),
            sort: true
        },
        {
            dataField: 'routes',
            isDummyField: true,
            text: 'Routes',
            formatter: GridColumnFormaters.RouteIconListFormater,
            filter: textFilter({
                onFilter: GridColumnFormaters.RouteListCustomFilter
              })
        },
        {
            dataField: "serviceDate",
            text: "Service Date",
            formatter: GridColumnFormaters.serviceDateFormater,
            filter: dateFilter(),
            sort: true
        },
        {
            dataField: "firstName",
            text: "First Name ",
            filter: textFilter(),
            sort: true
        },
        {
            dataField: "lastName",
            text: "Last Name ",
            filter: textFilter(),
            sort: true
        },
        {
            dataField:"statusDescription",
            text: "Status",
            formatter: GridColumnFormaters.statusColumnFormater,
            filter: selectFilter({
                options: requestStatusOptions,
                caseSensitive: false,
            }),
            sort: true
        },
        {
            dataField: 'dispositionDescription',
            isDummyField: true,
            text: 'Disposition',
            formatter: GridColumnFormaters.dispositionColumnFormater,
            filter: selectFilter({
                options: requestDispositionOptions,
                caseSensitive: false,
            }),
            sort: true,
        },
          
     
    ];
    public render(){
        var rowSelected: '';
        const selectRow = {
            mode: 'checkbox',
            clicktoSelect: true,
            // clicktoExpand: true,
            onSelect: (row: any, isSelect: boolean, rowIndex: number, e: any) => {
                var stringSelect = this.state.btnValue0;
                if (isSelect){
                    stringSelect = stringSelect + row.referenceNumber + ",";
                    this.setState({
                        btnValue0: stringSelect
                    })
                } else {
                    if (stringSelect.includes(row.referenceNumber)){
                        const re = row.referenceNumber + ",";
                        stringSelect = stringSelect.replace(re, "")
                    }
                    this.setState({
                                btnValue0: stringSelect
                            })
                }
            },
            onSelectAll: (row: any, rows: any, isSelect: any, e: any) => {
                if (row){
                    Object.keys(rows).forEach(key => {
                        if(key === "0"){
                            rowSelected = rows[key].referenceNumber
                        } else {
                            rowSelected += "," + rows[key].referenceNumber
                        }
                    })
                    rowSelected += ","

                this.setState({btnValue0: rowSelected});
                } else {
                    this.setState({btnValue0: ""});
                }
            },
        }
        // const expandrow = {
        //     onlyOneExpanding: true,
        //     showExpandColumn: true,
        //     renderer: GridRowExpander.GetExpandedRow,
        // };
        const options = {
            sizePerPageList: [{
               text: '10', value: 10
           },{
               text: '25', value: 25
           },{
            text: '50', value: 50
        },{
            text: '100', value: 100
        }]
        }
        const defaultSorted = [{
            dataField:"id",
            order: 'asc'
        }]
        return (
            <div>
                <BootstrapTable keyField='id' 
                    data={ this.props.RequestDtoList } 
                    columns={ this.gridColumns }   
                     bootstrap4={true} 
                     pagination={ paginationFactory(options) }
                     filter={ filterFactory() }
                     selectRow = {selectRow}
                     defaultSorted = {defaultSorted}
                    //  expandRow={ expandrow }

                />
                <button className="btn btn-success " disabled= {!this.state.btnValue0} onClick={this.enableCheckout}> Download</button>
                <button className="btn btn-success" style={{marginLeft:"1%"} } disabled= {!this.state.btnValue} onClick={this.submitRequest}> Confirm Mail Sent </button>
                <br></br><br></br>
            </div>
        )
    }

    private enableCheckout = async () => {
        try {
            const downloadList = this.state.btnValue0.slice(0, -1);
            toast("Downloading "+ downloadList, { transition: Slide, type: toast.TYPE.INFO, });
            var hardCopyRequest = await delayRequestListApi.GetHardCopy(downloadList);
            fileDownload(hardCopyRequest, downloadList + ".pdf");
            this.setState({
                btnValue: this.state.btnValue0,
                btnValue0: '',
            })

        } catch (err) {
            toast(err, { transition: Slide, type: toast.TYPE.INFO, });
        }

    }

    private submitRequest = async () => {
        try {
            toast("Confirming Hard Copy Sent "+ this.state.btnValue.slice(0, -1), { transition: Slide, type: toast.TYPE.INFO, });
            var hardCopyUpdate = await DelayRequestApi.UpdateHardCopy(this.state.btnValue.slice(0, -1));
            if (hardCopyUpdate){
                this.setState({
                    btnValue0: '',
                    btnValue: ''
                })
                window.location.reload();
            }
        } catch (err){
            toast(err, { transition: Slide, type: toast.TYPE.INFO, });
        }
    }

}