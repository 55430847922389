import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { ErrorAlert } from "../common/ErrorAlert";
import { ViewUserFinalDispositionComponent } from "./ViewUserFinalDispositionComponent";

export class ViewUserComponent extends React.Component<RouteComponentProps<{ userid: string }>, {}> {
    public render() {
        if (this.props.match.params.userid) {
            return (
                <ViewUserFinalDispositionComponent userid={this.props.match.params.userid} pathname={this.props.location.pathname} />
            )
        } else {
            return (
            <div>
                <NavbarComponent activeCheck="idUsers" />
                <div className="container mt-2">
                    <ErrorAlert alertText="System error. Invalid state. Please refresh the page and try again" />
                </div>
            </div>
            );
        }
    }
}