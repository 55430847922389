import React from "react";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";

import { ITableColumn } from "./gridTypes/ITableColumn";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import * as GridColumnFormaters from "./GridColumnFormaters";
// import { constants } from "buffer";
// import * as GridRowExpander from "./GridRowExpander";
export interface IRequestListGridProps {
    RequestDtoList: IDelayRequestDto[];
}
export const requestStatusOptions = {
    New: 'New',
    AutoVerified: 'Verified (Auto)',
    PendingManualVerify: 'Pending manual',
    ManualVerified: 'Verified (Manual)',
};
export const requestDispositionOptions = {
    New: 'New',
    Approved: 'Approved',
    Denied: 'Denied',
    CannotVerify: 'CannotVerify'
};
  
export class RequestListGrid extends  React.Component<IRequestListGridProps, {}> {

    private gridColumns: ITableColumn[] = [
        {
            dataField: "id",
            text: "Id",
            hidden: true
        },
        {
            dataField:"referenceNumber",
            text:"Reference No",
            formatter: GridColumnFormaters.referenceNumFormater,
            filter: textFilter(),
            sort: true
        }, 
        {
            dataField: "requestDate",
            text: "Request Date",
            formatter: GridColumnFormaters.serviceDateFormater,
            filter: dateFilter(),
            sort: true
        },
        {
            dataField: 'routes',
            isDummyField: true,
            text: 'Routes',
            formatter: GridColumnFormaters.RouteIconListFormater,
            filter: textFilter({
                onFilter: GridColumnFormaters.RouteListCustomFilter
              })
        },
        {
            dataField: "serviceDate",
            text: "Service Date",
            formatter: GridColumnFormaters.serviceDateFormater,
            filter: dateFilter(),
            sort: true
        },
        {
            dataField: "firstName",
            text: "First Name ",
            filter: textFilter(),
            sort: true
        },
        {
            dataField: "lastName",
            text: "Last Name ",
            filter: textFilter(),
            sort: true
        },
        {
            dataField:"statusDescription",
            text: "Status",
            formatter: GridColumnFormaters.statusColumnFormater,
            filter: selectFilter({
                options: requestStatusOptions,
                caseSensitive: false,
                // withoutEmptyOption: true,
                // defaultValue: 'PendingManualVerify'
            }),
            sort: true
        },
        {
            dataField: 'dispositionDescription',
            isDummyField: true,
            text: 'Disposition',
            formatter: GridColumnFormaters.dispositionColumnFormater,
            filter: selectFilter({
                options: requestDispositionOptions,
                caseSensitive: false,
                // withoutEmptyOption: true,
                // defaultValue: 'New'
            }),
            sort: true
        }
     
    ];
    public render(){
        // const expandrow: any = {
        //     renderer: GridRowExpander.GetExpandedRow,
        //     onlyOneExpanding: true,
        // };
        const options = {
            sizePerPageList: [{
               text: '10', value: 10
           },{
               text: '25', value: 25
           },{
            text: '50', value: 50
        },{
            text: '100', value: 100
        }]
        }
        const defaultSorted = [{
            dataField:"id",
            order: 'asc'
        }]
        return (
            <div>
                <BootstrapTable keyField='id' 
                    data={ this.props.RequestDtoList } 
                    columns={ this.gridColumns }   
                     bootstrap4={true} 
                     pagination={ paginationFactory(options) }
                     filter={ filterFactory() }
                     defaultSorted = {defaultSorted}
                    //  expandRow={ expandrow }
                     />
                     <br></br><br></br>
            </div>
        )
    }

}