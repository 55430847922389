import React from "react";
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";
import { StopCard } from "./StopCard";
import { TransitTypeEnum } from "../../../../apis/enums/TransitTypeEnum";

export interface IStopCardListProps {
    stopList: IBusTrainStop[];
    updateSelectedStop: (selStop: IBusTrainStop) => void;
    stopCardBorderColor: string;
    alreadySelectedStop?: IBusTrainStop;
    transitType: TransitTypeEnum;
}

export class StopCardList extends React.Component<IStopCardListProps, {}> {
    public render() {

        const stopCardList = this.props.stopList
            .sort((a, b) => {
                if (a.stopSequence < b.stopSequence) {
                    return -1;
                } else if (a.stopSequence > b.stopSequence) {
                    return 1;
                }
                return 0;
            })
            .map((rec, index) => {
                const isCardDisabled = this.isCardDisabled(rec);
                return (
                    <div key={index} id = {`listId_${index}`} >
                        <StopCard busStopObj={rec}
                            updateSelectedStop={this.props.updateSelectedStop}
                            borderColor={this.props.stopCardBorderColor}
                            isDisabled={isCardDisabled}
                        />
                    </div>
                )
            })

        return (
            <div className = "stopList">
                {stopCardList}
            </div>
        )
    }

    private isCardDisabled = (stopToDisplay: IBusTrainStop) => {
        let isDisabled = false;
        if (this.props.alreadySelectedStop) {
            if (this.props.transitType === TransitTypeEnum.Subway) {
                if (this.props.alreadySelectedStop.stopId === stopToDisplay.stopId) {
                    isDisabled = true;
                }
            } else {
                if (stopToDisplay.stopSequence <= this.props.alreadySelectedStop.stopSequence) {
                    isDisabled = true;
                }
            }
        }

        return isDisabled;
    }
}