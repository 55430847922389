import React from "react";
import { CircleSpinner } from "./CircleSpinner";

export interface IInputBlockOverlayProps {
    shouldDisplay: boolean;
}

export class InputBlockOverlay extends React.Component<IInputBlockOverlayProps, {}> {
    public render() {
        if (this.props.shouldDisplay) {
            return (
                <div className="overlay">
                    <div className="overlaymodal">
                        <CircleSpinner/>
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }
}