import React from "react";
import { IAddEditUserFormProps } from "./interfaces/IAddEditUserFormProps";
import { IAddEditUserFormState } from "./interfaces/IAddEditUserFormState";
import { ErrorAlert } from "../common/ErrorAlert";
import { FormInputControl } from "../common/FormInputControl";
import { UserRoleSelectControl } from "./UserRoleSelectControl";
import { UserStatusSelectControl } from "./UserStatusSelectControl";
import { IUserUpdateModel } from "../../apis/interfaces/IUserUpdateModel";
import * as addEditUserFormValidtor from "./AddEditUserFormValidator";

export class AddEditUserForm extends React.Component<IAddEditUserFormProps, IAddEditUserFormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            istriedToSubmit: false,
            BscId: "",
            FirstName: "",
            LastName: "",
            Email: "",
            IsActive: true,
            UserRoles: [],
          
            isBscIdValid: false,
            bscIdValidationMessage: "",
            isFirstNameValid: false,
            isLastNameValid: false,
            isEmailValid: false,
            isUserRolesValid: false,
            isFormValid: false,
           
        };
    }

    public componentWillMount() {
        const { UserInfo } = this.props;
        this.setState({
            BscId: UserInfo.bscId,
            FirstName: UserInfo.firstName,
            LastName: UserInfo.lastName,
            Email: UserInfo.emailAddress,
            IsActive: UserInfo.isActive,
            UserRoles: UserInfo.roleIdList
        })
    }

    public render() {
        return (
            <div>
                <h4 className="mt-2 mb-2">
                    User Information
                </h4>
                <form>
                {!this.state.isFormValid && this.state.istriedToSubmit
                            && <ErrorAlert alertText="Please enter all required values" />}
                    <div className="row">
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                label="First Name"
                                placeHolder=""
                                changeHandler={this.handleInputChange("FirstName")}
                                value={this.state.FirstName}
                                isValid = {!this.state.istriedToSubmit || this.state.isFirstNameValid}
                            />
                        </div>
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                label="Last Name"
                                placeHolder=""
                                changeHandler={this.handleInputChange("LastName")}
                                value={this.state.LastName}
                                isValid ={!this.state.istriedToSubmit || this.state.isLastNameValid}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <FormInputControl
                                type="email"
                                label="Email"
                                placeHolder=""
                                changeHandler={this.handleInputChange("Email")}
                                value={this.state.Email}
                                isValid = {!this.state.istriedToSubmit || this.state.isEmailValid}
                            />
                        </div>
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                label="BSC ID"
                                placeHolder=""
                                changeHandler={this.handleInputChange("BscId")}
                                value={this.state.BscId}
                                isValid ={!this.state.istriedToSubmit || this.state.isBscIdValid}
                                validationMessage ={this.state.bscIdValidationMessage}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <UserRoleSelectControl
                                label="Roles (can select more than one)"
                                userRoleList= {this.props.UserRoleList}
                                changeHandler={this.handleInputChange("UserRoles")}
                                value={this.state.UserRoles}
                                isValid={!this.state.istriedToSubmit || this.state.isUserRolesValid}
                            />
                        </div>
                        <div className="col-lg-6">
                            <UserStatusSelectControl
                                label="User Status"
                                changeHandler={this.handleInputChange("IsActive")}
                                value={this.state.IsActive}
                                isValid={true}
                            />
                        </div>
                       
                    </div>

                    <div className="row">
                            <div className="col-sm-12">
                                <button
                                    type="button"
                                    className="btn btn-success float-right"
                                    onClick={this.handleSubmit}
                                >Submit</button>
                            </div>
                        </div>
                </form>
            </div>
        )


    }

    private handleInputChange = (fieldName: string) => (value: any) => {
        this.setState({
            [fieldName]: value

        } as any, this.validateFields);
    }

    private handleSubmit = () => {
        this.setState({istriedToSubmit: true} ,this.validateFields)
        if(this.state.isFormValid){
            const userObj: IUserUpdateModel = {
                id: this.props.UserInfo.id,
                bscId: this.state.BscId,
                firstName: this.state.FirstName,
                lastName: this.state.LastName,
                emailAddress: this.state.Email,
                isActive: this.state.IsActive,
                roleIdList: this.state.UserRoles,
            }
            this.props.onSubmit(userObj);
        }
    }

    private validateFields = ()=>{
        const validationObj = addEditUserFormValidtor.validateUserData(this.state);
        this.setState(validationObj)
    }
}