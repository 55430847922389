import React from "react";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import { Link } from "react-router-dom";
import moment from "moment";
import { TransitTypeEnum } from "../../apis/enums/TransitTypeEnum";

// time of incident is coming in as UTC ISO string, without the Z at the end
// formating it to show in ET
export function serviceDateFormater(cell:any) {
    if(cell){
        const timeOfIncidentUtc = moment(cell);
        return timeOfIncidentUtc.format('MM/DD/YYYY');
    } else {
        return "";
    }
}

export function referenceNumFormater(cell:any, row: IDelayRequestDto) {
    const refEditUrl = `/RequestEdit/${row.id}`;
    const imgSrc = `pdf-download.svg`;
    const editUrl = `/RequestEdit/${row.id}`;
    if (row.requestingHardCopyResponse){
        return(
            <span >
                 <Link to={editUrl}>{row.referenceNumber}</Link>
                <Link to = {refEditUrl}><img src={imgSrc} style={{ marginRight: 5 }} alt={row.referenceNumber} height="30" width="30" />
                </Link>
            </span>
           );
    }
    else {
        return(
            <span >
                <Link to={editUrl}>{row.referenceNumber}</Link>
            </span>
           );
    }

}
export function editColumnFormater(cell: any, row: IDelayRequestDto){
    const editUrl = `/RequestEdit/${row.id}`;
    return(
     <span >
         <Link to={editUrl}>{row.dispositionDescription}</Link></span>
    );
}

export function dispositionColumnFormater(cell: any, row: IDelayRequestDto){
    return(
     <span>{row.dispositionDescription} 
    </span>
    );
}

export function statusColumnFormater(cell: any, row: IDelayRequestDto){
    let statusDesc = "New";
    if (row.statusDescription === "AutoVerified"){
        statusDesc = "Verified (Auto)";
    } else if (row.statusDescription === "ManualVerified"){
        statusDesc = "Verified (Manual)"
    } else if (row.statusDescription === "PendingManualVerify"){
        statusDesc = "Pending manual"
    }
    return(
     <span>
         {statusDesc}
    </span>
    );
}

export function RouteIconListFormater(cell: any, row: IDelayRequestDto){
    var newSortedList:any[] = [];
    const passedRouteList = row.passedRouteList;
    if (passedRouteList) {
        const groupByLegNumber = (array: any, key: any) => {
            // Returns the final array
            return array.reduce((result:any, currentValue: any) => {
              // If an array exists based on legNumber then push to the array. Else create an array and push the object
              (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
              );
              return result;
            }, {});
          };
          var groupSortedList = groupByLegNumber(passedRouteList, 'legNumber');
    Object.keys(groupSortedList).forEach(key => {
        var rec = {
            id: key,
            listOfResultPerLeg: groupSortedList[key][0]
        }
        newSortedList.push(rec);
    })
        var cardList = newSortedList.map((rec: any, index: any) => {
                if (rec.listOfResultPerLeg.routeType === TransitTypeEnum.Subway && rec.listOfResultPerLeg.routeShortName) {
                    const imgSrc = `lineIcons/${rec.listOfResultPerLeg.routeShortName}.svg`;
                    return (<img src={imgSrc} style={{ marginRight: 5 }} alt={rec.listOfResultPerLeg.routeShortName} height="20" width="20" key={index} />)
                } else if(rec.listOfResultPerLeg.routeShortName) {
                    return <span className="badge badge-primary mr-1" key={index}>
                        {rec.listOfResultPerLeg.routeShortName}
                    </span>
                } else {
                    return (<span key={index}></span>)
                }
        });

        return (
            <div className="mb-2">{cardList}</div>

        );
    }

    return <></>

}


export function RouteListCustomFilter (filterVal:string, data: IDelayRequestDto[])  {
    if (filterVal) {
        const uppercaseFilterVal = filterVal.toUpperCase();
        if(uppercaseFilterVal.length === 1){
            return data.filter((x) => {
                let routeList =  x.passedRouteList.filter((k)=> {return k.routeShortName.toUpperCase() === uppercaseFilterVal});
                return routeList.length > 0;
            })
        } else {
            return data.filter((x) => {
                let routeList =  x.passedRouteList.filter((k)=> {return k.routeShortName.toUpperCase().indexOf(uppercaseFilterVal) >= 0});
                return routeList.length > 0;
            })
        }
    }
    return data;
  }