import React, { Component } from "react";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { Link } from "react-router-dom";
import * as constants from "../../constants";
import { BrowserDetectionError } from "../common/BrowserDetectionError";
import BrowserDetection from 'react-browser-detection';

export class HomePageComponent extends Component {

    public render() {
        return (
            <div>
                <NavbarComponent activeCheck="" />
                <div className="container">
                    <h4 className="display-4">Delay Verification Administration</h4>
                    <BrowserDetection>
                        {BrowserDetectionError}
                    </BrowserDetection>
                    {this.getBannerMessage()}
                    <div className="row">
                        <div className="col-lg-4">
                            <Link to="/searchDashboard" className="custom_home_page_link_button" >
                                <span className="custom_home_page_line_title">View Requests</span>
                                <div>Filter, view and edit requests</div>
                            </Link>
                        </div>
                        <div className="col-lg-4">
                            <Link to="/newDelayRequest" className="custom_home_page_link_button" >
                                <span className="custom_home_page_line_title">Create New Request</span>
                                <div>Request on behalf of customers</div>
                            </Link>
                        </div>
                        <div className="col-lg-4">
                            <Link to="/SearchUsers" className="custom_home_page_link_button" >
                                <span className="custom_home_page_line_title">User Management</span>
                                <div>Add, Edit or Remove User Accounts</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    private getBannerMessage = () => {
        if (constants.BannerMessage) {
            return (
                <div className="alert alert-warning" role="alert">
                    {constants.BannerMessage}
                </div>
            )
        }

        return (<div></div>)
    }
}