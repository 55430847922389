import { Component } from "react";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import * as DelayRequestListApi from "../../apis/DelayRequestListApi";
import { Button } from "reactstrap";
import React from "react";
import { CSVLink } from "react-csv";
import moment from "moment";

interface IExportCSVDataProps {
    startDate: string,
    endDate: string
}

interface IExportCSVDataState {
    RequestData: IDelayRequestDto[],
}
export class ExportCSVData extends Component<IExportCSVDataProps, IExportCSVDataState> {
    constructor(props: any) {
        super(props);
        this.state = {
            RequestData: [],
        };
    }
    public async componentDidMount() {
        try {
            var requestList = await DelayRequestListApi.GetRequests(this.props.startDate, this.props.endDate);
            this.setState({
                RequestData: requestList
            });
        } catch (err) {
            this.setState({
                RequestData: []
            });
        }
    }

    public async componentDidUpdate(prevProps: IExportCSVDataProps) {
        if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
            try {
                var requestList = await DelayRequestListApi.GetRequests(this.props.startDate, this.props.endDate);
                this.setState({
                    RequestData: requestList
                });
            } catch (err) {
                this.setState({
                    RequestData: []
                });
            }
        }
    }

    private getTransitAdminComments = (transitComments: string) => {
        if (transitComments !== undefined) {
            var splitComments = transitComments.split('|');
            if (splitComments.length > 1) {
                var adminComments = "";
                for (let i = 1; i < splitComments.length; i++) {
                    adminComments += splitComments[i].trim() + " ";
                }
                return adminComments;
            }
            return transitComments;
        }
        return "";
    }

    public render() {
        if (this.state.RequestData.length > 0) {
            const processedDate = this.state.RequestData.map((rec) => {
                let requestType = "";
                const busRoutes = rec.passedRouteList.filter(a => a.routeType === "1").length;
                const subwayRoutes = rec.passedRouteList.filter(a => a.routeType === "2").length;
                if (busRoutes > 0 && subwayRoutes > 0) {
                    requestType = "Combo";
                } else if (busRoutes > 0 && subwayRoutes === 0) {
                    requestType = "Bus"
                } else if (busRoutes === 0 && subwayRoutes > 0) {
                    requestType = "Subway"
                }
                return {
                    "Reference No": rec.referenceNumber,
                    "Request Date": moment(rec.requestDate).format('MM/DD/yyyy'),
                    "Request Type": requestType,
                    "Routes": rec.passedRouteList.map(a => a.routeShortName).join(','),
                    "Service Date": moment(rec.serviceDate).format('MM/DD/yyyy'),
                    "First Name": rec.firstName,
                    "Last Name": rec.lastName,
                    "Status": rec.statusDescription,
                    "Disposition": rec.dispositionDescription,
                    "Latest Disposition Submitted By": rec.dispositionSubmitByName,
                    "Latest Disposition Submitted Date": moment(rec.dispositionSubmitDate).format('MM/DD/yyyy hh:mm a'),
                    "Comments": this.getTransitAdminComments(rec.transitComments)
                }
            });
            const fileName = `RequestData_${this.props.startDate}_${this.props.endDate}`;
            return (
                <CSVLink
                    data={processedDate}
                    filename={fileName}
                >
                    <Button
                        type="button"
                        className="btn btn-success mt-4 "
                    >Download csv</Button>
                </CSVLink>
            )
        } else {
            return <></>
        }
    }
}