import React from "react";
import { IUserRoleDto } from "../../apis/interfaces/IUserRoleDto";
import { IUserUpdateModel } from "../../apis/interfaces/IUserUpdateModel";
import { IUserDto } from "../../apis/interfaces/IUserDto";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { CircleSpinner } from "../common/CircleSpinner";
import { ErrorAlert } from "../common/ErrorAlert";
import { AddEditUserForm } from "./AddEditUserForm";
import { UserDataSaveSuccess } from "./UserDataSaveSuccess";
import { InputBlockOverlay } from "../common/InputBlockOerlay";

import * as apiErrorHandler from "../../apis/ApiErrorHandler";
import * as userApi from "../../apis/UserApi";
import * as userRolesApi from "../../apis/UserRolesApi";
import * as userObjectHandler from "./UserObjectHandler";

export interface IAddEditUserComponentProps {
    userid: string;
    pathname: string;
    isAddNew: boolean;
}

interface IAddEditUserComponentState {
    UserRoleList: IUserRoleDto[];
    lookupLoadInProgress: boolean;
    lookupLoadSuccess: boolean;
    lookupLoadFailed: boolean;
    UserInfo: IUserUpdateModel | null
    saveInProgress: boolean;
    saveSuccess: boolean;
    saveFailed: boolean;
    saveErrorMessage: string;
    savedUserData: IUserDto | null;
}

export class AddEditUserComponent extends React.Component<IAddEditUserComponentProps, IAddEditUserComponentState> {
    // do not put nested objects here. If you do make sure to update the code below
    // to avoid mutation
    private intitialState: IAddEditUserComponentState = {
        UserRoleList: [],
        UserInfo: null,
        lookupLoadFailed: false,
        lookupLoadInProgress: true,
        lookupLoadSuccess: false,

        saveInProgress: false,
        saveFailed: false,
        saveSuccess: false,
        saveErrorMessage: "",
        savedUserData: null
    };
    constructor(props: IAddEditUserComponentProps) {
        super(props);
        this.state = {
            ...this.intitialState
        }
    }
    public async componentWillMount() {
        await this.initializeComponent();
    }

    public render() {
        if (this.state.lookupLoadInProgress) {
            return (
                <div>
                    <NavbarComponent activeCheck= "idUsers"/>
                    <div className="container mt-2">
                        <CircleSpinner />
                    </div>
                </div>
            )
        } else if (this.state.lookupLoadSuccess && this.state.UserInfo) {
            if (!this.state.saveSuccess) {
                return (
                    <div>
                        <NavbarComponent activeCheck= "idUsers"/>
                        <div className="container mt-2">
                            {this.state.saveFailed &&
                                <ErrorAlert alertText={"Save Failed. There was an error : " + this.state.saveErrorMessage} />}
                            <InputBlockOverlay shouldDisplay={this.state.saveInProgress} />
                            <AddEditUserForm UserInfo={this.state.UserInfo} UserRoleList={this.state.UserRoleList}
                                onSubmit={this.submitHandler} />

                        </div>
                    </div>
                )
            } else if (this.state.savedUserData) {
                return (
                    <div>
                        <NavbarComponent activeCheck= "idUsers"/>
                        <div className="container mt-2">
                            <UserDataSaveSuccess userinfo={this.state.savedUserData} isAddNew={this.props.isAddNew}
                                initializeComponent={this.initializeComponent} />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <NavbarComponent activeCheck= "idUsers"/>
                        <div className="container mt-2">
                            <ErrorAlert alertText="System error. Invalid state. Please refresh the page and try again" />
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <NavbarComponent activeCheck= "idUsers"/>
                    <div className="container mt-2">
                        <ErrorAlert alertText="Couldn't connect to the server. Confirm that you are connected to the network" />
                    </div>
                </div>
            )
        }
    }

    private initializeComponent = async () => {
        this.setState({ ...this.intitialState, lookupLoadInProgress: true, lookupLoadFailed: false, lookupLoadSuccess: false });
        try {
            const userRoles = await userRolesApi.GetUserRoleList();
            const userObj = await userObjectHandler.getUserObj(this.props.userid);
            this.setState({
                lookupLoadFailed: false,
                lookupLoadSuccess: true,
                lookupLoadInProgress: false,
                UserRoleList: userRoles,
                UserInfo: userObj,
            })
        } catch (error) {
            this.setState({ lookupLoadFailed: true, lookupLoadSuccess: false, lookupLoadInProgress: false })
        }
    }


    private submitHandler = async (userObj: IUserUpdateModel) => {
        try {
            this.setState({ saveInProgress: true, saveFailed: false, saveSuccess: false, saveErrorMessage: "" });
            const savedUserData = await userApi.addUpdateUser(userObj);
            this.setState({
                saveInProgress: false, saveFailed: false, saveSuccess: true, saveErrorMessage: "",
                savedUserData
            })
        } catch (err) {
            const errString = apiErrorHandler.GetApiErrorMessage(err);
            this.setState({ saveInProgress: false, saveFailed: true, saveSuccess: false, saveErrorMessage: errString });
        }

    }
}