import React from "react";
import { FormSelectControl } from "../common/FormSelectControl";


export interface IUserStatusSelectControlProps {
    label: string;
    changeHandler: (value: any) => void;
    value: boolean;
    isValid: boolean;
}

interface IUserStatusSelectControlState {
    selectedStatus: string;
}

interface IUserStatus {
    value: string;
    displayLabel: string;
}

export class UserStatusSelectControl extends React.Component<IUserStatusSelectControlProps, IUserStatusSelectControlState> {

    private activeStatus: IUserStatus =  {value: "Active", displayLabel: "Active"};
    private inactiveStatus: IUserStatus = {value: "Inactive", displayLabel:"Inactive"}

    private userStatusList: IUserStatus[] = [
        {...this.activeStatus},
        {...this.inactiveStatus}
    ];

    constructor(props:IUserStatusSelectControlProps){
        super(props);
        this.state = {
            selectedStatus: this.activeStatus.value
        };
    }

    public componentWillMount(){
        if(this.props.value){
            this.setState({selectedStatus: this.activeStatus.value});
        } else {
            this.setState({selectedStatus: this.inactiveStatus.value});
        }
    }

    public render(){
   
        return(
            <FormSelectControl
                label={this.props.label}
                optionList ={this.userStatusList}
                changeHandler ={this.changeHandler}
                value ={this.state.selectedStatus}
                isValid = {this.props.isValid}
                />
        )
    }

    private changeHandler = (value: string) => {
        if(value === this.activeStatus.value){
            this.props.changeHandler(true);
            this.setState({selectedStatus: this.activeStatus.value})
        } else {
            this.props.changeHandler(false);
            this.setState({selectedStatus: this.inactiveStatus.value})
        }
    }


    
}