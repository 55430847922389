import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { IDelayRouteUi } from "../../interfaces/IDelayRouteUi";
import { ScreenTypeEnum } from "../enums/ScreenTypeEnum";
import { IBusTrainRoute } from "../../../../apis/interfaces/IBusTrainRoute";
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";
import { TrainRouteSelector } from "./TrainRouteSelector";
import { TrainStopListLoader } from "./TrainStopListLoader";
import { StopSelector } from "../CommonDelaySelect/StopSelector";
import { ExtraStopSelector } from "../CommonDelaySelect/ExtraStopSelector";
import { TimeEntryForm } from "../CommonDelaySelect/TimeEntryForm";
import { TransitTypeEnum } from "../../../../apis/enums/TransitTypeEnum";
import * as RouteIdHandler from "./RouteIdHandler";

export interface ITrainDelaySelectModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    serviceDateStr: string;
    addToDelayRouteList: (newTravelRoute: IDelayRouteUi) => void;
    lastDelayRouteRec: IDelayRouteUi | null;
}


export interface ITrainDelaySelectModalState {
    screenType: ScreenTypeEnum,
    selectedRoute: IBusTrainRoute | null;
    stopList: IBusTrainStop[];
    nearbyStopList: IBusTrainStop[];
    unusualStopList: IBusTrainStop[];
    startingStop: IBusTrainStop | null;
    endingStop: IBusTrainStop | null;
    showExtraList: boolean;
    showExtraListBtn: boolean;
}

export class TrainDelaySelectModal extends React.Component<ITrainDelaySelectModalProps, ITrainDelaySelectModalState> {


    constructor(props: any) {
        super(props);
        const initiState = this.getInitialState();
        this.state = { ...initiState }
    }

    public render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={this.props.toggleModal} scrollable={true}
                onOpened={this.initState} backdrop="static">
                <ModalHeader toggle={this.props.toggleModal}>{this.getModalHeaderText()}</ModalHeader>
                <ModalBody>
                    {this.getModalBody()}
                </ModalBody>
            </Modal>
        )
    }

    private getModalBody = () => {
        if (this.state.screenType === ScreenTypeEnum.ShowRoutes) {
            return (
                <TrainRouteSelector serviceDateStr={this.props.serviceDateStr}
                    updateSelectedTrainRoute={this.updateSelectedRoute}
                    lastDelayRouteRec={this.props.lastDelayRouteRec}
                />
            )
        } else if (this.state.screenType === ScreenTypeEnum.showStopListLoader
            && this.state.selectedRoute) {
            return (
                <TrainStopListLoader routeId={this.state.selectedRoute.routeId}
                    serviceDateStr={this.props.serviceDateStr}
                    updateStopList={this.updateStopList}
                    lastDelayRouteRec={this.props.lastDelayRouteRec}
                />
            );
        } else if (this.state.screenType === ScreenTypeEnum.showStartStopList) {
            return (
                <div>
                    <div>
                        <StopSelector stopList={this.state.nearbyStopList}
                        updateSelectedStop={this.updateStartingStop}
                        stopCardBorderColor="#0f61a9"
                        transitType={TransitTypeEnum.Subway}
                        />
                    </div>
                    <div className={`showExtraStops_${this.state.showExtraList === true? 'visible': 'hidden'}`}>
                        <ExtraStopSelector stopList={this.state.unusualStopList}
                        updateSelectedStop={this.updateUnsualStartingStop}
                        stopCardBorderColor="#0f61a9"
                        transitType={TransitTypeEnum.Subway}
                        />
                    </div>
                    <div>
                        <Button className={`col-sm-12 showExtraStops_${this.state.showExtraListBtn ? 'visible': 'hidden'}`}
                        onClick= {this.handleExtraStops}> Show more Stops</Button>
                    </div>
                </div>
            )
        } else if (this.state.screenType === ScreenTypeEnum.showEndStopList && this.state.startingStop) {
            return (
                <div>
                    <div>
                        <StopSelector stopList={this.state.stopList}
                        alreadySelectedStop={this.state.startingStop}
                        updateSelectedStop={this.updateEndingBusStop}
                        stopCardBorderColor="#0f61a9"
                        transitType={TransitTypeEnum.Subway}
                        />
                    </div>
                    <div className={`showExtraStops_${this.state.showExtraList === true? 'visible': 'hidden'}`}>
                        <ExtraStopSelector stopList={this.state.unusualStopList}
                        alreadySelectedStop={this.state.startingStop}
                        updateSelectedStop={this.updateUnsualEndingStop}
                        stopCardBorderColor="#0f61a9"
                        transitType={TransitTypeEnum.Subway}
                        />
                    </div>
                    <div>
                        <Button className={`col-sm-12 showExtraStops_${this.state.showExtraListBtn ? 'visible': 'hidden'}`}
                        onClick= {this.handleExtraStops}> Show more Stops</Button>
                    </div>
                </div>
            )
        } else if (this.state.screenType === ScreenTypeEnum.showTimeEntry && this.state.selectedRoute
            && this.state.startingStop && this.state.endingStop) {
            return (
                <TimeEntryForm selectedRoute={this.state.selectedRoute}
                    startingStop={this.state.startingStop}
                    endingStop={this.state.endingStop}
                    addToDelayRouteList={this.props.addToDelayRouteList}
                    transitType={TransitTypeEnum.Subway}
                    lastDelayRouteRec={this.props.lastDelayRouteRec}
                />
            )
        }
        return <div></div>
    }

    private initState = () => {
        const initState = this.getInitialState();
        this.setState({ ...initState })
    }

    private getInitialState = () => {
        const initState: ITrainDelaySelectModalState = {
            screenType: ScreenTypeEnum.ShowRoutes,
            selectedRoute: null,
            stopList: [],
            nearbyStopList: [],
            unusualStopList: [],
            startingStop: null,
            endingStop: null,
            showExtraList: false,
            showExtraListBtn: false,
        }

        return initState;
    }

    private updateSelectedRoute = (selectedRoute: IBusTrainRoute) => {
        const selRouteCopy = { ...selectedRoute };
        this.setState({
            selectedRoute: selRouteCopy,
            screenType: ScreenTypeEnum.showStopListLoader,
            stopList: [],
            nearbyStopList: [],
            startingStop: null,
            endingStop: null,
        });

    }

    private updateStopList = (stopList: IBusTrainStop[], nearbyStopList: IBusTrainStop[], unusualStopList: IBusTrainStop[]) => {
        this.setState({
            screenType: ScreenTypeEnum.showStartStopList,
            stopList: stopList,
            nearbyStopList: nearbyStopList,
            unusualStopList: unusualStopList,
            startingStop: null,
            endingStop: null,
        });
        if (unusualStopList.length > 0){
            this.setState({
                showExtraListBtn: true,
            })
        }
    }

    private updateStartingStop = (startStop: IBusTrainStop) => {
        const startStopCopy = { ...startStop };
        this.setState({
            screenType: ScreenTypeEnum.showEndStopList,
            startingStop: startStopCopy,
            endingStop: null,
        });
    }
    private updateUnsualStartingStop = (unusualStopList: IBusTrainStop) => {
        const unsualStartStopCopy = { ...unusualStopList };
        this.setState({
            screenType: ScreenTypeEnum.showEndStopList,
            startingStop: unsualStartStopCopy,
            endingStop: null,
        });
    }

    private updateEndingBusStop = (endingStop: IBusTrainStop) => {
        const endingBusCopy = { ...endingStop };
        this.setState({
            screenType: ScreenTypeEnum.showTimeEntry,
            endingStop: endingBusCopy,
        });
    }

    private updateUnsualEndingStop = (unusualStopList: IBusTrainStop) => {
        const unsualExitStopCopy = { ...unusualStopList };
        this.setState({
            screenType: ScreenTypeEnum.showTimeEntry,
            endingStop: unsualExitStopCopy,
        });
    }

    private handleExtraStops = () => {
        this.setState({
            showExtraList: true,
            showExtraListBtn: false,
        })
    }

    private getModalHeaderText = () => {

        if (this.state.screenType === ScreenTypeEnum.ShowRoutes) {
            return <div><h6>Select a Subway Route (1 of 4)</h6></div>;
        } else if (this.state.screenType === ScreenTypeEnum.showStopListLoader ||
            this.state.screenType === ScreenTypeEnum.showStartStopList) {
            return (
                <div><h6>Where did you <b>board</b> the {this.getTrainRouteIcon()} ? (2 of 4)</h6></div>
            )
        } else if (this.state.screenType === ScreenTypeEnum.showEndStopList) {
            return (
                <div><h6>Where did you <b>exit</b> the {this.getTrainRouteIcon()} ? (3 of 4)</h6></div>
            )
        } else if (this.state.screenType === ScreenTypeEnum.showTimeEntry) {
            return (
                <div><h6>Enter times for the {this.getTrainRouteIcon()} (4 of 4)</h6></div>
            )
        }
        return <></>;

    }

    private getTrainRouteIcon = () => {
        if (this.state.selectedRoute) {
            const routeId = RouteIdHandler.getRouteId(this.state.selectedRoute);
            const imgSrc = `lineIcons/${routeId}.svg`;
            return (
                <img className="ml-1" src={imgSrc} alt={this.state.selectedRoute.shortName} height="35" width="35" />
            )
        } else {
            return <> </>
        }


    }

}