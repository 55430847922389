import React from "react";
import { CircleSpinner } from "../../../common/CircleSpinner";
import * as TrainStopApi from "../../../../apis/TrainStopApi";
import { ErrorAlert } from "../../../common/ErrorAlert";
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";
import { IDelayRouteUi } from "../../interfaces/IDelayRouteUi";
import * as ApiErrorHandler from "../../../../apis/ApiErrorHandler";
import { ApiCallStatusEnum } from "../../../common/enums/ApiCallStatusEnum";

interface ITrainStopListLoaderState {
    apiCallStatus: ApiCallStatusEnum;
    errorMessage: string;

}

export interface ITrainStopListLoaderProps {
    serviceDateStr: string;
    routeId: string;
    updateStopList: (stopList: IBusTrainStop[], nearByStopList: IBusTrainStop[], unusualStopList: IBusTrainStop[]) => void;
    lastDelayRouteRec: IDelayRouteUi | null;
}


export class TrainStopListLoader extends React.Component<ITrainStopListLoaderProps, ITrainStopListLoaderState>{

    constructor(props: any) {
        super(props);
        this.state = {
            apiCallStatus: ApiCallStatusEnum.Started,
            errorMessage: ""
        }
    }

    public async componentDidMount() {
        try {
            const { routeId, serviceDateStr } = this.props;
            this.setState({ apiCallStatus: ApiCallStatusEnum.Started, errorMessage: "" });
            let stopList = await TrainStopApi.getStops(routeId, serviceDateStr);
            const unusualStopList = stopList.filter((rec) => {
                return rec.stopSequence >= 100;
            })
            stopList = stopList.filter((rec) => {
                return rec.stopSequence < 100;
            })

            // User wants the mile radius for "nearby" to be configurable. So we are getting the 
            // Nearby stops from the server. Otherwise this calculation would have been done on the client
            let nearByStopList = stopList;
            if (this.props.lastDelayRouteRec) {
                nearByStopList = await TrainStopApi.getNearbyStops(routeId, serviceDateStr,
                    this.props.lastDelayRouteRec.exitPointLon, this.props.lastDelayRouteRec.exitPointLat);
            }
            // The API returns shuttle bus stops alongs with subway stops
            // Removing them based on business requirement
            // shuttle bus stopId will be longer than subway stop ids
            const subwayStopMaxLength = 12;
            nearByStopList = nearByStopList.filter((rec) => {
                return rec.stopId.length <= subwayStopMaxLength;
            })

            this.props.updateStopList(stopList, nearByStopList, unusualStopList);
        } catch (err) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ apiCallStatus: ApiCallStatusEnum.Failed, errorMessage });
        }
    }

    public render() {
        if (this.state.apiCallStatus === ApiCallStatusEnum.Started || 
                this.state.apiCallStatus === ApiCallStatusEnum.Success) {
            return (
                <CircleSpinner />
            )
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Failed) {
            return (
                <ErrorAlert alertText={this.state.errorMessage} />
            )
        } else {
            return (
                <ErrorAlert alertText="Unexpted Error. Please refresh the page and try again" />
            )
        }


    }
}