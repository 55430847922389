import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { HomePageComponent } from './pages/HomePage/HomePageComponent';
import { RequestEditComponent } from './pages/RequestEdit/RequestEditComponent';
import { ROUTE_PATH } from './constants';
import { LoginComponent } from './pages/Landing/LoginComponent';
import { LogoutComponent } from './pages/Landing/LogoutComponent';
import { PrivateRoute } from './pages/common/PrivateRoute';
import { UserSearchComponent } from './pages/UserSearch/UserSearchComponent';
import { AddNewUserComponent } from './pages/UserAddEdit/AddNewUserComponent';
import { EditUserComponent } from './pages/UserAddEdit/EditUserComponent';
import { RequestFormComponent } from './pages/RequestForm/RequestFormComponent';
import { OpenRequestComponent } from './pages/OpenRequests/OpenRequestComponent';
import { RequestDateSearchComponent } from './pages/RequestDateSearch/RequestDateSearchComponent';
import { SearchDashboardComponent } from './pages/searchDashboard/SearchDashboardComponent';
import { ReferenceNumSearchComponent } from './pages/ReferenceNumSearch/ReferenceNumSearchComponent';
import { ManualVerifyDetailComponent } from './pages/ManualVerifyDetails/ManualVerifyDetailComponent';
import { HardCopyRequestComponent } from './pages/HardCopyEmails/HardCopyRequestComponent';
import { AutoverifiedRequestComponent } from './pages/AutoverifiedRequests/AutoverifiedRequestComponent';
import { EmailSearchComponent } from './pages/EmailSearch/EmailSearchComponent';
import { ViewUserComponent } from './pages/UserViewDisposition/ViewUserComponent';
import { DispositionDateSearchComponent } from './pages/DispositionDateSearch/DispositionDateSearchComponent';
import { DelayRequestAnalyticsComponent } from './pages/DelayRequestAnalytics/DelayRequestAnalyticsComponent';
const App = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={LoginComponent} />
        <Route path={ROUTE_PATH.login} component={LoginComponent} />
        <Route path={ROUTE_PATH.logout} component={LogoutComponent} />
        <PrivateRoute exact path={ROUTE_PATH.home} component={HomePageComponent} />
        <PrivateRoute path="/RequestList/:startDate?/:endDate?" component={RequestDateSearchComponent} />
        <PrivateRoute path="/RequestEdit/:id" component={RequestEditComponent} />
        <PrivateRoute path="/SearchUsers" component={UserSearchComponent} />
        <PrivateRoute path="/addNewUser" component={AddNewUserComponent} />
        <PrivateRoute path="/editUser/:userid" component={EditUserComponent} />
        <PrivateRoute path="/viewUser/:userid" component={ViewUserComponent} />
        <PrivateRoute path="/newDelayRequest" component={RequestFormComponent} />
        <PrivateRoute path="/openRequest" component={OpenRequestComponent} />
        <PrivateRoute path="/searchDashboard" component={SearchDashboardComponent} />
        <PrivateRoute path="/searchByName/:emailAddress?/:firstName?/:lastName?" component={EmailSearchComponent} />
        <PrivateRoute path="/referenceNumSearch/:referencenum?" component={ReferenceNumSearchComponent} />
        <PrivateRoute path="/manualVerifyDetails/:serviceDate" component={ManualVerifyDetailComponent} />
        <PrivateRoute path="/hardCopyEmail" component={HardCopyRequestComponent} />
        <PrivateRoute path="/autoverifiedRequest" component={AutoverifiedRequestComponent} />
        <PrivateRoute path="/analytics/:startDate?/:endDate?" component={DelayRequestAnalyticsComponent} />
        <PrivateRoute path="/AdminDispositionList/:startDate?/:endDate?" component={DispositionDateSearchComponent} />
      </Switch>
    </HashRouter>
  );
}

export default App;
