import React from "react";


export interface IFormCheckBoxControlProps {
    label: string;
    value: boolean;
    changeHandler: (value: any) => void;
}

export class FormCheckBoxControl extends React.Component<IFormCheckBoxControlProps, {}> {
    public render() {
        return (
            <div className="form-group form-check">
                <label className="form-check-label" >
                    <input type="checkbox" className="form-check-input"
                        checked={this.props.value}
                        onChange={(event) => { this.props.changeHandler(event.target.checked) }}
                    />
                   {this.props.label} </label>
            </div>
        );
    }
}