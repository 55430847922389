import React from "react";

export interface ISuccessMessageProps {
    referenceNumber: string | undefined;
    onAnotherRequestClick : ()=> void;
}

export class SuccessMessage extends React.Component<ISuccessMessageProps, {}> {
    public render() {
        return (<div>
            <h3 className="display-5 mt-4">Request Submitted Successfully</h3>
            <div className="mt-2 mb-2">Reference Number: {this.props.referenceNumber || ""} </div>
            <div>You will soon get an email confirming the receipt of this request.</div>
            
            <button type="button" className="btn btn-outline-primary mt-5"
                onClick ={this.props.onAnotherRequestClick}
            >Submit Another Request</button>
        </div>)
    }
}