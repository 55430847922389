import React from "react";
import Select from 'react-select';
import { IUserRoleDto } from "../../apis/interfaces/IUserRoleDto";

export interface IUserRoleSelectControlProps {
    label: string;
    changeHandler: (value: any) => void;
    value: number[];
    isValid: boolean;
    userRoleList: IUserRoleDto[];
}

interface ISelectOption {
    value: number;
    label: string;
}

interface IUserRoleState {
    selectedOption: ISelectOption[] ;
}

export class UserRoleSelectControl extends React.Component<IUserRoleSelectControlProps, IUserRoleState> {

    constructor(props:IUserRoleSelectControlProps){
        super(props);
        this.state = {
            selectedOption : []
        }
    }

    public componentWillMount() {
        const selectedOption = this.props.userRoleList
            .filter((rec)=>{
                // if the role name exist in the value then add it to the selected option
                return this.props.value.indexOf(rec.id) >= 0;
            })
            .map((rec)=>{
                return ({
                    value: rec.id,
                    label:rec.roleName
                })
            });

        this.setState({selectedOption})
    }

    public render() {
        const options: ISelectOption[] = this.props.userRoleList.map((rec) => {
            return ({
                value: rec.id,
                label: rec.roleName
            });
        })
        return (
            <div className="form-group ">
                <label >{this.props.label}</label>
                <Select
                    label={this.props.label}
                    options={options}
                    onChange={this.handleChange}
                    value={this.state.selectedOption}
                    isValid={this.props.isValid}
                    isMulti = {true}
                    styles = {this.getStyels()}
                />
            </div>

        )
    }

    private handleChange =(selectedOption:any) => {
        this.setState({ selectedOption });
        if(selectedOption ){
            const userIdList = selectedOption.map((rec: ISelectOption)=>{
                return (rec.value)
            })
            this.props.changeHandler(userIdList)
        } else {
            this.props.changeHandler([])
        }
            
    };

    private getStyels = () => {
        if(!this.props.isValid){
            const customStyles = {
                control: (provided:any) => ({
                  ...provided,
                  border: '1px solid red',
                  padding: 1,
                }),
              }
            return customStyles;
        } else {
            return {};
        }
       
    }
}