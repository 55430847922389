import React from "react";
// import { IDelayRouteDto } from "../../apis/interfaces/IDelayRouteDto";
import { IDelayRouteListDto } from "../../apis/interfaces/IDelayRouteListDto";
import { TransitTypeEnum } from "../../apis/enums/TransitTypeEnum";
import * as googleMapLinkHandler from "../../handlers/googleMapLinkHandler";
import moment from "moment";
import { IDelayRouteDto } from "../../apis/interfaces/IDelayRouteDto";
export interface IDelayRouteCardProps {
    arrayDelayRouteObj: IDelayRouteListDto;
    // delayRouteObj: IDelayRequestDto;
}

export interface IDelayRouteCardState {
    currentDelayRoute: IDelayRouteDto;
}

var delayVerifyResults: any[] = [];

export class DelayRouteCard extends React.Component<IDelayRouteCardProps, IDelayRouteCardState> {

    constructor(props: any) {
        super(props);
        this.state = {
            currentDelayRoute: this.props.arrayDelayRouteObj.listOfResultPerLeg[0],
        }
    }

    public render() {
        var isFirst = true;
        var maxDelay = '';
        var maxDelayArrival = '';
        if (this.props.arrayDelayRouteObj.listOfResultPerLeg[0].delayVerifyResult.length > 0) {
            delayVerifyResults = this.props.arrayDelayRouteObj.listOfResultPerLeg[0].delayVerifyResult;
        } else {
            delayVerifyResults = [];
        }
        var sortedList = delayVerifyResults.sort((a, b) => {
            if (moment(a.departTimeActual, 'h:mma') < moment(b.departTimeActual, 'h:mma')) {
                return -1;
            } else if (moment(a.departTimeActual, 'h:mma') > moment(b.departTimeActual, 'h:mma')) {
                return 1;
            } else {
                return 0;
            }
        })
        sortedList.map((rec, index) => {
            if (rec) {
                if (index === 0) {
                    maxDelay = rec.totalDelay.toString();
                    maxDelayArrival = rec.departTimeActual.toString();
                } else {
                    if (moment(maxDelay, 'h:mm:ssa') < moment(rec.totalDelay, 'h:mm:ssa')) {
                        maxDelay = rec.totalDelay.toString();
                        maxDelayArrival = rec.departTimeActual.toString();
                    }
                }
            }
        })
        let routeCardList: JSX.Element[] = [];
        if (sortedList.length > 0) {

            routeCardList = sortedList.map((rec, index) => {
                if (index === 0) {
                    if (moment(maxDelayArrival, 'h:mma') >= (moment(this.state.currentDelayRoute.entryTime, 'h:mma')) &&
                        (moment(maxDelayArrival, 'h:mma') <= (moment(this.state.currentDelayRoute.exitTime, 'h:mma')))) {
                    }
                }
                if (isFirst) {
                    isFirst = false;
                    return (
                        <div className="row align-items-center" key={index}>
                            <div className="col-sm-1">
                                {this.state.currentDelayRoute.legNumber}
                            </div>
                            <div className="col-sm-2">
                                {this.getRouteIcon()}
                            </div>
                            <div className="col-sm-9">
                                <div className="row rowPaddLeft">
                                    <div className="col-sm-12">
                                        {this.getLegDescription()}
                                    </div>
                                </div>
                                {this.getAutoVerifyResultsInfo(rec, maxDelay)}
                            </div>
                        </div>

                    )
                }
                else {
                    return (
                        <div className="row align-items-center" key={index}>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-2">
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-12">
                                    </div>
                                </div>
                                {this.getAutoVerifyResultsInfo(rec, maxDelay)}
                            </div>
                        </div>
                    )
                }

            })
        } else {
            routeCardList[0] = <div className="row align-items-center" key={this.state.currentDelayRoute.legNumber}>
                <div className="col-sm-1">
                    {this.state.currentDelayRoute.legNumber}
                </div>
                <div className="col-sm-2">
                    {this.getRouteIcon()}
                </div>
                <div className="col-sm-9">
                    <div className="row rowPaddLeft">
                        <div className="col-sm-12">
                            {this.getLegDescription()}
                        </div>
                    </div>
                </div>
            </div>
        }

        return (
            <div >
                <div className="card mt-1">
                    <div className="card-body">
                        {routeCardList}
                    </div>
                </div>
            </div>
        )
    }

    private getLegDescription = () => {
        const googleMapLink = googleMapLinkHandler.GetGoogleMapLink(this.state.currentDelayRoute);
        if (this.state.currentDelayRoute.routeType === TransitTypeEnum.SIFerry) {
            return (<div>
                {this.state.currentDelayRoute.entryPointName} to {this.state.currentDelayRoute.exitPointName}
            </div>);
        } else {
            return (
                <div>
                    {this.state.currentDelayRoute.entryPointName} (<b>{this.state.currentDelayRoute.entryTime}</b>) to {this.state.currentDelayRoute.exitPointName} (<b>{this.state.currentDelayRoute.exitTime}</b>)
                    Got First {this.state.currentDelayRoute.routeType === TransitTypeEnum.Bus ? "Bus" : "Train"}: <b>{this.state.currentDelayRoute.gotFirstSched === true ? "yes" : "no"}</b> [<a href={googleMapLink} target="_blank" rel="noopener noreferrer">Map</a>]
                </div>
            )
        }
    }

    private getRouteIcon = () => {

        const delayRouteObj = this.state.currentDelayRoute;
        if (delayRouteObj.routeType === TransitTypeEnum.Subway &&
            delayRouteObj.routeShortName) {
            const imgSrc = `lineIcons/${delayRouteObj.routeShortName}.svg`;
            return (<img src={imgSrc} style={{ marginRight: 5 }} alt={delayRouteObj.routeShortName} height="25" width="25" />)
        } else {
            return <span className="badge badge-primary" style={{ fontSize: 15 }}>
                {delayRouteObj.routeShortName}
            </span>
        }

    }

    private getOtherRouteIcon = (rec: any) => {
        if (rec) {
            const delayVerifyResult = rec;
            const delayRouteObj = this.state.currentDelayRoute;
            if (delayRouteObj.routeType === TransitTypeEnum.Subway &&
                delayVerifyResult.routeId) {
                if (delayRouteObj.routeShortName !== delayVerifyResult.routeId) {
                    const imgSrc = `lineIcons/${delayVerifyResult.routeId}.svg`;
                    return (<img src={imgSrc} style={{ marginRight: 5 }} alt={delayVerifyResult.routeId} height="25" width="25" />)
                }
            }
        }

    }

    private getAutoVerifyResultsInfo = (rec: any, maxDelay: string) => {
        if (rec) {
            const delayVerifyResult = rec;
            return (
                <div className={delayVerifyResult.highlight === true ? 'highlight' : 'nothighlight'}>
                    <hr />
                    <div className="row">
                        <div className="col-sm-4">
                            Sched Depart: <b>{delayVerifyResult.departTimeSchd}</b>
                        </div>
                        <div className="col-sm-4">
                            Sched Arrival: <b>{delayVerifyResult.arriveTimeSchd}</b>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Actual Depart: <b>{delayVerifyResult.departTimeActual}</b>
                        </div>
                        <div className="col-sm-4">
                            Actual Arrival: <b>{delayVerifyResult.arriveTimeActual}</b>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Waiting Delay: <span style={{ padding: 2 }} className="bg-secondary text-white">{delayVerifyResult.waitingTimeDelay}</span>
                        </div>
                        <div className="col-sm-4">
                            Travel Delay: <span style={{ padding: 2 }} className="bg-secondary text-white" >{delayVerifyResult.travelTimeDelay}</span>
                        </div>
                        <div className="col-sm-4">
                            Total: <span style={{ padding: 2 }} className="bg-warning  text-white">{delayVerifyResult.totalDelay}</span>
                            <span style={{ padding: 2 }} />
                            {this.getOtherRouteIcon(delayVerifyResult)}
                        </div>
                    </div>
                </div>
            )
        }

        return <></>;
    }
}