import React from "react";
import { FormSelectControl } from "../common/FormSelectControl";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import { IDelayRequestUpdateModel } from "../../apis/interfaces/IDelayRequestUpdateModel";
import { ErrorAlert } from "../common/ErrorAlert";
import validator from "validator";
import { TransitCommentTextArea } from "./TransitComments/TransitCommentTextArea";
// import { CommentTemplateSelModal } from "./TransitComments/CommentTemplateSelModal";
import * as constants from "../../constants";
import { ROUTE_PATH } from "../../constants";
import { Link } from "react-router-dom";
import { IUserDto } from "../../apis/interfaces/IUserDto";
import * as currentUserapi from "../../apis/CurrentUserApi";
import * as delayRequestListApi from "../../apis/DelayRequestListApi";
import { toast, Slide } from 'react-toastify';
import { Button } from "reactstrap";
import { FormCheckBoxControl } from "../common/FormCheckBoxControl";
import fileDownload from "js-file-download";
import { IDelayCommentsUpdateModel } from "../../apis/interfaces/IDelayCommentsUpdateModel";
// import { useHistory } from "react-router-dom";
import { IDispositionAuditDto } from "../../apis/interfaces/IDispositionAuditDto";
import { DispositionAuditCardList } from "./DispositionAudit/DispositionAuditCardList";

export interface IRequestEditFormProps {
    requestObj: IDelayRequestDto;
    updateRequestInfo: (requestUpdateModel: IDelayRequestUpdateModel) => void;
    updateRequestComments: (commentsUpdateModel: IDelayCommentsUpdateModel) => void;
}

export interface IRequestEditFormState {
    dispositionId: string | null;
    statusDescription: string;
    emailAddress: string;
    adminComments: string;
    processComments: string;
    requestObjUpdateGuardKey: string;
    isDispositionValid: boolean;
    isEmailAddressValid: boolean;
    isTransitCommentsValid: boolean;
    triedToSubmit: boolean;
    isFormValid: boolean;
    validationErrMsg: string;
    statusDescription1: string;
    requestingHardCopyResponse: boolean;
    hardCopyDownloadBy: number | null;
    userProfile: IUserDto | null;
    disposition1: string;
    responseEmailSentdate: string;
    hardCopyDownloadDate: string;
    checkEmailSent: boolean;
    updateVerification: boolean;
    isUpdateVerificationValid: boolean;
    dispositionDescription: string;
    initialDisposition: string;
    backColumn: string;
    dispositionSubmitBy: number | null;
    addressEditBy: number | null;
    dispositionSubmitBy1: number | null;
    dispositionSubmitByName: string;
    submitDisposition: boolean;
    adminCommentsOld: string;
    changeComments: boolean;
    isDispositionChanged: boolean;
    dispositionHistory: IDispositionAuditDto[];
    isDispositionHistoryModalOpen: boolean;
    maxAdminComments: number;
}


export class RequestEditForm extends React.Component<IRequestEditFormProps, IRequestEditFormState> {
    private statusIdFieldName = "statusId";
    private dispositionIdFieldName = "dispositionId";

    constructor(props: any) {
        super(props);
        this.state = {
            dispositionId: null,
            statusDescription: "",
            emailAddress: "",
            adminComments: "",
            processComments: "",
            requestObjUpdateGuardKey: "",
            isDispositionValid: false,
            isEmailAddressValid: false,
            triedToSubmit: false,
            isTransitCommentsValid: true,
            isFormValid: false,
            validationErrMsg: "",
            statusDescription1: "",
            requestingHardCopyResponse: false,
            hardCopyDownloadBy: null,
            userProfile: null,
            disposition1: "",
            responseEmailSentdate: "",
            hardCopyDownloadDate: "",
            checkEmailSent: false,
            updateVerification: false,
            isUpdateVerificationValid: false,
            dispositionDescription: "",
            initialDisposition: "",
            backColumn: "",
            dispositionSubmitBy: null,
            addressEditBy: null,
            dispositionSubmitBy1: null,
            dispositionSubmitByName: "",
            submitDisposition: false,
            adminCommentsOld: "",
            changeComments: false,
            isDispositionChanged: false,
            dispositionHistory: [],
            isDispositionHistoryModalOpen: false,
            maxAdminComments: 300,
        }
    }

    public async componentDidMount() {
        if (this.props.requestObj) {
            const statusDescription = this.getStatusDescription(this.props.requestObj.statusId + "");
            const dispositionDescription = this.getDispositionDescription(this.props.requestObj.dispositionId + "");
            const initialDisposition = this.getDispositionDescription(this.props.requestObj.initialDispositionId + "");
            const adminComments = this.getTransitAdminComments(this.props.requestObj.transitComments, true);
            const processComments = this.getTransitAdminComments(this.props.requestObj.transitComments, false);
            const maxAdminLength = 1000 - processComments.length - 1;
            this.setState({
                dispositionId: this.props.requestObj.dispositionId + "",
                statusDescription,
                adminComments: adminComments,
                processComments: processComments,
                requestObjUpdateGuardKey: this.props.requestObj.lastTransitUpdateDateKey,
                emailAddress: this.props.requestObj.emailAddress,
                isDispositionValid: true,
                isEmailAddressValid: true,
                triedToSubmit: false,
                isTransitCommentsValid: true,
                isFormValid: true,
                validationErrMsg: "",
                statusDescription1: statusDescription,
                requestingHardCopyResponse: this.props.requestObj.requestingHardCopyResponse,
                disposition1: this.props.requestObj.dispositionId + "",
                responseEmailSentdate: this.props.requestObj.responseEmailSentdate,
                hardCopyDownloadDate: this.props.requestObj.hardCopyDownloadDate,
                updateVerification: false,
                dispositionDescription,
                initialDisposition,
                dispositionSubmitBy1: this.props.requestObj.dispositionSubmitBy,
                dispositionSubmitByName: this.props.requestObj.dispositionSubmitByName,
                adminCommentsOld: adminComments,
                dispositionHistory: this.props.requestObj.dispositionAuditList,
                maxAdminComments: maxAdminLength,
            });
            try {
                this.setState({ userProfile: null })
                const userDto = await currentUserapi.GetCachedAuthUser();
                this.setState({ userProfile: userDto });
            } catch (err) {
                this.setState({ userProfile: null });
            }
            if (this.props.requestObj.hardCopyDownloadBy) {
                this.setState({ checkEmailSent: true });
            }
            if ((this.state.statusDescription1 === "AutoVerified" || this.state.statusDescription1 === "ManualVerified")
                && this.state.requestingHardCopyResponse === true) {
                this.setState({ backColumn: ROUTE_PATH.hardRequest })
            } else if (this.state.statusDescription1 === "PendingManualVerify") {
                this.setState({ backColumn: ROUTE_PATH.openRequest })
            } else if (this.state.statusDescription1 === "AutoVerified" && this.state.dispositionSubmitBy1 === 0) {
                this.setState({ backColumn: ROUTE_PATH.autoverifiedRequest })
            } else {
                const requestDate = localStorage.getItem('searchGrid');
                this.setState({ backColumn: requestDate || '' })
            }
            if (this.props.requestObj.dispositionId !== this.props.requestObj.initialDispositionId) {
                this.setState({ isDispositionChanged: true })
            }

        }

    }

    public render() {
        const adminValidationMessage = `Text is too long. Comments cannot be more than ${this.state.maxAdminComments} characters`;
        return (
            <div className="card mt-1">
                <div className="card-body">
                    {this.state.validationErrMsg && this.state.triedToSubmit && <ErrorAlert alertText={this.state.validationErrMsg} />}
                    <form>
                        <div className="row">
                            <div className="col-lg-6" id={`unVerified${this.state.statusDescription1}${this.state.updateVerification}`}>
                                <FormSelectControl
                                    label="Disposition"
                                    optionList={constants.RequestDispostionList}
                                    changeHandler={this.handleInputChange("dispositionId")}
                                    value={this.state.dispositionId || 0}
                                    isValid={!this.state.triedToSubmit || this.state.isDispositionValid}
                                />
                            </div>
                            <div className="col-lg-6" id={`verified${this.state.isDispositionChanged}${this.state.statusDescription1}${this.state.updateVerification}`}>
                                <div style={{ paddingTop: 40 }}><b>Final Disposition: {this.state.dispositionDescription}</b></div>
                            </div>
                            <div className="col-lg-6" id={`verified_${this.state.isDispositionChanged}${this.state.statusDescription1}${this.state.updateVerification}`}>
                                <div style={{ paddingTop: 40 }}><b>Initial Disposition: {this.state.initialDisposition}</b></div>

                            </div>
                            <div className="col-lg-6">
                                <div style={{ paddingTop: 40 }}>Status: <b>{this.state.statusDescription}</b></div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-12" id={`verifiedInitial${this.state.isDispositionChanged}${this.state.statusDescription1}${this.state.updateVerification}`}>
                                <div style={{ paddingTop: 40 }}><b>Initial Disposition: {this.state.initialDisposition}</b></div>
                            </div>
                        </div>
                        {this.renderDispositionInfo()}
                        {this.renderDispositionAudit()}
                        <div className="row">
                            <div className="col-lg-6" id={`verified${this.state.statusDescription1}`}>
                                <FormCheckBoxControl
                                    label="Change Disposition"
                                    value={this.state.updateVerification}
                                    changeHandler={this.updateVerificationToggle}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <TransitCommentTextArea
                                    label="Admin Comments"
                                    rowcount={3}
                                    changeHandler={this.handleInputChange("adminComments")}
                                    value={this.state.adminComments}
                                    isValid={!this.state.triedToSubmit || this.state.isTransitCommentsValid}
                                    validationMessage={adminValidationMessage}
                                    isAdminComments={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <TransitCommentTextArea
                                    label="Process Comments"
                                    rowcount={5}
                                    changeHandler={this.handleInputChange("processComments")}
                                    value={this.state.processComments}
                                    isValid={true}
                                    validationMessage="Text is too long. Comments cannot be more than 1000 characters"
                                    isAdminComments={false}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-sm-12">
                                <button id={`submit_${this.state.statusDescription1}${this.state.updateVerification}${this.state.submitDisposition}`} //
                                    style={{ marginLeft: "1%" }}
                                    type="button"
                                    className="btn btn-success float-right"
                                    onClick={this.submitData}
                                >Submit</button>
                                <button id={`save_${this.state.updateVerification}${this.state.changeComments}`} //
                                    style={{ marginLeft: "1%" }}
                                    type="button"
                                    className="btn btn-success float-right"
                                    onClick={this.submitComments}
                                >Save Comments</button>
                                <button id={`confirm_${this.state.updateVerification}${this.state.statusDescription1}${this.state.requestingHardCopyResponse}${this.state.checkEmailSent}`} //
                                    style={{ marginLeft: "1%" }}
                                    type="button"
                                    className="btn btn-success float-right"
                                    onClick={this.confirmHardCopyData}
                                >Confirm Hard copy sent</button>
                                <Button id={`download_${this.state.updateVerification}${this.state.statusDescription1}${this.state.requestingHardCopyResponse}`}
                                    style={{ marginLeft: "1%" }} type="button"
                                    className="btn btn-success float-right"
                                    onClick={this.downloadPdf}> Download </Button>

                                <Link to={this.state.backColumn} className="btn btn-outline-success ml-2 my-2 my-sm-0 float-right" role="button" >Back </Link>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <CommentTemplateSelModal isModalOpen={this.state.isModalOpen}
                    toggleModal={this.toggleCommentSelectModal}
                    updateCommentText={this.updateCommentText}
                /> */}
            </div>
        )
    }
    private renderDispositionAudit = () => {
        if (this.state.isDispositionHistoryModalOpen === true && this.state.dispositionHistory.length > 0) {
            return (
                <div className="card mt-2">
                    <div className="card-body">
                        <DispositionAuditCardList DispositionAuditList={this.state.dispositionHistory} />
                    </div>
                </div>
            )
        }
    }

    private renderDispositionInfo = () => {
        if (this.state.dispositionSubmitByName !== "" && this.state.dispositionHistory.length > 0) {
            var userLink = `/viewUser/${this.state.dispositionSubmitBy1}`;
            return (
                <div className="row">
                    <div className="col-lg-6">
                        {this.state.dispositionSubmitByName === "" ? '' : <div style={{ paddingTop: 40 }}>Latest Disposition submitted by : <Link to={userLink}><b>{this.state.dispositionSubmitByName}</b></Link></div>}
                    </div>
                    <div className="col-lg-6" style={{ paddingTop: 30 }}>
                        <button type="button" className="btn btn-info"
                            onClick={this.toggleDispositionAuditModal}>{this.state.isDispositionHistoryModalOpen === false ? 'Show Disposition Audit' : 'Hide Disposition Audit'}</button>
                    </div>
                </div>
            );
        } else if (this.state.dispositionSubmitByName !== "" && this.state.dispositionHistory.length === 0) {
            var userLink = `/viewUser/${this.state.dispositionSubmitBy1}`;
            return (
                <div className="row">
                    <div className="col-lg-6">
                        {this.state.dispositionSubmitByName === "" ? '' : <div style={{ paddingTop: 40 }}>Latest Disposition submitted by : <Link to={userLink}><b>{this.state.dispositionSubmitByName}</b></Link></div>}
                    </div>
                </div>
            );
        } else if (this.state.dispositionSubmitByName === "" && this.state.dispositionHistory.length > 0) {
            return (
                <div className="row">
                    <div className="col-lg-6" style={{ paddingTop: 30 }}>
                        <button type="button" className="btn btn-info"
                            onClick={this.toggleDispositionAuditModal}>{this.state.isDispositionHistoryModalOpen === false ? 'Show Disposition Audit' : 'Hide Disposition Audit'}</button>
                    </div>
                </div>
            );
        }
    }

    private toggleDispositionAuditModal = () => {
        this.setState((state) => {
            return { isDispositionHistoryModalOpen: !state.isDispositionHistoryModalOpen };
        })
    }

    private downloadPdf = async () => {
        try {
            toast("Downloading " + this.props.requestObj.referenceNumber, { transition: Slide, type: toast.TYPE.INFO, });
            var hardCopyRequest = await delayRequestListApi.GetHardCopy(this.props.requestObj.referenceNumber + "");
            fileDownload(hardCopyRequest, this.props.requestObj.referenceNumber + ".pdf");

        } catch (err) {

        }
    }
    private handleInputChange = (fieldName: string) => (value: any) => {
        if (fieldName !== this.dispositionIdFieldName) {
            this.setState({
                [fieldName]: value
            } as any, this.validateFields);

            if (fieldName === "adminComments" && this.state.adminCommentsOld !== value) {
                this.setState({
                    changeComments: true
                })
            }
            if (fieldName === "adminComments" && this.state.adminCommentsOld === value) {
                this.setState({
                    changeComments: false
                })
            }
        } else {
            const statusDescription = this.getStatusDescriptionForDispositionId(value);
            this.setState({
                [fieldName]: value,
                statusDescription
            } as any, this.validateFields);
            if (value !== this.state.disposition1 && this.state.statusDescription1 === "PendingManualVerify") {
                this.setState({
                    submitDisposition: true
                })
            }
            if (value === this.state.disposition1 && this.state.statusDescription1 === "PendingManualVerify") {
                this.setState({
                    submitDisposition: false
                })
            }
        }


    }

    private validateFields = () => {
        let validationErrMsg = "";
        let isUpdateVerificationValid = true;
        let isDispositionValid = this.state.dispositionId ? true : false;


        let isTransitCommentsValid = true;
        if (this.state.adminComments && this.state.adminComments.length > this.state.maxAdminComments) {
            isTransitCommentsValid = false;
            validationErrMsg += `Transit Comments are too long (Must be less than ${this.state.maxAdminComments} chars).`;
        }
        let isEmailAddressValid = false;
        if ((this.state.emailAddress && validator.isEmail(this.state.emailAddress)) ||
            this.state.requestingHardCopyResponse) {
            isEmailAddressValid = true;
        } else if (this.state.emailAddress && validator.isEmail(this.state.emailAddress)) {
            validationErrMsg += "Invalid Email Address. ";
        }

        let isFormValid = isDispositionValid &&
            isEmailAddressValid && isTransitCommentsValid && isUpdateVerificationValid;

        if (!isFormValid && validationErrMsg === "") {
            validationErrMsg = "Please fill all the required fields";
        }

        if (this.state.updateVerification === true) {
            isUpdateVerificationValid = this.state.updateVerification ? true : false;
        }

        this.setState({
            isDispositionValid, isTransitCommentsValid, isEmailAddressValid, isFormValid,
            validationErrMsg, isUpdateVerificationValid
        });
    }

    private submitData = () => {

        this.setState({ validationErrMsg: "" })
        this.validateFields();
        this.setState({ triedToSubmit: true });
        if (this.state.isFormValid && this.state.dispositionId) {
            const statusId = this.getStatusIdFrDispositionId(this.state.dispositionId);
            // Address fields are not updated here.
            // To make the UI simpler we are updating them in a seperate modal
            var requestUpdateModel: IDelayRequestUpdateModel = {
                requestId: this.props.requestObj.id,
                dispositionId: Number(this.state.dispositionId),
                statusID: Number(statusId),
                emailAddress: this.state.emailAddress,
                transitComments: `${this.state.processComments}|${this.state.adminComments}`,
                addressStreet1: this.props.requestObj.addressStreet1,
                addressStreet2: this.props.requestObj.addressStreet2,
                addressCity: this.props.requestObj.addressCity,
                addressState: this.props.requestObj.addressState,
                addressZip: this.props.requestObj.addressZip,
                requestingHardCopyResponse: this.props.requestObj.requestingHardCopyResponse,
                lastTransitUpdateDateKey: this.state.requestObjUpdateGuardKey,
                isHardCopyDownloaded: false,
                dispositionSubmitBy: Number(this.state.userProfile?.id),
                isAddressEdited: false,
                initialDispositionId: Number(this.props.requestObj.initialDispositionId)
            };
            this.props.updateRequestInfo(requestUpdateModel);
        }

    }

    private submitComments = () => {

        this.setState({ validationErrMsg: "" })
        this.validateFields();
        this.setState({ triedToSubmit: true });
        if (this.state.isFormValid && this.state.dispositionId) {
            // Address fields are not updated here.
            // To make the UI simpler we are updating them in a seperate modal
            var requestUpdateModel: IDelayCommentsUpdateModel = {
                requestId: this.props.requestObj.id,
                transitComments: `${this.state.processComments}|${this.state.adminComments}`,
            };
            this.props.updateRequestComments(requestUpdateModel);
        }

    }

    private confirmHardCopyData = () => {

        this.setState({ validationErrMsg: "" })
        this.validateFields();
        this.setState({ triedToSubmit: true });
        let mailId = null;
        if (Number(this.state.disposition1) !== 1) {
            mailId = Number(this.state.userProfile?.id);
        }
        if (this.state.isFormValid && this.state.dispositionId) {
            const statusId = this.getStatusIdFrDispositionId(this.state.dispositionId);
            // Address fields are not updated here.
            // To make the UI simpler we are updating them in a seperate modal
            var requestUpdateModel: IDelayRequestUpdateModel = {
                requestId: this.props.requestObj.id,
                dispositionId: Number(this.state.dispositionId),
                statusID: Number(statusId),
                emailAddress: this.state.emailAddress,
                transitComments: `${this.state.processComments}|${this.state.adminComments}`,
                addressStreet1: this.props.requestObj.addressStreet1,
                addressStreet2: this.props.requestObj.addressStreet2,
                addressCity: this.props.requestObj.addressCity,
                addressState: this.props.requestObj.addressState,
                addressZip: this.props.requestObj.addressZip,
                requestingHardCopyResponse: this.props.requestObj.requestingHardCopyResponse,
                lastTransitUpdateDateKey: this.state.requestObjUpdateGuardKey,
                isHardCopyDownloaded: true,
                dispositionSubmitBy: this.props.requestObj.dispositionSubmitBy,
                isAddressEdited: false,
                initialDispositionId: Number(this.props.requestObj.initialDispositionId),
            };
            this.props.updateRequestInfo(requestUpdateModel);
        }

    }

    private getStatusDescription = (statusId: string) => {
        if (statusId) {
            for (var rec of constants.RequestStatusList) {
                if (rec.value === statusId) {
                    return rec.displayLabel;
                }
            }
        }
        return "New";
    }

    private getDispositionDescription = (dispositionId: string) => {
        if (dispositionId) {
            for (var rec of constants.RequestDispostionList) {
                if (rec.value === dispositionId) {
                    return rec.displayLabel;
                }
            }
        }
        return "";
    }

    private getTransitAdminComments = (transitComments: string, getAdmin: boolean) => {
        if (transitComments !== undefined) {
            var splitComments = transitComments.split('|');
            if (splitComments.length > 1) {
                if (getAdmin === true) {
                    var adminComments = "";
                    for (let i = 1; i < splitComments.length; i++) {
                        adminComments += splitComments[i].trim() + " ";
                    }
                    return adminComments.trim();
                } else {
                    return splitComments[0];
                }
            }
            if (getAdmin === true) {
                return "";
            } else {
                return transitComments;
            }
        }
        return "";
    }

    private getStatusDescriptionForDispositionId = (dispositionId: string) => {
        if (dispositionId !== constants.newDispositionId) {
            return this.getStatusDescription(constants.manualVerifiedRequestStatusId);
        }
        return this.getStatusDescription(constants.pendingManualVerifyStatusId);
    }

    private getStatusIdFrDispositionId = (dispositionId: string) => {
        if (dispositionId !== constants.newDispositionId) {
            return constants.manualVerifiedRequestStatusId;
        }
        return constants.pendingManualVerifyStatusId;
    }

    private updateVerificationToggle = (checked: boolean) => {
        this.setState({ updateVerification: checked });
        this.handleInputChange1("updateVerification")(checked);
    }

    private handleInputChange1 = (fieldName: string) => (value: any) => {
        this.setState({
            [fieldName]: value
        } as any, this.validateFields);

    }
}