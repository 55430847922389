import React from 'react';
import { ApiCallStatusEnum } from "../common/enums/ApiCallStatusEnum";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import * as delayRequestListApi from "../../apis/DelayRequestListApi";
import * as apiErrorHandler from "../../apis/ApiErrorHandler";
import { CircleSpinner } from "../common/CircleSpinner";
import { ErrorAlert } from "../common/ErrorAlert";
import { OpenRequestListGrid } from '../RequestList/OpenRequestListGrid';

interface IOpenRequestComponentState {
    apiCallStatus: ApiCallStatusEnum;
    VerificationRequests: IDelayRequestDto[];
    errorMessage: string;
}

interface IOpenRequestComponentProps {

}

export class OpenRequestSummary extends React.Component<IOpenRequestComponentProps, IOpenRequestComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            apiCallStatus: ApiCallStatusEnum.NotStarted,
            VerificationRequests: [],
            errorMessage: "",
        }
    }

    public async componentDidMount() {
        try {
            this.setState({ apiCallStatus: ApiCallStatusEnum.Started, VerificationRequests: [], errorMessage: "" });
            const requestData = await delayRequestListApi.GetOpenRequests();
            this.setState({ apiCallStatus: ApiCallStatusEnum.Success, VerificationRequests: requestData, errorMessage: "" });
        } catch (err) {
            const errMsg = apiErrorHandler.GetApiErrorMessage(err);
            this.setState({ apiCallStatus: ApiCallStatusEnum.Failed, VerificationRequests: [], errorMessage: errMsg });
        }
    }
    public render() {
        return (
            <div>
                {this.renderResults()}
            </div>
        )
    }
    private renderResults = () => {
        if (this.state.apiCallStatus === ApiCallStatusEnum.Started) {
            return (
                <CircleSpinner />
            )
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Failed) {
            return <ErrorAlert alertText={this.state.errorMessage} />
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Success && this.state.VerificationRequests) {
            return (
                <OpenRequestListGrid RequestDtoList={this.state.VerificationRequests} />
            );
        }
        return <div></div>

    }
}