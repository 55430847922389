import React from "react";
import { IUserDto } from "../../apis/interfaces/IUserDto";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import * as UserApi from "../../apis/UserApi";
import { ApiCallStatusEnum } from "../common/enums/ApiCallStatusEnum";
import * as apiErrorHandler from "../../apis/ApiErrorHandler";
import { CircleSpinner } from "../common/CircleSpinner";
import { ErrorAlert } from "../common/ErrorAlert";
import { UserCardList } from "./UserCardList";
import { Link } from "react-router-dom";

interface IUserSearchComponentState {
    searchText: string;
    userList: IUserDto[];
    apiCallStatus: ApiCallStatusEnum;
    errorMessage: string;
}

export class UserSearchComponent extends React.Component<{}, IUserSearchComponentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            searchText: "",
            userList: [],
            apiCallStatus: ApiCallStatusEnum.NotStarted,
            errorMessage: "",
        };
    }

    public render() {
        const addNewUrl = "/addNewUser/";
        return (
            <div>
                <NavbarComponent activeCheck= "idUsers"/>
                <div className="container">
                    <div className="row mt-2">
                        <div className="col-lg-9 mt-2">
                            <input className="form-control"
                                type="text" placeholder="Enter Search text"
                                onChange={(event) => { this.handleSearchTextChange(event.target.value) }}
                                value={this.state.searchText}
                            />
                        </div>
                        <div className="col-lg-3 mt-2">
                            <button type="button" className="btn btn-primary mr-3 "
                                onClick={this.searchUsers}
                            >Search</button>
                            <Link to={addNewUrl} className="btn btn-warning ">Add New</Link>
                        </div>
                    </div>
                    <hr />
                    {this.getUserListPart()}
                </div>
            </div>
        )
    }

    private getUserListPart = () => {
        if (this.state.apiCallStatus === ApiCallStatusEnum.Started) {
            return (
                <CircleSpinner />
            )
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Failed) {
            return (
                <ErrorAlert alertText={this.state.errorMessage} />
            )
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Success) {
            if (this.state.userList.length === 0) {
                return (
                    <div className="alert alert-primary mt-4" role="alert">
                        No match found!
                    </div>
                )
            } else {
                return (
                    <UserCardList userList={this.state.userList} />
                )
            }

        }

        return (
            <div></div>
        )


    }

    private handleSearchTextChange = (value: string) => {
        this.setState({ searchText: value })
    }

    private searchUsers = async () => {
        if (this.state.searchText) {
            try {
                this.setState({ userList: [], errorMessage: "", apiCallStatus: ApiCallStatusEnum.Started });
                var userList = await UserApi.getUserList(this.state.searchText);
                this.setState({ userList, errorMessage: "", apiCallStatus: ApiCallStatusEnum.Success });
            } catch (err) {
                var errMsg = apiErrorHandler.GetApiErrorMessage(err);
                this.setState({ userList: [], errorMessage: errMsg, apiCallStatus: ApiCallStatusEnum.Failed });
            }
        }
    }
}