import React, { Component } from "react";
import moment from "moment";
import { ServiceDatePickerControl } from "../RequestForm/Controls/ServiceDatePickerControl";


interface IDispositionDateFormState {
    fromDate: moment.Moment | undefined;
    toDate: moment.Moment | undefined;
    isFromDateValid: boolean;
    isToDateValid: boolean;
    isTriedToSubmit: boolean
    isFormValid: boolean;
}

interface IDispositionDateFormProps {
    onReportGet: (fromDateIsoString: string, toDateIsoString: string) => void;
    startDate?: string;
    endDate?: string;
}

export class DispositionDateForm extends Component<IDispositionDateFormProps, IDispositionDateFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            fromDate: undefined,
            isFromDateValid: false,
            toDate: undefined,
            isToDateValid: false,
            isTriedToSubmit: false,
            isFormValid: false,
        };
    }

    public componentDidMount(){
        if(this.props.startDate && this.props.endDate){
            this.setState({
                fromDate: moment(this.props.startDate),
                toDate: moment(this.props.endDate),
                isFormValid: true,
                isToDateValid: true,
                isFromDateValid: true,
            });
        }
    }

    public render() {
        const pastDayCount = moment().diff(moment("2020-10-15"), 'days');
        return (
            <div className="container">
                <form className="mt-2">
                    <div className="row">
                        <div className="col-lg-4">
                            <ServiceDatePickerControl
                                label="From Date (Disposition Submit Date)"
                                value={this.state.fromDate}
                                changeHandler={this.handleInputChange("fromDate")}
                                isValid={!this.state.isTriedToSubmit || this.state.isFromDateValid}
                                selectablePastDays={pastDayCount + 1}
                            />
                        </div>
                        <div className="col-lg-4">
                            <ServiceDatePickerControl
                                label="To Date (Disposition Submit Date)"
                                value={this.state.toDate}
                                changeHandler={this.handleInputChange("toDate")}
                                isValid={!this.state.isTriedToSubmit || this.state.isToDateValid}
                                selectablePastDays={pastDayCount + 1}
                            />
                        </div>
                        <div className="col-lg-4 mt-2" >
                            <button
                                type="button"
                                className="btn btn-success mt-4 " 
                                onClick={this.handleSubmit}
                            >Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    private handleInputChange = (fieldName: string) => (value: any) => {
        this.setState({
            [fieldName]: value
        } as any, this.validateFields);
    }

    private validateFields = () => {
        let isFromDateValid = this.state.fromDate ? true : false;
        let isToDateValid = this.state.toDate ? true : false;
        let isFormValid = isFromDateValid && isToDateValid;
        this.setState({isFromDateValid, isToDateValid, isFormValid});
    }

    private handleSubmit = () => {
        this.setState({isTriedToSubmit: true});
        if(this.state.isFormValid && this.state.fromDate && this.state.toDate){
          this.props.onReportGet(this.state.fromDate.toDate().toDateString(), this.state.toDate.toDate().toDateString());
        }
        
    }

}