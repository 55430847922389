import React from "react";

export interface IFormSelectControlProps {
    label: string;
    optionList: {value: number|string, displayLabel: string}[];
    changeHandler: (value: any) => void;
    value: number|string;
    isValid: boolean;
}



export class FormSelectControl extends React.Component<IFormSelectControlProps, {}> {

    public render() {
        return (
            <div className="form-group">
                <label >{this.props.label}</label>

                <select className= {this.getClassName()}
                    value={this.props.value}
                    onChange={(event) => { this.props.changeHandler(event.target.value) }}
                >
                    <option key={-1} value=""></option>
                    {this.selectOptions()}
                </select>
            </div>
        )
    }

    private selectOptions = () => {
        if (this.props.optionList) {
            const optionList = this.props.optionList.map((optChoice, index) => {
                return (
                    <option key={index} value={optChoice.value} >{optChoice.displayLabel}</option>
                )
            })
            return optionList;
        }
    }

    private getClassName = ()=>{
        if(this.props.isValid){
            return "form-control";
        } else {
            return "form-control is-invalid"
        }
    }


}