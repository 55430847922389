import React from "react";
import { IUserDto } from "../../apis/interfaces/IUserDto";
import { UserCard } from "./UserCard";

export interface IUserCardListProps {
    userList: IUserDto[];
}

export class UserCardList extends React.Component<IUserCardListProps,{}>{

    public render(){

        var cardList = this.props.userList.map((rec, index) =>{
            return (
                <div className="col-lg-4" key={index}>
                    <UserCard userInfo={rec}/>
                </div>
            );
        })

        return (
            <div className="row">
                {cardList}
            </div>
        )
    }

}

