import React from "react";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";

import { ITableColumn } from "./gridTypes/ITableColumn";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import * as GridColumnFormaters from "./GridColumnFormaters";
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as DelayRequestApi from "../../apis/DelayRequestApi";
import * as GridRowExpander from "./GridRowExpander";
import * as CurrentUserApi from "../../apis/CurrentUserApi";
import { IUserDto } from "../../apis/interfaces/IUserDto";
import { FormTextAreaControl } from "../common/FormTextAreaControl";

export interface IRequestListGridProps {
    RequestDtoList: IDelayRequestDto[];
}
interface IAutoverifiedCopyRequestComponentState {
    btnValue: string;
    dispositionValue: string;
    userAuthenticated: IUserDto | null;
    adminComments: string;
    isCommentsValid: boolean;
    commentsValidationMessage: string;
}
export const requestStatusOptions = {
    New: 'New',
    AutoVerified: 'Verified (Auto)',
    PendingManualVerify: 'Pending manual',
    ManualVerified: 'Verified (Manual)'
};
export const requestDispositionOptions = {
    New: 'New',
    Approved: 'Approved',
    Denied: 'Denied',
    CannotVerify: 'CannotVerify'
};

export class AutoverifiedRequestListGrid extends React.Component<IRequestListGridProps, IAutoverifiedCopyRequestComponentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            btnValue: '',
            dispositionValue: 'Approve',
            userAuthenticated: null,
            adminComments: '',
            isCommentsValid: true,
            commentsValidationMessage: "Comments Required (up to 300 characters)",
        }
        this.submitRequest = this.submitRequest.bind(this);
    }

    public async componentDidMount() {
        const authUser = await CurrentUserApi.GetCachedAuthUser();
        this.setState({ userAuthenticated: authUser });
    }


    private gridColumns: ITableColumn[] = [
        {
            dataField: "id",
            text: "Id",
            hidden: true
        },
        {
            dataField: "referenceNumber",
            text: "Reference No",
            formatter: GridColumnFormaters.referenceNumFormater,
            filter: textFilter(),
            sort: true
        },
        {
            dataField: "requestDate",
            text: "Request Date",
            formatter: GridColumnFormaters.serviceDateFormater,
            filter: dateFilter(),
            sort: true
        },
        {
            dataField: 'routes',
            isDummyField: true,
            text: 'Routes',
            formatter: GridColumnFormaters.RouteIconListFormater,
            filter: textFilter({
                onFilter: GridColumnFormaters.RouteListCustomFilter
            })
        },
        {
            dataField: "serviceDate",
            text: "Service Date",
            formatter: GridColumnFormaters.serviceDateFormater,
            filter: dateFilter(),
            sort: true
        },
        {
            dataField: "firstName",
            text: "First Name ",
            filter: textFilter(),
            sort: true
        },
        {
            dataField: "lastName",
            text: "Last Name ",
            filter: textFilter(),
            sort: true
        },
        {
            dataField: "statusDescription",
            text: "Status",
            formatter: GridColumnFormaters.statusColumnFormater,
            filter: selectFilter({
                options: requestStatusOptions,
                caseSensitive: false,
            }),
            sort: true
        },
        {
            dataField: 'dispositionDescription',
            isDummyField: true,
            text: 'Disposition',
            formatter: GridColumnFormaters.dispositionColumnFormater,
            filter: selectFilter({
                options: requestDispositionOptions,
                caseSensitive: false,
            }),
            sort: true,
        },


    ];
    public render() {
        var rowSelected: '';
        const expandrow = {
            showExpandColumn: true,
            renderer: GridRowExpander.GetExpandedRow,
        };

        const selectRow = {
            mode: 'checkbox',
            clicktoSelect: true,
            // clicktoExpand: true,
            onSelect: (row: any, isSelect: boolean, rowIndex: number, e: any) => {
                var stringSelect = this.state.btnValue;
                if (isSelect) {
                    stringSelect = stringSelect + row.referenceNumber + ",";
                    this.setState({
                        btnValue: stringSelect
                    })
                } else {
                    if (stringSelect.includes(row.referenceNumber)) {
                        const re = row.referenceNumber + ",";
                        stringSelect = stringSelect.replace(re, "")
                    }
                    this.setState({
                        btnValue: stringSelect
                    })
                }
            },
            onSelectAll: (row: any, rows: any, isSelect: any, e: any) => {
                if (row) {
                    Object.keys(rows).forEach(key => {
                        if (key === "0") {
                            rowSelected = rows[key].referenceNumber
                        } else {
                            rowSelected += "," + rows[key].referenceNumber
                        }
                    })
                    rowSelected += ","

                    this.setState({ btnValue: rowSelected });
                } else {
                    this.setState({ btnValue: "" });
                }
            },
        }
        const options = {
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '25', value: 25
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }]
        }
        const defaultSorted = [{
            dataField: "id",
            order: 'asc'
        }]
        return (
            <div>
                <BootstrapTable keyField='id'
                    data={this.props.RequestDtoList}
                    columns={this.gridColumns}
                    bootstrap4={true}
                    pagination={paginationFactory(options)}
                    filter={filterFactory()}
                    selectRow={selectRow}
                    expandRow={expandrow}
                    defaultSorted={defaultSorted}

                />
                {this.showMultiDisposition()}
                <br></br><br></br>
            </div>
        )
    }

    private showMultiDisposition() {
        const checkMassClose = this.state.userAuthenticated?.roleList.filter(a => a.roleName.toUpperCase() === "MASSCLOSE").length;
        if (this.state.btnValue !== "" && checkMassClose === 1) {
            return (
                <div>
                    <div className="row">
                        <div className="col-lg-2">
                            <select className="form-control" value={this.state.dispositionValue} onChange={this.handleDispositionChange}>
                                <option value="Approve">Approve</option>
                                <option value="Deny">Deny</option>
                                <option value="CannotVerify">Cannot Verify</option>
                            </select>
                        </div>
                        <div className="col-lg-2">
                            <button className="btn btn-success " onClick={this.updateDispositionRequest}>{this.state.dispositionValue} Request{this.state.btnValue.split(',').length > 2 ? 's' : ''}</button>
                        </div>
                        <div className="col-lg-2">
                            <button className="btn btn-success " onClick={this.submitRequest}> Submit for Email</button>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-lg-4">
                            <FormTextAreaControl
                                label="Comments"
                                rowcount={3}
                                changeHandler={this.handleAdminComments()}
                                value={this.state.adminComments}
                                isValid={this.state.isCommentsValid}
                                validationMessage={this.state.commentsValidationMessage}
                            />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <button className="btn btn-success " disabled={!this.state.btnValue} onClick={this.submitRequest}> Submit for Email</button>
            )
        }
    }

    private handleDispositionChange = (e: any) => {
        this.setState({
            dispositionValue: e.target.value,
        })
    }

    private handleAdminComments = () => (value: any) => {
        this.setState({ adminComments: value });
    }

    private validateComments = () => {
        if (this.state.btnValue !== "" && (this.state.adminComments !== "" && this.state.adminComments.length <= 300)) {
            this.setState({ isCommentsValid: true })
        } else if (this.state.btnValue === "" && this.state.adminComments === "") {
            this.setState({ isCommentsValid: true })
        } else {
            this.setState({ isCommentsValid: false });
            if (this.state.btnValue !== "" && (this.state.adminComments !== "" && this.state.adminComments.length > 300)) {
                const characterCount = this.state.adminComments.length - 300;
                this.setState({
                    commentsValidationMessage: `Maximum 300 characters currently it is ${characterCount} character${characterCount > 1 ? 's' : ''} more`
                });
            } else {
                this.setState({
                    commentsValidationMessage: "Comments Required (up to 300 characters)"
                })
            }
        }
    }

    private updateDispositionRequest = async () => {
        try {
            this.validateComments();
            if (this.state.adminComments !== '' && this.state.btnValue !== '' && this.state.isCommentsValid === true) {
                const downloadList = this.state.btnValue.slice(0, -1);
                toast(`Updating Disposition to ${this.state.dispositionValue} for ${downloadList}`, { transition: Slide, type: toast.TYPE.INFO, });
                var updatedRequestResponse = await DelayRequestApi.UpdateDispositionMultiRecords(downloadList, this.state.dispositionValue, this.state.adminComments);
                if (updatedRequestResponse) {
                    this.setState({
                        btnValue: '', adminComments: '', isCommentsValid: true
                    })
                    window.location.reload();
                }
            }

        } catch (err) {
            toast(err, { transition: Slide, type: toast.TYPE.INFO, });
        }

    }

    private submitRequest = async () => {
        try {
            const downloadList = this.state.btnValue.slice(0, -1);
            toast("Confirming " + downloadList, { transition: Slide, type: toast.TYPE.INFO, });
            var submittedRequests = await DelayRequestApi.UpdateAutoverified(downloadList);
            if (submittedRequests) {
                this.setState({
                    btnValue: '', adminComments: '', isCommentsValid: true
                })
                window.location.reload();
            }


        } catch (err) {
            toast(err, { transition: Slide, type: toast.TYPE.INFO, });
        }

    }

}