import React, { Component } from "react";


interface IEmailSearchFormState {
    emailAddress: string;
    firstName: string;
    lastName: string;
    isTriedToSubmit: boolean
}

interface IEmailSearchFormProps {
    onReportGet: (emailAddress: string, firstName: string, lastName: string) => void;
    emailAddress?: string;
    firstName?: string;
    lastName?: string
}

export class EmailSearchForm extends Component<IEmailSearchFormProps, IEmailSearchFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            emailAddress: "",
            firstName: "",
            lastName: "",
            isTriedToSubmit: false,
        };
    }

    public componentDidMount() {

        if (this.props.emailAddress !== 'null' && this.props.emailAddress) {
            this.setState({
                emailAddress: this.props.emailAddress,
            });}
            if (this.props.firstName !== 'null' && this.props.firstName) {
                this.setState({
                    firstName: this.props.firstName,
                });}
                if (this.props.lastName) {
                    this.setState({
                        lastName: this.props.lastName,
                    });
        }
    }

    public render() {
        return (
            <div className="container">
                <form className="mt-2">
                    <div className="row">
                <div className="col-lg-9 mt-2">
                    <input className="form-control"
                        type="text" placeholder="Enter Email Address"
                        onChange={(event) => { this.handleEmailChange(event.target.value) }}
                        value={this.state.emailAddress}
                    />
                    <div className="row mt-2">
                        <div className="col-lg-6 mt-2">
                            <input className="form-control"
                                type="text" placeholder="Enter First Name"
                                onChange={(event) => { this.handleFirstNameChange(event.target.value) }}
                                value={this.state.firstName}
                            />
                        </div>
                        <div className="col-lg-6 mt-2">
                            <input className="form-control"
                                type="text" placeholder="Enter Last Name"
                                onChange={(event) => { this.handleLastNameChange(event.target.value) }}
                                value={this.state.lastName}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 mt-5">
                    <button type="button" className="btn btn-primary mr-3 "
                        onClick={this.handleSubmit}
                    >Search</button>
                </div>
                </div>
                </form>
            </div>
        )
    }

    private handleEmailChange = (value: string) => {
        this.setState({ emailAddress: value })
    }

    private handleFirstNameChange = (value: string) => {
        this.setState({ firstName: value })
    }

    private handleLastNameChange = (value: string) => {
        this.setState({ lastName: value })
    }

    private handleSubmit = () => {
        let email = ""
        let fName = ""
        let lName = ""
        if (this.state.emailAddress) {
            email = this.state.emailAddress
        }
        if (this.state.firstName) {
            fName = this.state.firstName
        }
        if (this.state.lastName) {
            lName = this.state.lastName
        }
        this.setState({ isTriedToSubmit: true });
        if (this.state.emailAddress || this.state.firstName || this.state.lastName) {
            this.props.onReportGet(email, fName, lName);
        }

    }

}