import React from "react";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { EmailSearchForm } from "./EmailSearchForm";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import * as DelayRequestListApi from "../../apis/DelayRequestListApi";
import { CircleSpinner } from "../common/CircleSpinner";
import { ErrorAlert } from "../common/ErrorAlert";
import { RequestListGrid } from "../RequestList/RequestListGrid";
import { RouteComponentProps } from "react-router-dom";
import * as ApiErrorHandler from "../../apis/ApiErrorHandler";

interface IEmailSearchComponentState {
    requestGetStarted: boolean;
    requestGetFailed: boolean;
    requestGetSuccess: boolean;
    VerificationRequests: IDelayRequestDto[];
    errorMessage: string;
}

interface IEmailSearchComponentProps extends RouteComponentProps<{ emailAddress: string, firstName: string, lastName: string }> {

}

export class EmailSearchComponent extends React.Component<IEmailSearchComponentProps, IEmailSearchComponentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            requestGetStarted: false,
            requestGetFailed: false,
            requestGetSuccess: false,
            VerificationRequests: [],
            errorMessage: "",
        }
    }
    public async componentDidMount() {
        if (this.props.match.params.emailAddress || this.props.match.params.firstName || this.props.match.params.lastName) {
            try {
                let emailAddress = this.props.match.params.emailAddress;
                let firstName = this.props.match.params.firstName;
                let lastName = this.props.match.params.lastName;
                this.setState({ requestGetStarted: true, requestGetFailed: false, requestGetSuccess: false, VerificationRequests: [], errorMessage: "" });
                var requestList = await DelayRequestListApi.GetRequestsByEmail(emailAddress, firstName, lastName);
                this.setState({ requestGetStarted: false, requestGetFailed: false, requestGetSuccess: true, VerificationRequests: requestList, errorMessage: "" });
            } catch (err) {
                const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
                this.setState({ requestGetStarted: false, requestGetFailed: true, requestGetSuccess: false, VerificationRequests: [], errorMessage });
            }
        }
    }
    public render() {
        let email = this.props.match.params.emailAddress === 'null' ? '' : this.props.match.params.emailAddress
        let fName = this.props.match.params.firstName === 'null' ? '' : this.props.match.params.firstName
        return (
            <div>
                <NavbarComponent activeCheck="idSearchRequests" />
                <div className="container-fluid">
                    <EmailSearchForm onReportGet={this.onReportGet} emailAddress={email} firstName={this.props.match.params.firstName} lastName={this.props.match.params.lastName} />
                    <br></br>
                    {this.renderResults()}
                </div>
            </div>
        )
    }

    private onReportGet = async (emailAddress: string, firstName: string, lastName: string) => {
        try {
            this.setState({ requestGetStarted: true, requestGetFailed: false, requestGetSuccess: false, VerificationRequests: [], errorMessage: "" });
            var requestList = await DelayRequestListApi.GetRequestsByEmail(emailAddress, firstName, lastName);
            this.setState({ requestGetStarted: false, requestGetFailed: false, requestGetSuccess: true, VerificationRequests: requestList, errorMessage: "" });
        } catch (err) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ requestGetStarted: false, requestGetFailed: true, requestGetSuccess: false, VerificationRequests: [], errorMessage });
        }

        this.props.history.push(`/searchByName/${emailAddress}/${firstName}/${lastName}`)
    }

    private renderResults = () => {
        if (this.state.requestGetStarted) {
            return <CircleSpinner />
        } else if (this.state.requestGetFailed) {
            return <ErrorAlert alertText={this.state.errorMessage} />
        } else if (this.state.requestGetSuccess && this.state.VerificationRequests) {
            return (
                <RequestListGrid RequestDtoList={this.state.VerificationRequests} />
            );
        }
        return <div></div>

    }
}