import React from "react";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import moment from "moment";
import { TransitTypeEnum } from "../../apis/enums/TransitTypeEnum";

export function GetExpandedRow(row: IDelayRequestDto) {
    var totalDelay = '00:00:00'
    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-body">
                    <div>  {renderDelayRouteCards(row, totalDelay)} </div>
                    <div className="row">
                        <div className="col-lg-6 float-right">
                            <h6 className="card-title">Total Delay: {getTotalDelay(row, totalDelay)} Minutes</h6>
                        </div>
                        <div className="col-lg-6">
                            <h6 className="card-title">User Delay: {row.lenthOfDelay} Minutes</h6>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6 float-right">
                            <h6 className="card-title">Disposition: <b> {row.dispositionDescription}</b></h6>
                        </div>
                        <div className="col-lg-6">
                            <h6 className="card-title">User Comments: {row.userComments}</h6>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

// function getFormatedRequestDate(requestDateStr: string) {
//     const requestDateFormated = moment(requestDateStr).format("MM/DD/YYYY dddd hh:mm A");
//     return requestDateFormated;
// }

function getTotalDelay(row: IDelayRequestDto, totalDelay: any) {
    if (row.passedRouteList) {
        var newSortedList: any[] = [];
        const groupByLegNumber = (array: any, key: any) => {
            // Returns the final array
            return array.reduce((result: any, currentValue: any) => {
                // If an array exists based on legNumber then push to the array. Else create an array and push the object
                (result[currentValue[key]] = result[currentValue[key]] || []).push(
                    currentValue
                );
                return result;
            }, {});
        };
        var groupSortedList = groupByLegNumber(row.passedRouteList, 'legNumber');
        Object.keys(groupSortedList).forEach(key => {
            var rec = {
                id: key,
                listOfResultPerLeg: groupSortedList[key]
            }
            newSortedList.push(rec);
        })
        var routeCardList = newSortedList.map((rtRec: any, index: any) => {
            const routeCard = rtRec.listOfResultPerLeg[0];
            var isFirst = true;
            var sortedList: any[] = [];
            if (routeCard.delayVerifyResult.length > 0) {
                sortedList = routeCard.delayVerifyResult.sort((a: any, b: any) => {
                    if (moment(a.totalDelay, 'h:mm:ssa') < moment(b.totalDelay, 'h:mm:ssa')) {
                        return 1;
                    } else if (moment(a.totalDelay, 'h:mm:ssa') > moment(b.totalDelay, 'h:mm:ssa')) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
            }
            sortedList.map((rec: any, index: any) => {
                if (rec) {
                    if (isFirst) {
                        isFirst = false;
                        const secs = moment(rec.totalDelay, 'h:mm:ssa').second()
                        const minute = moment(rec.totalDelay, 'h:mma').minute()
                        const hour = moment(rec.totalDelay, 'h:mma').hour()
                        var localTD = moment(totalDelay, 'mm:ss').clone().add(secs, "seconds")
                            .add(minute, "minutes").add(hour, "hours")
                        totalDelay = localTD.format('mm:ss')
                        return totalDelay;
                    }
                }
            })
            return totalDelay;
        })
        return totalDelay;
    }
}

function renderDelayRouteCards(row: IDelayRequestDto, totalDelay: any) {
    if (row.passedRouteList) {
        var newSortedList: any[] = [];
        const groupByLegNumber = (array: any, key: any) => {
            // Returns the final array
            return array.reduce((result: any, currentValue: any) => {
                // If an array exists based on legNumber then push to the array. Else create an array and push the object
                (result[currentValue[key]] = result[currentValue[key]] || []).push(
                    currentValue
                );
                return result;
            }, {});
        };
        var groupSortedList = groupByLegNumber(row.passedRouteList, 'legNumber');
        Object.keys(groupSortedList).forEach(key => {
            var rec = {
                id: key,
                listOfResultPerLeg: groupSortedList[key]
            }
            newSortedList.push(rec);
        })
        var routeCardList = newSortedList.map((rtRec: any, index: any) => {
            const routeCard = rtRec.listOfResultPerLeg[0];
            var isFirst = true;
            var sortedList: any[] = [];
            if (routeCard.delayVerifyResult.length > 0) {
                sortedList = routeCard.delayVerifyResult.sort((a: any, b: any) => {
                    if (moment(a.totalDelay, 'h:mm:ssa') < moment(b.totalDelay, 'h:mm:ssa')) {
                        return 1;
                    } else if (moment(a.totalDelay, 'h:mm:ssa') > moment(b.totalDelay, 'h:mm:ssa')) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
            }
            var resultInfo = sortedList.map((resultRec: any, index: any) => {
                if (resultRec) {
                    if (isFirst) {
                        isFirst = false;
                        return (
                            <div className="row align-items-center">
                                <div className="col-sm-1">
                                    {/* {rec.legNumber} */}
                                </div>
                                <div className="col-sm-2">
                                    {getRouteIcon(routeCard)}

                                </div>
                                <div className="col-sm-8">
                                    <div className="row rowPaddLeft">
                                        <div className="col-sm-12">
                                            <div>
                                                {routeCard.entryPointName} (<b>{routeCard.entryTime}</b>) to {routeCard.exitPointName} (<b>{routeCard.exitTime}</b>)
                                            </div>
                                        </div>
                                    </div>
                                    {getAutoVerifyResultsInfo(resultRec)}
                                    <br></br>
                                </div>
                            </div>

                        )
                    }
                }
            })
            return (
                <div>
                    {resultInfo}
                </div>
            )
        })
        return (
            <div>
                <div className="card mt-1">
                    <div className="card-body"></div>
                    {routeCardList}
                </div>
            </div>
        )
    }
}

function getRouteIcon(rec: any) {
    const delayRouteObj = rec;
    if (delayRouteObj.routeType === TransitTypeEnum.Subway &&
        delayRouteObj.routeShortName) {
        const imgSrc = `lineIcons/${delayRouteObj.routeShortName}.svg`;
        return (<img src={imgSrc} style={{ marginRight: 5 }} alt={delayRouteObj.routeShortName} height="25" width="25" />)
    } else {
        return <span className="badge badge-primary" style={{ fontSize: 15 }}>
            {delayRouteObj.routeShortName}
        </span>
    }
}

function getAutoVerifyResultsInfo(rec: any) {
    const delayVerifyResult = rec;
    if (delayVerifyResult) {
        return (
            <div className="highlight">
                <hr />
                <div className="row">
                    <div className="col-sm-4">
                        Start Time: <b>{delayVerifyResult.departTimeActual}</b>
                    </div>
                    <div className="col-sm-4">
                        End Time: <b>{delayVerifyResult.arriveTimeActual}</b>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        Waiting Delay: <span style={{ padding: 2 }} className="bg-secondary text-white">{delayVerifyResult.waitingTimeDelay}</span>
                    </div>
                    <div className="col-sm-4">
                        Travel Delay: <span style={{ padding: 2 }} className="bg-secondary text-white" >{delayVerifyResult.travelTimeDelay}</span>
                    </div>
                    <div className="col-sm-4">
                        Total: <span style={{ padding: 2 }} className="bg-warning  text-white">{delayVerifyResult.totalDelay}</span>
                    </div>
                </div>
            </div>
        )
    }
}