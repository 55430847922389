import React, { Component } from 'react';
import { NavbarComponent } from '../Navbar/NavbarComponent';
import { RequestForm } from './RequestForm';

export class RequestFormComponent extends Component {

    public render() {
        return (
            <div>
                <NavbarComponent activeCheck= "idNewRequest"/>
                <div className="container">
                    <div className="dm-pageHeader">
                        <h1 className="display-4 dm-font-header-bold">Subway and Bus Delay Verification</h1>
                    </div>
                    <RequestForm/>
                   
                </div>
            </div>
        )
    }
}