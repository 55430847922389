import axios from "axios";
import { IUserDto } from "./interfaces/IUserDto";
import { IUserUpdateModel } from "./interfaces/IUserUpdateModel";

export async function addUpdateUser(userUpdateModel: IUserUpdateModel){
    const url = `/users/addUpdateUser`;
    const response = await axios.post<IUserDto>(url, userUpdateModel);
    return response.data;
}

export async function GetUser(userId: string){
    const url = `/users/?userId=${userId}`;
    const response = await axios.get<IUserDto>(url);
    return response.data;
}

export async function getUserList(searchText: string){
    const url =`/users/search/?searchText=${searchText}`;
    var response = await axios.get<IUserDto[]>(url);
    return response.data;
}