import React from 'react';
import { ApiCallStatusEnum } from "../common/enums/ApiCallStatusEnum";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { AutoverifiedRequestSummary } from "./AutoverifiedRequestSummary";

interface IAutoverifiedRequestComponentState {
    apiCallStatus: ApiCallStatusEnum;
    VerificationRequests: IDelayRequestDto[];
    errorMessage: string;
}

interface IAutoverifiedRequestComponentProps {

}


export class AutoverifiedRequestComponent extends React.Component<IAutoverifiedRequestComponentProps, IAutoverifiedRequestComponentState> {

    public render(){
        return (
            <div>
                <NavbarComponent activeCheck= "idAutoverifiedRequests"/>
                <div className="mt-2">
                        <AutoverifiedRequestSummary/>
                    </div>
                </div>
        );
    }
}