import { IAddEditUserFormState, IAddEditUserFormValidationFields } from "./interfaces/IAddEditUserFormState";


export function validateUserData(stateObj: IAddEditUserFormState) {

    let validationObj: IAddEditUserFormValidationFields = {
        isBscIdValid: stateObj.BscId ? true : false,
        bscIdValidationMessage: "", 
        isFirstNameValid: stateObj.FirstName ? true : false,
        isLastNameValid: stateObj.LastName ? true : false,
        isEmailValid: stateObj.Email ? true : false,
        isUserRolesValid: stateObj.UserRoles.length > 0 ? true : false,
        isFormValid: true
    };

    if(validationObj.isBscIdValid){
        if(stateObj.BscId.length !== 7){
            validationObj.bscIdValidationMessage = "BSC Id must be 7 characters long";
            validationObj.isBscIdValid = false;
        }

    }

    validationObj.isFormValid =  validationObj.isBscIdValid &&
        validationObj.isFirstNameValid &&
        validationObj.isLastNameValid &&
        validationObj.isEmailValid &&
        validationObj.isUserRolesValid;

    return validationObj;

}