import React from "react";
import { DispositionAuditCard } from "./DispositionAuditCard";
import { IDispositionAuditDto } from "../../../apis/interfaces/IDispositionAuditDto";

export interface IDispositionAuditListProps {
    DispositionAuditList: IDispositionAuditDto[];
}

export class DispositionAuditCardList extends React.Component<IDispositionAuditListProps, {}> {

    public render() {
        const cardList = this.props.DispositionAuditList.map((rec, index) => {
            return (
                <div className="col-lg-12" key={index}>
                    {index === 0 ?
                        <div className="row">
                            <div className="col-sm-4">
                                <b> Disposition Changed By</b>
                            </div>
                            <div className="col-sm-4">
                                <b>  Changed Date</b>
                            </div>
                            <div className="col-sm-2">
                                <b>  Old Value</b>
                            </div>
                            <div className="col-sm-2">
                                <b> New Value</b>
                            </div>
                        </div> : ''}
                    <hr />
                    <DispositionAuditCard DispositionAuditObj={rec} />
                </div>
            )
        })

        return (
            <div className="row">
                {cardList}
            </div>
        )
    }
}