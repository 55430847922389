import React from 'react';
import { ApiCallStatusEnum } from "../common/enums/ApiCallStatusEnum";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { OpenRequestSummary } from "./OpenRequestSummary";

interface IOpenRequestComponentState {
    apiCallStatus: ApiCallStatusEnum;
    VerificationRequests: IDelayRequestDto[];
    errorMessage: string;
}

interface IOpenRequestComponentProps {

}


export class OpenRequestComponent extends React.Component<IOpenRequestComponentProps, IOpenRequestComponentState> {

    public render(){
        return (
            <div>
                <NavbarComponent activeCheck= "idOpenRequests"/>
                <div className="mt-2">
                        <OpenRequestSummary/>
                    </div>
                </div>
        );
    }
}