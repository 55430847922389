import React from "react";

export interface ITransitCommentTextArea {
    label: string;
    rowcount: number;
    changeHandler: (value: any) => void;
    value: string;
    isValid: boolean;
    validationMessage?: string;
    isAdminComments: boolean;
}


export class TransitCommentTextArea extends React.Component<ITransitCommentTextArea, {}> {

    public render() {
        if (this.props.isAdminComments === true) {
            return (
                <div className="form-group ">
                    <label>{this.props.label}</label>
                    <textarea className={this.getClassName()}
                        rows={this.props.rowcount}
                        value={this.props.value}
                        onChange={(event) => { this.props.changeHandler(event.target.value) }}
                    ></textarea>
                    {this.props.validationMessage && !this.props.isValid &&
                        <div className="invalid-feedback"> {this.props.validationMessage} </div>}

                </div>
            )
        } else {
            return (
                <div className="form-group readonly">
                    <label>{this.props.label}</label>
                    <textarea className={this.getClassName()}
                        value={this.props.value}
                        readOnly={true}
                    ></textarea>
                    {this.props.validationMessage && !this.props.isValid &&
                        <div className="invalid-feedback"> {this.props.validationMessage} </div>}

                </div>
            )
        }
    }

    private getClassName = () => {
        if (this.props.isValid) {
            return "form-control";
        } else {
            return "form-control is-invalid"
        }
    }

}