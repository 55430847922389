import axios from "axios";
import { IBusTrainRoute } from "./interfaces/IBusTrainRoute";

export async function GetSubwayRoutes(serviceDate: string) {
    const url = `/Routes/list?routeType=subway&date=${serviceDate}`;
    const response = await axios.get<IBusTrainRoute[]>(url);
    return response.data;
}

export async function GetNearbySubwayRoutes(serviceDate: string, lat: number, lon: number) {
    const url = `/Routes/list-nearby?routeType=subway&date=${serviceDate}&lon=${lon}&lat=${lat}`;
    const response = await axios.get<IBusTrainRoute[]>(url);
    return response.data;
}
