import { IRequestFormState } from "./interfaces/IRequestFormState";
import { IRequestFormValidation } from "./interfaces/IRequestFormValidation";
import validator from "validator";
import moment from "moment";

export function validateRequestForm(state1: IRequestFormState) {
    const state = state1;  // a way to prevent modifications to state.
    let validationObj: IRequestFormValidation = {
        isFirstNameValid: state.firstName ? true : false,
        isLastNameValid: state.lastName ? true : false,
        isEmailAddressValid: false,
        isEmailAddressMatchWithConfirm: true,
        emailAddressValidationMessage: "",
        emailAddressConfirmValidationMessage: "",
        isDateOfServiceValid: false,
        isLateMinutesValid: false,
        isRouteDataValid: state.routeData.length > 0 ? true : false,
        isAddressStreet1Valid: true,
        isAddressStreet2Valid: true,
        isAddressCityValid: true,
        isAddressStateValid: true,
        isAddressZipValid: true,
        isFormValid: false,
        isServiceValid: false,
        serviceDateValidation: "",
        isConfirmEmailAddressValid: false,
        isUserCommentsValid: true
    };

    //validate Service Dates
    if (state.dateOfService !== undefined) {
        const serviceDate = moment(state.dateOfService);
        const validDate = moment().add(-181, 'days');
        const currentDate = moment();
        if (serviceDate.isValid()) {
            if (validDate <= serviceDate && serviceDate <= currentDate) {
                validationObj.isDateOfServiceValid = true;
            }
            else {
                validationObj.serviceDateValidation = "Delay must have been within the past 180 days";
            }
        } else {
            validationObj.serviceDateValidation = "Please enter valid Date";
        }
    }

    //Validate future trips
    if (state.dateOfService && state.routeData.length > 0) {
        let serviceDate = state.dateOfService;
        let isDateChanged = false;
        const entryTime = moment(state.routeData[0].entryTime, ["h:mm A"]).format();
        let exitTime = moment(state.routeData[state.routeData.length - 1].exitTime, ["h:mm A"]).format();
        if (entryTime > exitTime) {
            serviceDate = (serviceDate).add(1, 'day');
            isDateChanged = true;
        }
        let dateOfService = serviceDate.format("YYYY-MM-DD")
        exitTime = moment(state.routeData[state.routeData.length - 1].exitTime, ["h:mm A"]).format("HH:mm:ss");
        const serviceTime = moment(`${dateOfService} ${exitTime}`, 'YYYY-MM-DD HH:mm:ss').toISOString();
        if (serviceTime < moment().toISOString()) {
            validationObj.isServiceValid = true;
        }
        if (isDateChanged) {
            serviceDate = serviceDate.add(-1, 'day');
        }
    }
    // Validating Email Address
    if ((state.emailAddress.toLowerCase() && validator.isEmail(state.emailAddress.toLowerCase())) || (state.requestingHardCopyResponse === true)) {
        validationObj.isEmailAddressValid = true;
    } else {
        validationObj.isEmailAddressValid = false;
        validationObj.emailAddressValidationMessage = "Email address is not valid. Please provide valid email address or request a hardcopy";
    }

    // Validating Confirm Email Address
    if (state.emailAddressConfirm.toLowerCase() && validator.isEmail(state.emailAddressConfirm.toLowerCase())) {
        validationObj.isConfirmEmailAddressValid = true;
    } else {
        validationObj.isConfirmEmailAddressValid = false;
        validationObj.emailAddressConfirmValidationMessage = "Email address is not valid";
    }

    // var inputMinutes = 0;
    // if(state.routeData.map((rec, index) => {
    //     inputMinutes += moment.duration(moment(rec.exitTime, 'h:mma').diff(moment(rec.entryTime, 'h:mma'))).asMinutes();
    //     // inputMinutes =  moment(rec.exitTime, 'h:mma').diff(moment(rec.entryTime, 'h:mma'));
    // }))
    // Valdating Lateness Duration
    if (state.lateMinutes && state.lateMinutes >= 10) {
        validationObj.isLateMinutesValid = true;
    } else {
        validationObj.isLateMinutesValid = false;
    }

    // Validating user comments
    if (state.delayComment.length > 1000) {
        validationObj.isUserCommentsValid = false;
    } else {
        validationObj.isUserCommentsValid = true;
    }


    // Validating Address Fields If needed
    if (state.requestingHardCopyResponse === true) {
        validationObj.isAddressStreet1Valid = state.addressStreet1 ? true : false;
        validationObj.isAddressCityValid = state.addressCity ? true : false;
        validationObj.isAddressZipValid = state.addressZip ? true : false;
        validationObj.isAddressStateValid = state.addressState ? true : false;
    } else if (state.emailAddress !== "" && state.emailAddressConfirm !== "") {
        const validEmailRegex = RegExp(
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
        const errorMsg = validEmailRegex.test(state.emailAddress) ? true : false;
        if (errorMsg) {
            validationObj.isEmailAddressValid = true;
            if (validationObj.isConfirmEmailAddressValid &&
                state.emailAddress.toLowerCase() !== state.emailAddressConfirm.toLowerCase()) {
                validationObj.isEmailAddressMatchWithConfirm = false;
                validationObj.emailAddressConfirmValidationMessage = "Email addresses provided do not match"
            }
        }
    }

    else {
        validationObj.isEmailAddressValid = state.emailAddress ? true : false;
        validationObj.isEmailAddressMatchWithConfirm = state.isEmailAddressMatchWithConfirm ? true : false;
    }

    validationObj.isFormValid =
        validationObj.isFirstNameValid &&
        validationObj.isLastNameValid &&
        validationObj.isEmailAddressValid &&
        validationObj.isEmailAddressMatchWithConfirm &&
        validationObj.isDateOfServiceValid &&
        validationObj.isLateMinutesValid &&
        validationObj.isAddressStreet1Valid &&
        validationObj.isAddressStreet2Valid &&
        validationObj.isAddressCityValid &&
        validationObj.isAddressStateValid &&
        validationObj.isAddressZipValid &&
        validationObj.isRouteDataValid &&
        validationObj.isServiceValid &&
        validationObj.isUserCommentsValid;


    return validationObj;

}