import React from "react";

interface IReferenceNumState {
    referenceNum: string;
}

interface IReferenceNumbFormProps{
    onSearchClick: (numSearched: string) => void;
    referenceNum?: string;
}

export class ReferenceNumForm extends React.Component<IReferenceNumbFormProps, IReferenceNumState> {
    constructor(props:any){
        super(props);
        this.state = {
            referenceNum: ""
        };
    }

    public componentDidMount(){
        if(this.props.referenceNum){
            this.setState({referenceNum: this.props.referenceNum});
        }
    }

    public render(){
        return (
            <div>
               <div className="row mt-2">
                        <div className="col-lg-9 mt-2">
                            <input className="form-control"
                                type="text" placeholder="Enter Reference Number"
                                onChange={(event) => { this.handleSearchTextChange(event.target.value) }}
                                value={this.state.referenceNum}
                            />
                        </div>
                        <div className="col-lg-3 mt-2">
                            <button type="button" className="btn btn-primary mr-3 "
                                onClick={this.onSearchClick}
                            >Search</button>
                        </div>
                    </div>
            </div>
        )
    }

    private handleSearchTextChange = (value: string) => {
        this.setState({ referenceNum: value })
    }

    private onSearchClick = ()=> {
        if(this.state.referenceNum && this.state.referenceNum.trim()){
            this.props.onSearchClick(this.state.referenceNum.trim())
        }
    }

}