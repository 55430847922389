import React from "react";
import { FormCheckBoxControl } from "../common/FormCheckBoxControl";
import { FormInputControl } from "../common/FormInputControl";
import { Collapse } from "reactstrap";

export interface IHardCopyAddressInputProps {
    addressStreet1: string;
    addressStreet2: string;
    addressCity: string;
    addressState: string;
    addressZip: string;
    requestingHardCopyResponse: boolean;
    isAddressStreet1Valid: boolean;
    isAddressStreet2Valid: boolean;
    isAddressCityValid: boolean;
    isAddressStateValid: boolean;
    isAddressZipValid: boolean;
    isTriedToSubmit: boolean;
    handleInputChange: (fieldName: string) => (value: any) => void;
}

export interface IHardCopyAddressInputState {
    isAddressDetailOpen: boolean;
}

export class HardCopyAddressInput extends React.Component<IHardCopyAddressInputProps, IHardCopyAddressInputState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isAddressDetailOpen: false
        }
    }

    public render() {
        return (
            <div>
                <div className="row">
                    <div className="col-lg-6">
                        <FormCheckBoxControl
                            label="Request Hard Copy Response"
                            value={this.props.requestingHardCopyResponse}
                            changeHandler={this.hardCopyResponseToggle}
                        />
                    </div>
                </div>
                <Collapse isOpen={this.state.isAddressDetailOpen}>
                    <div className="row">
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                placeHolder=""
                                label="Address Street 1"
                                changeHandler={this.props.handleInputChange("addressStreet1")}
                                value={this.props.addressStreet1}
                                isValid={!this.props.isTriedToSubmit || this.props.isAddressStreet1Valid}
                            />
                        </div>
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                placeHolder=""
                                label="Address Street 2"
                                changeHandler={this.props.handleInputChange("addressStreet2")}
                                value={this.props.addressStreet2}
                                isValid={!this.props.isTriedToSubmit || this.props.isAddressStreet2Valid}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                placeHolder=""
                                label="Address City"
                                changeHandler={this.props.handleInputChange("addressCity")}
                                value={this.props.addressCity}
                                isValid={!this.props.isTriedToSubmit || this.props.isAddressCityValid}
                            />
                        </div>
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                placeHolder=""
                                label="Address State"
                                changeHandler={this.props.handleInputChange("addressState")}
                                value={this.props.addressState}
                                isValid={!this.props.isTriedToSubmit || this.props.isAddressStateValid}
                            />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                placeHolder=""
                                label="Address Zip"
                                changeHandler={this.props.handleInputChange("addressZip")}
                                value={this.props.addressZip}
                                isValid={!this.props.isTriedToSubmit || this.props.isAddressZipValid}
                            />
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    }

    private hardCopyResponseToggle = (checked: boolean) => {
        this.setState({ isAddressDetailOpen: checked });
        this.props.handleInputChange("requestingHardCopyResponse")(checked);
    }

    private toggleDetail = () => {
        this.setState((state) => {
            return { isAddressDetailOpen: !state.isAddressDetailOpen }
        });
    }
}