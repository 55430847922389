import React from "react";
import { IUserDto } from "../../apis/interfaces/IUserDto";
import { ApiCallStatusEnum } from "./enums/ApiCallStatusEnum";
import * as CurrentUserApi from "../../apis/CurrentUserApi";
import { CircleSpinner } from "./CircleSpinner";
import { Route, Redirect } from "react-router-dom";
import { ROUTE_PATH } from '../../constants';
import { getLoginError } from "../../AppSettings";
import * as constants from "../../constants";

export interface IPrivateRouteProps {
    component: any;
    exact?: boolean;
    path: string;
    location?: any;
}

interface IPrivateRouteState {
    userProfile: IUserDto | null;
    apiCallStatus: ApiCallStatusEnum;
    loginError?: any;
}

export class PrivateRoute extends React.Component<IPrivateRouteProps, IPrivateRouteState> {

    constructor(props: any) {
        super(props);

        this.state = {
            userProfile: null,
            apiCallStatus: ApiCallStatusEnum.NotStarted,
            loginError: null
        }
    }

    public async componentDidMount() {
        const loginError = getLoginError();
        if (loginError) {
            this.setState({ ...this.state, loginError });
            return;
        }

        this.setState({ apiCallStatus: ApiCallStatusEnum.Started });
        const userProfile = await CurrentUserApi.GetAuthUserOrNull();
        this.updateUserIdInLocalStorage(userProfile?.id);
        const apiCallStatus = userProfile !== null ? ApiCallStatusEnum.Success : ApiCallStatusEnum.Failed;
        this.setState({ userProfile, apiCallStatus});

        // add listener after updating local storage. we dont want our own event!
        window.addEventListener('storage', this.onStorageUpdate);
    }

    public componentWillUnmount() {
        window.removeEventListener('storage', this.onStorageUpdate);
    }

    public render() {
        if (this.state.loginError) {
            return (
                <Route render={() => {
                    const url = `/${constants.ROUTE_PATH.login}?loginError=${this.state.loginError}`;
                    return (<Redirect to={url} />);
                }} />
            )
        }

        if (this.state.apiCallStatus === ApiCallStatusEnum.NotStarted || this.state.apiCallStatus === ApiCallStatusEnum.Started) {
            return (
                <CircleSpinner />
            )
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Success) {
            const { component: Component, ...rest } = this.props;
            return (
                <Route
                    {...rest}
                    render={(routeProps) => {
                        return (<this.props.component  {...routeProps} />);
                    }} />
            )
        } else {
            return (
                <Route render={() => {
                    return (<Redirect to={ROUTE_PATH.login} />);
                }} />
            )
        }
    }

    private updateUserIdInLocalStorage = (newUserId: number | undefined) => {
        window.localStorage[constants.APP_CURRENT_USERID_KEY] = newUserId;
    }

    // This logic is used to auto logout if the user has multiple tabs open
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#responding_to_storage_changes_with_the_storageevent
    private onStorageUpdate = (e: any) => {
        const currentUserId = this.state.userProfile?.id || 0;
        if (e.key === constants.APP_CURRENT_USERID_KEY && e.newValue !== currentUserId) {
            this.setState({ userProfile: null, apiCallStatus: ApiCallStatusEnum.Failed });
        }
    }
}