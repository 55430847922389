import React from "react";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import { DelayRouteCard } from "./DelayRouteCard";
import { IDelayRouteListDto } from "../../apis/interfaces/IDelayRouteListDto";

export interface IRouteResultProps {
    RequestObj: IDelayRequestDto;
}

interface IRouteResultState {
    sortedRouteList: IDelayRouteListDto[];
}

export class RouteResults extends React.Component<IRouteResultProps, IRouteResultState> {

    constructor(props: any) {
        super(props);
        this.state = {
            sortedRouteList: [],
        }
    }

    public render() {
        return (
            <div>

                {this.renderDelayRouteCards()}

            </div>
        )
    }

    private renderDelayRouteCards = () => {
        const groupByLegNumber = (array: any, key: any) => {
            // Returns the final array
            return array.reduce((result: any, currentValue: any) => {
                // If an array exists based on legNumber then push to the array. Else create an array and push the object
                (result[currentValue[key]] = result[currentValue[key]] || []).push(
                    currentValue
                );
                return result;
            }, {});
        };
        var groupSortedList = groupByLegNumber(this.props.RequestObj.passedRouteList, 'legNumber');

        Object.keys(groupSortedList).forEach(key => {
            var rec = {
                id: key,
                listOfResultPerLeg: groupSortedList[key]
            }
            this.state.sortedRouteList.push(rec);
        })

        var routeCardList = this.state.sortedRouteList.map((rec, index) => {
            return (
                <div key={index}>
                    <DelayRouteCard arrayDelayRouteObj={rec} />
                </div>
            );
        })

        return (
            <div >
                {routeCardList}
            </div>
        )

    }

}