import axios from "axios";
import { IBusTrainStop } from "./interfaces/IBusTrainStop";

export async function GetBusStops(routeId: string, patternId: string, serviceDate: string){
    const encodedRouteId = encodeURIComponent(routeId);
    const encodedPatternId = encodeURIComponent(patternId);
    const url = `/bus-stops/list?routeId=${encodedRouteId}&date=${serviceDate}&patternId=${encodedPatternId}`;
    const response = await axios.get<IBusTrainStop[]>(url);
    return response.data;
}

export async function GetNearbyBusStops(routeId: string, patternId: string, serviceDate: string, lon: number, lat: number){
    const encodedRouteId = encodeURIComponent(routeId);
    const encodedPatternId = encodeURIComponent(patternId);
    const url = `/bus-stops/list-nearby?routeId=${encodedRouteId}&date=${serviceDate}&patternId=${encodedPatternId}&lon=${lon}&lat=${lat}`;
    const response = await axios.get<IBusTrainStop[]>(url);
    return response.data;
}