import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FormCheckBoxControl } from "../common/FormCheckBoxControl";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import { FormInputControl } from "../common/FormInputControl";
import { IDelayRequestUpdateModel } from "../../apis/interfaces/IDelayRequestUpdateModel";
import { Collapse } from "reactstrap";
import { IUserDto } from "../../apis/interfaces/IUserDto";
import * as currentUserapi from "../../apis/CurrentUserApi";

export interface IEditAddressModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    requestObj: IDelayRequestDto;
    updateRequestInfo: (requestUpdateModel: IDelayRequestUpdateModel) => void;
}

interface IEditAddressModalState {
    emailAddress: string;
    addressStreet1: string;
    addressStreet2: string;
    addressCity: string;
    addressState: string;
    addressZip: string;
    requestingHardCopyResponse: boolean;
    isAddressStreet1Valid: boolean;
    isAddressCityValid: boolean;
    isAddressStateValid: boolean;
    isAddressZipValid: boolean;
    isTriedToSubmit: boolean;
    isFormValid: boolean;
    isEmailAddressValid: boolean;
    isAddressDetailOpen: boolean;
    userProfile: IUserDto | null;
}

export class EditAddressModal extends React.Component<IEditAddressModalProps, IEditAddressModalState> {
    constructor(props: any) {
        super(props);
        this.state = {
            emailAddress: "",
            addressStreet1: "",
            addressStreet2: "",
            addressCity: "",
            addressState: "",
            addressZip: "",
            requestingHardCopyResponse: false,
            isAddressStreet1Valid: false,
            isAddressCityValid: false,
            isAddressStateValid: false,
            isAddressZipValid: false,
            isTriedToSubmit: false,
            isFormValid: false,
            isEmailAddressValid: false,
            isAddressDetailOpen: false,
            userProfile: null
        }
    }

    public async componentDidMount() {
        const { requestObj } = this.props;

        this.setState({
            emailAddress: requestObj.emailAddress,
            addressStreet1: requestObj.addressStreet1,
            addressStreet2: requestObj.addressStreet2,
            addressCity: requestObj.addressCity,
            addressState: requestObj.addressState,
            addressZip: requestObj.addressZip,
            requestingHardCopyResponse: requestObj.requestingHardCopyResponse,
        })
        try {
            this.setState({ userProfile: null })
            const userDto = await currentUserapi.GetCachedAuthUser();
            this.setState({ userProfile: userDto });
        } catch (err) {
            this.setState({ userProfile: null });
        }
    }
    public render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={this.props.toggleModal} scrollable={true}
                backdrop="static" size="lg">
                <ModalHeader toggle={this.props.toggleModal}>Edit/ Update Address</ModalHeader>
                <ModalBody>
                    <div className="row">
                            <div className="col-lg-6">
                                <FormInputControl
                                    type="email"
                                    placeHolder=""
                                    label="Email"
                                    changeHandler={this.handleInputChange("emailAddress")}
                                    value={this.state.emailAddress}
                                    isValid={!this.state.isTriedToSubmit || this.state.isEmailAddressValid}
                                />
                            </div>
                        </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <FormCheckBoxControl
                                label="Request Hard Copy Response"
                                value={this.state.requestingHardCopyResponse}
                                changeHandler={this.hardCopyResponseToggle}
                            />
                        </div>
                    </div>
                    <Collapse isOpen={this.state.isAddressDetailOpen}>
                    
                    <div className="row">
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                placeHolder=""
                                label="Address Street 1"
                                changeHandler={this.handleInputChange("addressStreet1")}
                                value={this.state.addressStreet1}
                                isValid={!this.state.isTriedToSubmit || this.state.isAddressStreet1Valid}
                            />
                        </div>
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                placeHolder=""
                                label="Address Street 2"
                                changeHandler={this.handleInputChange("addressStreet2")}
                                value={this.state.addressStreet2}
                                isValid={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                placeHolder=""
                                label="Address City"
                                changeHandler={this.handleInputChange("addressCity")}
                                value={this.state.addressCity}
                                isValid={!this.state.isTriedToSubmit || this.state.isAddressCityValid}
                            />
                        </div>
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                placeHolder=""
                                label="Address State"
                                changeHandler={this.handleInputChange("addressState")}
                                value={this.state.addressState}
                                isValid={!this.state.isTriedToSubmit || this.state.isAddressStateValid}
                            />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <FormInputControl
                                type="text"
                                placeHolder=""
                                label="Address Zip"
                                changeHandler={this.handleInputChange("addressZip")}
                                value={this.state.addressZip || ""}
                                isValid={!this.state.isTriedToSubmit || this.state.isAddressZipValid}
                            />
                        </div>
                    </div>
                    </Collapse>
                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <button
                                type="button"
                                className="btn btn-info float-right"
                                onClick={this.submitUpdate}
                            >Update</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    private handleInputChange = (fieldName: string) => (value: any) => {
        this.setState({
            [fieldName]: value
        } as any, this.validateFields);

    }

    private hardCopyResponseToggle = (checked: boolean) => {
        this.setState({ isAddressDetailOpen: checked });
        this.handleInputChange("requestingHardCopyResponse")(checked);
    }

    private validateFields = () => {
        let isAddressStreet1Valid = true;
        let isAddressCityValid = true;
        let isAddressStateValid = true;
        let isAddressZipValid = true;
        if (this.state.requestingHardCopyResponse === true) {
            isAddressStreet1Valid = this.state.addressStreet1 ? true : false;
            isAddressCityValid = this.state.addressCity ? true : false;
            isAddressStateValid = this.state.addressState ? true : false;
            isAddressZipValid = this.state.addressZip ? true : false;
        }

        let isFormValid = isAddressStreet1Valid && isAddressCityValid
            && isAddressStateValid && isAddressZipValid;


        this.setState({
            isAddressStreet1Valid,
            isAddressCityValid,
            isAddressStateValid,
            isAddressZipValid,
            isFormValid
        })
    }


    private submitUpdate = () => {
        this.validateFields();
        if(!this.state.isFormValid){
            this.setState({isTriedToSubmit: true});
        } else {
            const delayRequestUpdateModel: IDelayRequestUpdateModel = {
                requestId : this.props.requestObj.id,
                dispositionId: this.props.requestObj.dispositionId,
                statusID: this.props.requestObj.statusId,
                emailAddress: this.state.emailAddress,
                transitComments: this.props.requestObj.transitComments,
                addressStreet1 : this.state.addressStreet1,
                addressStreet2: this.state.addressStreet2,
                addressCity: this.state.addressCity,
                addressState: this.state.addressState,
                addressZip: this.state.addressZip,
                requestingHardCopyResponse: this.state.requestingHardCopyResponse,
                lastTransitUpdateDateKey: this.props.requestObj.lastTransitUpdateDateKey,
                isHardCopyDownloaded: false,
                dispositionSubmitBy: this.props.requestObj.dispositionSubmitBy,
                isAddressEdited: true,
                initialDispositionId: this.props.requestObj.initialDispositionId,
            };
            this.props.updateRequestInfo(delayRequestUpdateModel);
        }
    }
}