import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import * as DelayRequestApi from "../../apis/DelayRequestApi";
import { CircleSpinner } from "../common/CircleSpinner";
import { ErrorAlert } from "../common/ErrorAlert";
import { RequestCard } from "./RequestCard";
import { RequestEditForm } from "./RequestEditForm";
import * as ApiErrorHandler from "../../apis/ApiErrorHandler";
import { IDelayRequestUpdateModel } from "../../apis/interfaces/IDelayRequestUpdateModel";
import { IDelayCommentsUpdateModel } from "../../apis/interfaces/IDelayCommentsUpdateModel";
import { ApiCallStatusEnum } from "../common/enums/ApiCallStatusEnum";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ROUTE_PATH } from "../../constants";
import { RouteResults } from "./RouteResults";

export interface IRequestEditComponentState {
    requestGetStatus: ApiCallStatusEnum;
    requestSaveStatus: ApiCallStatusEnum;
    errorMessage: string;
    redirectTo: string;
    delayRequest: IDelayRequestDto | undefined;
}


export class RequestEditComponent extends React.Component<RouteComponentProps<{ id: string }>, IRequestEditComponentState> {
    constructor(props: any) {
        super(props);

        this.state = {
            requestGetStatus: ApiCallStatusEnum.Started,
            requestSaveStatus: ApiCallStatusEnum.NotStarted,
            errorMessage: "",
            redirectTo: "",
            delayRequest: undefined
        };
    }

    public async componentDidMount() {
        try {
            this.setState({ requestGetStatus: ApiCallStatusEnum.Started, errorMessage: "", delayRequest: undefined });
            const requestId = this.props.match.params.id;
            const requestObj = await DelayRequestApi.GetDelayRequestById(requestId);
            this.setState({ requestGetStatus: ApiCallStatusEnum.Success, errorMessage: "", delayRequest: requestObj });
        } catch (err) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ requestGetStatus: ApiCallStatusEnum.Failed, errorMessage, delayRequest: undefined });
        }
    }

    public render() {
        return (
            <div>
                <NavbarComponent activeCheck="" />
                <div className="container">
                    {this.renderRequestDetail()}
                    <ToastContainer />
                </div>
            </div>
        )
    }

    private renderRequestDetail = () => {
        if (this.state.requestGetStatus === ApiCallStatusEnum.Started) {
            return (
                <CircleSpinner />
            )
        } else if (this.state.requestGetStatus === ApiCallStatusEnum.Success && this.state.delayRequest) {
            if (this.state.requestSaveStatus === ApiCallStatusEnum.Started) {
                return (
                    <CircleSpinner />
                )
            } else {
                return (
                    <div>
                        {this.state.errorMessage && <ErrorAlert alertText={this.state.errorMessage} />}
                        <RequestCard RequestObj={this.state.delayRequest}
                            updateRequestInfo={this.updateRequestInfo}
                        />
                        <RouteResults RequestObj={this.state.delayRequest} />
                        <RequestEditForm requestObj={this.state.delayRequest}
                            updateRequestInfo={this.updateRequestInfo}
                            updateRequestComments={this.updateRequestComments}
                        />
                    </div>
                )
            }

        } else {
            var errMessage = this.state.errorMessage || "";
            return (
                <ErrorAlert alertText={errMessage} />
            )
        }
    }

    private updateRequestInfo = async (requestUpdateModel: IDelayRequestUpdateModel) => {
        if ((this.state.delayRequest?.statusId === 2 || this.state.delayRequest?.statusId === 4)
            && this.state.delayRequest?.requestingHardCopyResponse === true) {
            this.setState({ redirectTo: ROUTE_PATH.hardRequest })
        } else if (this.state.delayRequest?.statusId === 3) {
            this.setState({ redirectTo: ROUTE_PATH.openRequest })
        } else if (this.state.delayRequest?.statusId === 2 && this.state.delayRequest.dispositionSubmitBy === 1) {
            this.setState({ redirectTo: ROUTE_PATH.autoverifiedRequest })
        } else {
            this.setState({ redirectTo: ROUTE_PATH.searchRequest })
        }
        try {
            this.setState({ requestSaveStatus: ApiCallStatusEnum.Started, errorMessage: "" })
            var updatedRequest = await DelayRequestApi.UpdateDelayRequest(requestUpdateModel);
            this.setState({ requestSaveStatus: ApiCallStatusEnum.Success, delayRequest: updatedRequest, errorMessage: "" });
            //this.props.history.push(this.state.redirectTo);
            this.showUpdateReference();

        } catch (err) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ requestSaveStatus: ApiCallStatusEnum.Failed, errorMessage });
        }
    }

    private showUpdateReference = () => {
        toast("Record Updated Successfully!!", { transition: Slide, type: toast.TYPE.INFO, });
    }

    private updateRequestComments = async (commentsUpdateModel: IDelayCommentsUpdateModel) => {
        try {
            this.setState({ requestSaveStatus: ApiCallStatusEnum.Started, errorMessage: "" })
            var updatedRequest = await DelayRequestApi.UpdateDelayComments(commentsUpdateModel);
            this.setState({ requestSaveStatus: ApiCallStatusEnum.Success, delayRequest: updatedRequest, errorMessage: "" });
            this.showUpdateReference();

        } catch (err) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ requestSaveStatus: ApiCallStatusEnum.Failed, errorMessage });
        }
    }
}