import React from "react";

export interface IFormInputControlProps {
    type: string;
    placeHolder: string;
    label: string;
    changeHandler: (value: any) => void;
    value: string;
    isValid: boolean;
    validationMessage?: string;
    autoCompleteHint?: string;
}
export class FormInputControl extends React.Component<IFormInputControlProps, {}> {

    public render() {
        return (
            <div className="form-group ">
                <label >{this.props.label}</label>
                <input type={this.props.type}
                    className={this.getClassName()}
                    placeholder={this.props.placeHolder}
                    value={this.props.value}
                    onChange={(event) => { this.props.changeHandler(event.target.value) }}
                    autoComplete={this.props.autoCompleteHint || "off"}

                />
                {this.props.validationMessage && !this.props.isValid &&
                    <div className="invalid-feedback"> {this.props.validationMessage} </div>}
                
            </div>
        )
    }

    private getClassName = () => {
        if (this.props.isValid) {
            return "form-control";
        } else {
            return "form-control is-invalid"
        }
    }


}