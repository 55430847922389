import React from "react";
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IStopCardProps {
    busStopObj: IBusTrainStop;
    updateSelectedStop: (selStop: IBusTrainStop) => void;
    borderColor: string;
    isDisabled: boolean;
}

export class StopCard extends React.Component<IStopCardProps, {}> {
    public render() {
        if(!this.props.isDisabled){
            return (
                <div className="busstop_route_div_button mt-2"
                    onClick={(event) => { this.props.updateSelectedStop(this.props.busStopObj) }}
                    style={{borderColor:this.props.borderColor}}
                >
                    <div className="row no-gutters">
                        {this.getStopName()}
                        {this.getTrainRouteIcon()}
                        <div className="col-sm-1">
                            <span className="float-right"><FontAwesomeIcon size="1x" icon={faAngleRight} /></span>
                        </div>
                    </div>
                </div>
    
            );
        } else {
            return (
                <div className="busstop_route_div_button_disabled mt-2"  style={{borderColor: "gray"}} >
                    <div className="row no-gutters">
                        <div className="col-sm-12" >
                            {this.props.busStopObj.stopName}
                        </div>
                    </div>
                </div>
    
            );
        }
    }

    private getStopName = () => {
        if (this.props.busStopObj.otherRoutes) {
            return (
                <div className="col-sm-7" >
                    {this.props.busStopObj.stopName}
                </div>
            )
        } else {
            return (
                <div className="col-sm-11" >
                    {this.props.busStopObj.stopName}
                </div>
            )
        }
    }

    private getTrainRouteIcon = () => {
        if (this.props.busStopObj.otherRoutes) {
            if (this.props.busStopObj.otherRoutes.split(',').length > 1) {
                let routes = this.props.busStopObj.otherRoutes.split(',');
                const routeCard = routes.map((rec, index) => {
                    if (index < this.props.busStopObj.otherRoutes.split(',').length -1){
                        const imgSrc = `lineIcons/${rec}.svg`;
                        return(
                            <img key={rec} className="ml-1" src={imgSrc} height="25" width="25" />
                        )
                    }
                })
                return (
                    <div className="col-sm-4" >
                        {routeCard}
                    </div>
                );
            }
        }
    }
}