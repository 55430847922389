import * as constants from "../../constants";
import * as userApi from "../../apis/UserApi";
import { IUserUpdateModel } from "../../apis/interfaces/IUserUpdateModel";

export async function getUserObj(userid: string) {
    if (userid === constants.NEW_USER_ID + "") {
        const userObj: IUserUpdateModel = {
            id: constants.NEW_USER_ID,
            bscId: "",
            firstName: "",
            lastName: "",
            emailAddress: "",
            isActive: true,
            roleIdList: [],
        }
        return userObj;
    } else {
        const userDto = await userApi.GetUser(userid);
        const userObj: IUserUpdateModel = {
            id: userDto.id,
            bscId: userDto.bscId,
            firstName: userDto.firstName,
            lastName: userDto.lastName,
            emailAddress: userDto.emailAddress,
            isActive: userDto.isActive,
            roleIdList: userDto.roleList.map((role)=>{
                return role.id
            }),
        }
        return userObj;
    }
}