import React from "react";
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";
import { StopCardList } from "./StopCardList";
import { TransitTypeEnum } from "../../../../apis/enums/TransitTypeEnum";


export interface IStopSelectorProps {
    stopList: IBusTrainStop[];
    alreadySelectedStop?: IBusTrainStop;
    stopCardBorderColor: string;
    updateSelectedStop: (selStop: IBusTrainStop)=> void;
    transitType: TransitTypeEnum;
}

interface IStopSelectorState {
    filterText: string;
    filteredStopList: IBusTrainStop[];
    
}

export class StopSelector extends React.Component<IStopSelectorProps, IStopSelectorState> {

    constructor(props: any) {
        super(props);
        this.state = {
            filterText: "",
            filteredStopList: []
        }
    }

    public render() {
        return (
            <div className = "filterSearch"> 
                <input className="form-control"
                    type="text" placeholder="Filter Stop List"
                    value={this.state.filterText} onChange={this.handleFilterChange} />
                <StopCardList stopList={this.getStopList()} 
                    updateSelectedStop={this.updateSelectedStop}
                    stopCardBorderColor = {this.props.stopCardBorderColor}
                    alreadySelectedStop={this.props.alreadySelectedStop}
                    transitType = {this.props.transitType}
                />
            </div>
        )
    }

    private getStopList() {
        if(this.state.filterText){
            const filteredStopList = this.props.stopList.filter((x) => {
                const searchableText = x.stopName
                return (searchableText.toUpperCase().indexOf(this.state.filterText.toUpperCase()) >= 0);
            })
            return filteredStopList;
        }
        return this.props.stopList; //.sort((a, b) => (a.stopSequence > b.stopSequence) ? 1 : -1)
    }

    private handleFilterChange = (event: any) => {
        this.setState({ filterText: event.target.value });
    }

    private updateSelectedStop = (selStop: IBusTrainStop) => {
        this.setState({
            filterText: "",
            filteredStopList: []
        });

        this.props.updateSelectedStop(selStop);
    }
}