import queryString from "query-string";

export interface ClientConfigDto {
    BannerMessage: string;
    EnableMockLogin: boolean;
    SSO: SSOConfig;
}

export interface SSOConfig {
    MockLogin: string;
    MockLogout: string;
    Login: string;
    Logout: string;
}

const APP_CONFIG_KEY: string = '__MTA_APP_CONFIG__';

export function setAppConfig(config: ClientConfigDto) {
    (window as any)[APP_CONFIG_KEY] = config;
    console.debug('process.env ', process.env);
    console.debug(config);
}

export function getAppConfig(): ClientConfigDto {
    return (window as any)[APP_CONFIG_KEY];
}

export function getLoginUrl() {
    const baseUrl = process.env.REACT_APP_ApiBaseUrl;
    const config = getAppConfig();
    const queryParams = queryString.stringify({
        redirect_uri: process.env.REACT_APP_RedirectUrl
    });

    if (config.EnableMockLogin) {
        return `${baseUrl}/${config.SSO.MockLogin}?${queryParams}`;
    }
    return `${baseUrl}/${config.SSO.Login}?${queryParams}`;
}

export function getLogoutUrl() {
    const baseUrl = process.env.REACT_APP_ApiBaseUrl;
    const config = getAppConfig();

    if (config.EnableMockLogin) {
        return `${baseUrl}/${config.SSO.MockLogout}`;
    }
    return `${baseUrl}/${config.SSO.Logout}`;
}

export function getWellknownUrl() {
    return `/well-known/client-config`;
}

export function getLoginError() {
    const url = window.location.href;
    if (!url) {
        return;
    }

    const index = url.indexOf('?');
    if (index === -1) {
        return;
    }

    const query = url.substr(index);
    const searchParams = new URLSearchParams(query);
    let loginError = searchParams.get('loginError');
    if (!loginError) {
        return;
    }

    // remove chars #/ at the end, if any
    // for ex, https://fareevasionqa.nymta.info/?loginError=The+user+does+not+exist+in+the+application+database.#/
    if (loginError.endsWith('#/')) {
        loginError = loginError.substr(0, loginError.length - 2);
    }

    return loginError;
}
