import axios from "axios";
import * as constants from "./constants";

class AxiosConfig {
    private axiosConfigured = false;

    configure() {
        if (this.axiosConfigured) {
            return;
        }
        this.axiosConfigured = true;

        axios.defaults.baseURL = `${process.env.REACT_APP_ApiBaseUrl}/api`;
        axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
        axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN'; // default value; keeping it here anyway
        axios.defaults.withCredentials = true;

        // response interceptor
        axios.interceptors.response.use(undefined, error => this.handleAxiosResponseError(error));
    }

    private handleAxiosResponseError(error: any) {
        if (!error) {
            return Promise.reject('A error occurred when calling the api. No further error information is available.');
        }

        const skipIntercept = error.config?.headers[constants.SKIP_AXIOS_INTERCEPT];
        if (skipIntercept) {
            return Promise.reject(error);
        }

        const { response } = error;
        if (!response) {
            return Promise.reject(error);
        }

        if (response.status === 400 || response.status === 403) {
            return Promise.reject(error);
        }

        if (response.status === 401) {
            const loginError = response.data || '';
            window.location.href = `#${constants.ROUTE_PATH.login}?loginError=${loginError}`;
        }

        return Promise.reject(error);
    }
}

const axiosConfig = new AxiosConfig();
export { axiosConfig }
