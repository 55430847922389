import axios from "axios";
import { IUserDto } from "./interfaces/IUserDto";
import { getAppConfig, getLogoutUrl } from "../AppSettings";
import * as constants from "../constants";

let cachedUser: IUserDto | null = null;

export async function GetAuthUser() {
    const url = `/auth-user`;
    const response = await axios.get<IUserDto>(url);
    cachedUser = response.data;
    return response.data;
}

export async function GetCachedAuthUser() {
    if (cachedUser) {
        return cachedUser;
    } else {
        const url = `/auth-user`;
        const response = await axios.get<IUserDto>(url);
        cachedUser = response.data;
        return response.data;
    }
}

export async function GetAuthUserOrNull() {
    try {
        const headers: any = {};
        headers[constants.SKIP_AXIOS_INTERCEPT] = 1;
        const response = await axios.get<IUserDto>("/auth-user", { headers });
        return response.data;
    }
    catch {
        return null;
    }
}

export async function logout() {
    const url = getLogoutUrl();
    if (url.includes(getAppConfig().SSO.MockLogout)) {
        return await axios.get(url);
    }
    window.location.href = url;
}