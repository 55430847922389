import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AddEditUserComponent } from "./AddEditUserComponent";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { ErrorAlert } from "../common/ErrorAlert";

export class EditUserComponent extends React.Component<RouteComponentProps<{ userid: string }>, {}> {
    public render() {
        if (this.props.match.params.userid) {
            return (
                <AddEditUserComponent userid={this.props.match.params.userid} pathname={this.props.location.pathname}
                    isAddNew={false} />
            )
        } else {
          return(  <div>
                <NavbarComponent  activeCheck= "idUsers"/>
                <div className="container mt-2">
                    <ErrorAlert alertText="System error. Invalid state. Please refresh the page and try again" />
                </div>
            </div>
          );
        }
    }
}