import React from "react";
import { IViewUserFinalDispositionFormProps } from "./interfaces/IViewUserFinalDispositionFormProps";
import { IViewUserFinalDispositionFormState } from "./interfaces/IViewUserFinalDispositionFormState";
import { ErrorAlert } from "../common/ErrorAlert";
import { FormInputControl } from "../common/FormInputControl";
import { Link } from "react-router-dom";

export class ViewUserFinalDispositionForm extends React.Component<IViewUserFinalDispositionFormProps, IViewUserFinalDispositionFormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            verifiedRequests: [],
        };
    }

    public componentWillMount() {
        const { VerifiedRequests } = this.props;
        this.setState({
            verifiedRequests: VerifiedRequests,
        })
    }

    public render() {
        return (
            <div>
                {this.getVerifiedRequestsLinks()}
            </div>
        )


    }

    private getVerifiedRequestsLinks = () => {
        if (this.state.verifiedRequests.length > 0) {
            var referenceRequestTags = this.state.verifiedRequests
                .map((rec, index) => {
                    const linkUrl = `/referenceNumSearch/${rec.referenceNum}`;

                    return (
                        <div className="col-lg-4 mb-2" key={index}>
                            <div className="row">
                                <div className="col-lg-6"><Link to={linkUrl}>{rec.referenceNum}</Link></div>
                                <div className="col-lg-6">{rec.dispositionName}</div>
                            </div>
                        </div>
                    );
                });

            return (
                <div className="row mt-4">
                    {referenceRequestTags}
                </div>
            )
        }
    }

}