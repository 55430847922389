import React from "react";

export interface IFormTextAreaControlProps {
    label: string;
    rowcount: number;
    changeHandler: (value: any) => void;
    value: string;
    isValid: boolean;
    validationMessage?: string;
}
export class FormTextAreaControl extends React.Component<IFormTextAreaControlProps, {}> {

    public render() {
        return (
            <div className="form-group ">
                <label style={this.props.label === "Comments" ? {marginLeft: "15px"}: {marginLeft: "0px"}}>{this.props.label}</label>
                <textarea className={this.getClassName()}
                    rows={this.props.rowcount}
                    value={this.props.value}
                    onChange={(event) => { this.props.changeHandler(event.target.value) }}
                ></textarea>
                {this.props.validationMessage && !this.props.isValid &&
                    <div className="invalid-feedback"> {this.props.validationMessage} </div>}
            </div>
        )
    }

    private getClassName = () => {
        if (this.props.isValid && this.props.value.length < 1001) {
            return "form-control";
        } else {
            return "form-control is-invalid"
        }
    }

}