import React from "react";
import { ApiCallStatusEnum } from "../common/enums/ApiCallStatusEnum";
import { IRequstSummaryByStatusDto } from "../../apis/interfaces/IRequstSummaryByStatusDto";
import { CircleSpinner } from "../common/CircleSpinner";
import * as ApiErrorHandler from "../../apis/ApiErrorHandler";
import * as DelayRequestListApi from "../../apis/DelayRequestListApi";
import { ErrorAlert } from "../common/ErrorAlert";
import moment from 'moment';
import { Link } from "react-router-dom";

export interface IManualVerifySummary {
    apiCallStatus: ApiCallStatusEnum,
    requestSummaryList: IRequstSummaryByStatusDto[],
    errorMessage: string
}

export class ManualVerifySummary extends React.Component<{}, IManualVerifySummary> {

    constructor(props: any) {
        super(props);
        this.state = {
            apiCallStatus: ApiCallStatusEnum.NotStarted,
            requestSummaryList: [],
            errorMessage: "",
        }
    }

    public async componentDidMount() {
        try {
            this.setState({ apiCallStatus: ApiCallStatusEnum.Started, requestSummaryList: [], errorMessage: "" });
            const reqSummary = await DelayRequestListApi.GetManualVerificationSummary();
            this.setState({ apiCallStatus: ApiCallStatusEnum.Success, requestSummaryList: reqSummary, errorMessage: "" });

        } catch (err) {
            const errMeg = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ apiCallStatus: ApiCallStatusEnum.Failed, requestSummaryList: [], errorMessage: errMeg });
        }
    }

    public render() {
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-subtitle mb-2 text-muted">Requests Pending Manual Verification by Service Date</h5>
                    {this.renderSummary()}
                </div>
            </div>
        )
    }

    private renderSummary = () => {
        if (this.state.apiCallStatus === ApiCallStatusEnum.Started) {
            return (
                <CircleSpinner />
            )
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Success) {
            if (this.state.requestSummaryList.length === 0) {
                return (
                    <h4 className="card-subtitle mt-4 text-muted">There are no more requests pending manual verification</h4>
                )
            } else {
                return (
                    <div>
                        {this.getDateLinks()}
                        <hr />
                        <div className="mt-2">
                            <h4>Total <span className="badge badge-secondary">{this.getTotalCount()}</span></h4>

                            <small className="text-muted float-right  ">Click on the links above to view details</small>

                        </div>

                    </div>

                )
            }
        } else {
            return (
                <ErrorAlert alertText={this.state.errorMessage} />
            )
        }

    }

    private getDateLinks = () => {
        var summaryTags = this.state.requestSummaryList
            .sort((a, b) => {
                const mmta = moment(a.serviceDate);
                const mmtb = moment(b.serviceDate);

                if (mmta.isAfter(mmtb)) {
                    return -1;
                } else {
                    return 1;
                }

            })
            .map((rec, index) => {
                const mmt = moment(rec.serviceDate);
                const displayText = mmt.format("MM/DD/YYYY");
                const linkPara = mmt.format("YYYY-MM-DD");
                const linkUrl = "/manualVerifyDetails/" + linkPara;
                return (<div className="col-lg-3 mb-2" key={index}>
                    <Link to={linkUrl}>{displayText} <span className="badge badge-secondary">{rec.requestCount}</span></Link>
                </div>);
            });

        return (
            <div className="row mt-4">
                {summaryTags}
            </div>
        )
    }

    private getTotalCount = () => {
        let totalCount = 0;

        for (const rec of this.state.requestSummaryList) {
            totalCount += rec.requestCount;
        }

        return totalCount;
    }
}