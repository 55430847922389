import React from 'react';
import { ApiCallStatusEnum } from "../common/enums/ApiCallStatusEnum";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { HardCopyRequestSummary } from "./HardCopyRequestSummary";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IHardCopyRequestComponentState {
    apiCallStatus: ApiCallStatusEnum;
    VerificationRequests: IDelayRequestDto[];
    errorMessage: string;
}

interface IHardCopyRequestComponentProps {
}


export class HardCopyRequestComponent extends React.Component<IHardCopyRequestComponentProps, IHardCopyRequestComponentState> {

    public render(){
        return (
            <div>
                <NavbarComponent activeCheck="idHardCopyRequests"/>
                <div className="mt-2">
                        <HardCopyRequestSummary/>
                    </div>
                <ToastContainer />
                </div>
        );
    }
}