import React from "react";
import moment from "moment";
import { IBusTrainRoute } from "../../../../apis/interfaces/IBusTrainRoute";
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";
import { IDelayRouteUi } from "../../interfaces/IDelayRouteUi";
import { TransitTypeEnum } from "../../../../apis/enums/TransitTypeEnum";
import { BusCommuteCard } from "../BusDelaySelect/BusCommuteCard";
import { TrainCommuteCard } from "../TrainDelaySelect/TrainCommuteCard";
import * as TrainRouteIdHandler from "../TrainDelaySelect/RouteIdHandler";
import { TimePickerControl } from "../../Controls/TimePickerControl";
import { ErrorAlert } from "../../../common/ErrorAlert";
interface IEntryFormState {
    entryTime: moment.Moment | undefined;
    exitTime: moment.Moment | undefined;
    isEntryTimeValid: boolean;
    isExitTimeValid: boolean;
    isTriedToSubmit: boolean;
    errorMessage: string;
    radioChecked: boolean;
}

export interface IEntryFormProps {
    selectedRoute: IBusTrainRoute;
    selectedPatternId?: string;
    startingStop: IBusTrainStop;
    endingStop: IBusTrainStop;
    addToDelayRouteList: (newTravelRoute: IDelayRouteUi) => void;
    transitType: TransitTypeEnum;
    lastDelayRouteRec: IDelayRouteUi | null;
}

export class TimeEntryForm extends React.Component<IEntryFormProps, IEntryFormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            entryTime: undefined,
            exitTime: undefined,
            isEntryTimeValid: false,
            isExitTimeValid: false,
            isTriedToSubmit: false,
            errorMessage: "",
            radioChecked: true,
        }
        this.handleRadio = this.handleRadio.bind(this);
    }

    handleRadio(event: any) {
        const radioChecked = event.currentTarget.value === 'true' ? true : false;
        this.setState({
            radioChecked: radioChecked
        })
    }
    public render() {
        const { radioChecked } = this.state;
        let routeType = "train";
        if (this.props.transitType === '1'){
            routeType = "bus";
        }
        return (
            <div>
                {this.getCommuteCard()}
                {this.state.errorMessage && this.state.isTriedToSubmit && <ErrorAlert alertText={this.state.errorMessage} />}
                <form>
                    <div className="row mt-2">
                        <div className="col-sm-12 rdtPickerHide">
                            <TimePickerControl
                                label={this.getBoardingTimeLabel()}
                                value={moment(this.state.entryTime, 'h:mm A')}
                                changeHandler={this.handleInputChange("entryTime")}
                                isValid={!this.state.isTriedToSubmit || this.state.isEntryTimeValid}
                                validationMessage={this.getEntryTimeValidationMsg()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 rdtPickerHide">
                            <TimePickerControl
                                label={`Approximate time you arrived at ${this.props.endingStop.stopName}`}
                                value={moment(this.state.exitTime, 'h:mm A')}
                                changeHandler={this.handleInputChange("exitTime")}
                                isValid={!this.state.isTriedToSubmit || this.state.isExitTimeValid}
                                validationMessage={this.getExitTimeValidationMsg()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <b> Did you board the first {routeType} that arrived?</b>
                            <div>
                                <label className="radioCheck">
                                    <input
                                        type="radio"
                                        value="true"
                                        checked={radioChecked === true}
                                        onChange={this.handleRadio} />
                                        Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="false"
                                        checked={radioChecked === false}
                                        onChange={this.handleRadio} />
                                        No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mb-5">
                        <div className="col-sm-12">
                            <button
                                type="button"
                                className="btn btn-secondary float-right"
                                onClick={() => { this.addToDelayRouteList() }}
                            >Add</button>
                        </div>
                    </div>
                </form>

            </div>
        )
    }
    // Based on business request.
    // Display time arrived at stop for the first leg
    private getBoardingTimeLabel = () => {
        if (this.props.lastDelayRouteRec) {
            return "Approximate time you boarded at " + this.props.startingStop.stopName
        } else {
            return "Approximate time you arrived at " + this.props.startingStop.stopName
        }
    }

    private getCommuteCard = () => {
        if (this.props.transitType === TransitTypeEnum.Bus) {
            return (
                <BusCommuteCard
                    selectedRoute={this.props.selectedRoute}
                    startingStop={this.props.startingStop}
                    endingStop={this.props.endingStop}
                />
            );
        } else if (this.props.transitType === TransitTypeEnum.Subway) {
            return (
                <TrainCommuteCard
                    selectedRoute={this.props.selectedRoute}
                    startingStop={this.props.startingStop}
                    endingStop={this.props.endingStop}
                />
            )
        } else {
            return (
                <></>
            )
        }
    }

    private getEntryTimeValidationMsg = () => {
        if (this.props.lastDelayRouteRec) {
            return "Must be at or after " + this.props.lastDelayRouteRec.exitTime + " (exit time of last leg)";
        }
        return "Enter time in hh:mm AM/PM format";
    }

    private getExitTimeValidationMsg = () => {
        return "Enter time in hh:mm AM/PM format";
    }

    private handleInputChange = (fieldName: string) => (value: any) => {
        this.setState({
            [fieldName]: value
        } as any, this.validateFields);
    }


    private validateFields = () => {
        let isEntryTimeValid = false;
        let isExitTimeValid = false;
        let errorMessage = "Enter valid time in HH:MM AM/PM format";
        if (this.state.entryTime) {
            isEntryTimeValid = true;
        }

        if (this.state.exitTime) {
            isExitTimeValid = true;
        }
        if (isEntryTimeValid && isExitTimeValid) {
            errorMessage = ""
        }

        if (moment(this.state.entryTime, 'h:mm A') && moment(this.state.exitTime, 'h:mm A')) {

            const entryHour = moment(this.state.entryTime, 'h:mm A').hour();
            const entryMinute = moment(this.state.entryTime, 'h:mm A').minute();
            const exitHour = moment(this.state.exitTime, 'h:mm A').hour();
            // Validation based on Business Request
            // If the Entry time on or after 10 PM then Exit time can be up to less than 02:00 AM next day
            // Note that we are not capturing date values in the input.
            if ((entryHour === 22 && exitHour <= 2) || (entryHour === 23 && exitHour <= 3)) {
                if ((moment(this.state.entryTime, 'h:mm A').diff(moment(this.state.exitTime, 'h:mm A'), 'hours')) < 20) {
                    isExitTimeValid = false;
                    isEntryTimeValid = true;
                    errorMessage = "Exit time should be not more than 4 hours from Entry Time";
                } else {
                    isExitTimeValid = true;
                    isEntryTimeValid = true;
                }
            } else if (moment(this.state.exitTime, 'h:mm A').diff(moment(this.state.entryTime, 'h:mm A'), 'minutes') <= 0) {
                isExitTimeValid = false;
                isEntryTimeValid = true;
                errorMessage = "Exit time should be later than Entry Time";
            } else if (moment(this.state.exitTime, 'h:mm A').diff(moment(this.state.entryTime, 'h:mm A'), 'minutes') > 240) {
                isExitTimeValid = false;
                isEntryTimeValid = true;
                errorMessage = "Exit time should be not more than 4 hours from Entry Time";
            }

            if (this.props.lastDelayRouteRec) {
                if (entryHour === this.props.lastDelayRouteRec.exitHour) {
                    if (entryMinute < this.props.lastDelayRouteRec.exitMinute) {
                        errorMessage = "This time must be later than the exit time of the last leg";
                        isEntryTimeValid = false;
                    }
                } else if (entryHour < this.props.lastDelayRouteRec.exitHour) {
                    if (this.props.lastDelayRouteRec.exitHour === 22 && entryHour < 2) {
                        isEntryTimeValid = true;
                    }  else if (this.props.lastDelayRouteRec.exitHour === 23 && entryHour <= 3) {
                        isEntryTimeValid = true;
                    } else {
                        errorMessage = "This time must be later than the exit time of the last leg";
                        isEntryTimeValid = false;
                    }
                }
            }
        }

        this.setState({
            isEntryTimeValid,
            isExitTimeValid,
            errorMessage
        })
    }

    private addToDelayRouteList = () => {
        this.validateFields();
        if (!this.state.isExitTimeValid || !this.state.isEntryTimeValid) {
            this.setState({ isTriedToSubmit: true });
        } else if (moment(this.state.entryTime, 'h:mm A') && moment(this.state.exitTime, 'h:mm A')) {
            const entryTimeStr = moment(this.state.entryTime, 'h:mm A').format('h:mm A');
            const exitTimeStr = moment(this.state.exitTime, 'h:mm A').format('h:mm A');

            const delayRouteUi: IDelayRouteUi = {
                routeId: this.props.selectedRoute.routeId,
                routeName: this.getRouteName(),
                routeType: this.props.transitType,
                direction: null,
                entryPoint: this.props.startingStop.stopId,
                entryPointName: this.props.startingStop.stopName,
                exitPoint: this.props.endingStop.stopId,
                exitPointName: this.props.endingStop.stopName,
                entryTime: entryTimeStr,
                exitTime: exitTimeStr,
                entryPointLat: this.props.startingStop.latitude,
                entryPointLon: this.props.startingStop.longitude,
                exitPointLat: this.props.endingStop.latitude,
                exitPointLon: this.props.endingStop.longitude,
                exitHour: moment(this.state.exitTime, 'h:mm A').hour(),
                exitMinute: moment(this.state.exitTime, 'h:mm A').minute(),
                legNumber: 0, // will be updated by parent
                // isCrowded: this.state.isNextCrowded,
                gotFirstSched: this.state.radioChecked,
            };

            if (this.props.selectedPatternId) {
                delayRouteUi.direction = this.props.selectedPatternId;
            }

            this.props.addToDelayRouteList(delayRouteUi);
        }
    }


    private getRouteName = () => {
        if (this.props.transitType === TransitTypeEnum.Bus) {
            return this.props.selectedRoute.shortName;
        } else if (this.props.transitType === TransitTypeEnum.Subway) {
            return TrainRouteIdHandler.getRouteId(this.props.selectedRoute);
        } else {
            return "";
        }
    }
}