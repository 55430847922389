import React from "react";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { RequestDateForm } from "./RequestDateForm";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import * as DelayRequestListApi from "../../apis/DelayRequestListApi";
import { CircleSpinner } from "../common/CircleSpinner";
import { ErrorAlert } from "../common/ErrorAlert";
import { RequestListGrid } from "../RequestList/RequestListGrid";
import { RouteComponentProps } from "react-router-dom";
import * as ApiErrorHandler from "../../apis/ApiErrorHandler";

interface IRequestDateSearchComponentState {
    requestGetStarted: boolean;
    requestGetFailed: boolean;
    requestGetSuccess: boolean;
    VerificationRequests: IDelayRequestDto[] ;
    errorMessage: string;
}

interface IRequestDateSearchComponentProps extends RouteComponentProps<{ startDate: string, endDate:string }> {

}

export class RequestDateSearchComponent extends React.Component<IRequestDateSearchComponentProps, IRequestDateSearchComponentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            requestGetStarted: false,
            requestGetFailed: false,
            requestGetSuccess: false,
            VerificationRequests: [],
            errorMessage: "",
        }
    }
    public async componentDidMount(){
        if(this.props.match.params.startDate && this.props.match.params.endDate){
            try {
                let fromDateString = this.props.match.params.startDate;
                let toDateIsoString = this.props.match.params.endDate;
                this.setState({ requestGetStarted: true, requestGetFailed: false, requestGetSuccess: false, VerificationRequests: [], errorMessage: "" });
                var requestList = await DelayRequestListApi.GetRequests(fromDateString, toDateIsoString);
                this.setState({ requestGetStarted: false, requestGetFailed: false, requestGetSuccess: true, VerificationRequests: requestList, errorMessage: "" });
            } catch (err) {
                const errorMessage  = ApiErrorHandler.GetApiErrorMessage(err);
                this.setState({ requestGetStarted: false, requestGetFailed: true, requestGetSuccess: false, VerificationRequests: [], errorMessage });
            }
        }
    }
    public render() {
        return (
            <div>
                <NavbarComponent activeCheck= "idSearchRequests"/>
                <div className="container-fluid">
                    <RequestDateForm onReportGet={this.onReportGet} startDate={this.props.match.params.startDate}  
                            endDate={this.props.match.params.endDate}/>
                    {this.renderResults()}
                </div>
            </div>
        )
    }

    private onReportGet = async (fromDateString: string, toDateIsoString: string) => {
        try {
            this.setState({ requestGetStarted: true, requestGetFailed: false, requestGetSuccess: false, VerificationRequests: [], errorMessage: "" });
            var requestList = await DelayRequestListApi.GetRequests(fromDateString, toDateIsoString);
            this.setState({ requestGetStarted: false, requestGetFailed: false, requestGetSuccess: true, VerificationRequests: requestList, errorMessage: "" });
            localStorage.setItem('searchGrid', `/RequestList/${fromDateString}/${toDateIsoString}`);
        } catch (err) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ requestGetStarted: false, requestGetFailed: true, requestGetSuccess: false, VerificationRequests: [], errorMessage });
        }

        this.props.history.push(`/RequestList/${fromDateString}/${toDateIsoString}`)
    }

    private renderResults = () => {
        if (this.state.requestGetStarted) {
            return <CircleSpinner />
        } else if (this.state.requestGetFailed) {
            return <ErrorAlert alertText={this.state.errorMessage} />
        } else if (this.state.requestGetSuccess && this.state.VerificationRequests) {
           return ( 
               <RequestListGrid RequestDtoList={this.state.VerificationRequests}/>
           );
        }
        return <div></div>

    }
}