import { IRequestFormState } from "../interfaces/IRequestFormState";
import { IDelayRouteCreateModel } from "../../../apis/interfaces/IDelayRouteCreateModel";
import { IDelayRouteUi } from "../interfaces/IDelayRouteUi";
import { IDelayRequestCreateModel } from "../../../apis/interfaces/IDelayRequestCreateModel";

export function MapToDelayRequest(state1: IRequestFormState) {
    const state = state1; // to prevent accidental modification to state
    const routeList: IDelayRouteCreateModel[] = [];
    for (var routeUi of state.routeData) {
        routeList.push(MapToDelayRouteObj(routeUi));
    }
    const requestObj: IDelayRequestCreateModel = {
        firstName: state.firstName,
        lastName: state.lastName,
        emailAddress: state.emailAddress,
        emailAddressConfirm: state.emailAddressConfirm,
        ServiceDate: getDateOfServiceStr(state),
        LenthOfDelay: Number(state.lateMinutes) || 0,
        userComments: state.delayComment,
        addressCity : state.addressCity,
        addressState: state.addressState,
        addressStreet1: state.addressStreet1,
        addressStreet2 : state.addressStreet2,
        addressZip: state.addressZip,
        requestingHardCopyResponse : state.requestingHardCopyResponse,
        passedRouteList: routeList
    };

    return requestObj;

}

function getDateOfServiceStr(state: IRequestFormState) {
    let dateofServiceStr = "";
    if (state.dateOfService) {
        dateofServiceStr = state.dateOfService.format("YYYY-MM-DD");
    }
    return dateofServiceStr;
}


function MapToDelayRouteObj(delayRouteUi: IDelayRouteUi) {
    const delayRoute: IDelayRouteCreateModel = {
        routeId: delayRouteUi.routeId,
        routeType: delayRouteUi.routeType,
        direction: delayRouteUi.direction,
        entryPoint: delayRouteUi.entryPoint,
        exitPoint: delayRouteUi.exitPoint,
        entryTime: delayRouteUi.entryTime,
        exitTime: delayRouteUi.exitTime,
        legNumber: delayRouteUi.legNumber,
        gotFirstSched: delayRouteUi.gotFirstSched,
    };

    return delayRoute;
}