import React from "react";
import axios from "axios";
import { ApiCallStatusEnum } from "../common/enums/ApiCallStatusEnum";
import { RouteComponentProps } from "react-router-dom";
import { CircleSpinner } from "../common/CircleSpinner";
import { getLogoutUrl, getAppConfig } from "../../AppSettings";
import { ROUTE_PATH } from "../../constants";

interface ILogoutComponentState {
    apiCallStatus: ApiCallStatusEnum;
}

export class LogoutComponent extends React.Component<RouteComponentProps, ILogoutComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            apiCallStatus: ApiCallStatusEnum.NotStarted
        }

        const logoutUrl = getLogoutUrl();

        if (getAppConfig().EnableMockLogin) {
            axios.get(logoutUrl).finally(() => {
                this.props.history.push(ROUTE_PATH.login);
            });
        }
        else {
            console.log('redirect to SLO ' + logoutUrl);
            window.location.href = logoutUrl;
        }        
    }

    render() {
        return <CircleSpinner />
    }
}