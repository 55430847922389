import React from "react";
import { Navbar, NavbarToggler, Collapse, Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import { IUserDto } from "../../apis/interfaces/IUserDto";
import * as currentUserapi from "../../apis/CurrentUserApi";
import * as constants from "../../constants";
import { getLogoutUrl } from "../../AppSettings";

interface INavbarState {
    isOpen: boolean;
    userProfile: IUserDto | null;
}

interface INavbarProps {
    activeCheck: string;
}

export class NavbarComponent extends React.Component<INavbarProps, INavbarState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            userProfile: null,
        };
    }

    public async componentDidMount() {
        try {
            this.setState({ userProfile: null })
            const userDto = await currentUserapi.GetCachedAuthUser();
            this.setState({ userProfile: userDto });
        } catch (err) {
            this.setState({ userProfile: null });
        }
    }
    public render() {
        return (
            <Navbar className="navbar-dark bg-dark" expand="md">
                <Link className="navbar-brand" to="/home">
                    <img src="logo.svg" alt="MTA" width="30" height="30" className="mr-2" />
                    MTA NYCT Delay Verification
                </Link>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        <NavItem>
                            <Link className={`nav-link ${this.props.activeCheck === "idAutoverifiedRequests" ? 'active' : ''}`} id="idAutoverifiedRequests" to="/autoverifiedRequest" >Autoverified Requests </Link>
                        </NavItem>
                        <NavItem>
                            <Link className={`nav-link ${this.props.activeCheck === "idOpenRequests" ? 'active' : ''}`} id="idOpenRequests" to="/openRequest" >Open Requests </Link>
                        </NavItem>
                        <NavItem>
                            <Link className={`nav-link ${this.props.activeCheck === "idSearchRequests" ? 'active' : ''}`} id="idSearchRequests" to="/searchDashboard" >Search Requests </Link>
                        </NavItem>
                        <NavItem>
                            <Link className={`nav-link ${this.props.activeCheck === "idNewRequest" ? 'active' : ''}`} id="idNewRequest" to="/newDelayRequest" >New Request </Link>
                        </NavItem>
                        <NavItem>
                            <Link className={`nav-link ${this.props.activeCheck === "idHardCopyRequests" ? 'active' : ''}`} id="idHardCopyRequests" to="/HardCopyEmail" >Hard Copy Requests </Link>
                        </NavItem>
                        <NavItem>
                            <Link className={`nav-link ${this.props.activeCheck === "idUsers" ? 'active' : ''}`} id="idUsers" to="/SearchUsers" >Users </Link>
                        </NavItem>
                        <NavItem>
                            <Link className={`nav-link ${this.props.activeCheck === "idAnalytics" ? 'active' : ''}`} id="idAnalytics" to="/analytics" >Analytics </Link>
                        </NavItem>
                    </Nav>
                    {this.getUserNameNavText()}
                    <button
                        onClick={this.onLogoutClick}
                        type="button"
                        className="btn btn-outline-success ml-2 my-2 my-sm-0 ">
                        Logout
                    </button>
                </Collapse>
            </Navbar>
        )
    }

    private onLogoutClick = () => {
        // setting an invalid value in the user id key to force any other tabs open to logout
        // see PrivateRoute.ts code to see how this is managed
        window.localStorage[constants.APP_CURRENT_USERID_KEY] = -9999;
        window.location.href = getLogoutUrl();
    };

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    private getUserNameNavText = () => {
        if (this.state.userProfile && this.state.userProfile.emailAddress) {
            return (
                <span className="navbar-text">
                    {this.state.userProfile.emailAddress}
                </span>
            );
        } else {
            return (
                <span className="navbar-text">
                </span>
            )
        }
    }

}