import React from "react";
import { IDispositionAuditDto } from "../../../apis/interfaces/IDispositionAuditDto";
import { TransitTypeEnum } from "../../../apis/enums/TransitTypeEnum";
import moment from "moment";

export interface IDispositionAuditCardProps {
    DispositionAuditObj: IDispositionAuditDto;
}

export class DispositionAuditCard extends React.Component<IDispositionAuditCardProps, {}> {

    public render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    {this.props.DispositionAuditObj.dispositionChangedByName}
                </div>
                <div className="col-sm-4">
                    {moment(this.props.DispositionAuditObj.dispositionChangedDate).format('DD-MMM-YYYY h:mma')}
                </div>
                <div className="col-sm-2">
                    {this.props.DispositionAuditObj.oldDispositionDescription}
                </div>
                <div className="col-sm-2">
                    {this.props.DispositionAuditObj.newDispositionDescription}
                </div>
            </div>
        )
    }

}