import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AddEditUserComponent } from "./AddEditUserComponent";
import * as constants from "../../constants";


export class AddNewUserComponent extends React.Component<RouteComponentProps, {}> {
    public render() {
        return (
            <AddEditUserComponent userid={constants.NEW_USER_ID + ""} pathname={this.props.location.pathname}
                isAddNew={true} />
        )
    }
}