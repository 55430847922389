import React from "react";
import { Link } from "react-router-dom";
import { IUserDto } from "../../apis/interfaces/IUserDto";

export interface IUserDataSaveSuccessProps {
    userinfo: IUserDto;
    isAddNew: boolean;
    initializeComponent: () => void;
}
export class UserDataSaveSuccess extends React.Component<IUserDataSaveSuccessProps, {}> {
    public render() {
        const { userinfo } = this.props;
        return (
            <div>
                <h3>User Information Saved Successfully</h3>
                <div className="card mt-2 " >
                    <div className="card-body">
                        <h5 className="card-title">{userinfo.firstName} {userinfo.lastName} {this.getUserStatus()}</h5>
                        <h6 className="card-subtitle mb-2 text-muted">{userinfo.emailAddress}</h6>
                        {this.getRoleList()}
                        {this.getEditButtonOrLink()}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-12">
                        {this.addNewButtonOrLink()}
                    </div>
                </div>
            </div>
        )
    }

    private getRoleList = () => {
        const roleList = this.props.userinfo.roleList.map((rec, index) => {
            return (
                <span className="badge badge-info mr-1" key={index}>{rec.roleName}</span>
            )
        })
        return (
            <div className="card-text mb-2">
                {roleList}
            </div>
        )
    }
    private getUserStatus = () => {
        if (!this.props.userinfo.isActive) {
            return (
                <span className="badge badge-secondary">Inactive</span>
            )
        }
    }

    private getEditButtonOrLink = () => {
        if (this.props.isAddNew) {
            const editUrl = "/editUser/" + this.props.userinfo.id;
            return <Link to={editUrl} className="btn btn-primary" replace={true}>Edit</Link>;
        } else {
            return (
                <button
                    type="button"
                    className="btn btn-primary "
                    onClick={this.props.initializeComponent}
                >Edit</button>
            )
        }
    }


    private addNewButtonOrLink = () => {
        if(this.props.isAddNew){
            return (
                <button
                    type="button"
                    className="btn btn-warning "
                    onClick={this.props.initializeComponent}
                >Add New Record</button>
            )
        } else {
            const addNewUrl = "/addNewUser/";
            return <Link to={addNewUrl} className="btn btn-warning" replace={true}>Add New Record</Link>;
        }
    }
}