import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import "./customBootStrap/customBootStrap.css";
import "./index.css";
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { getWellknownUrl, setAppConfig } from './AppSettings';
import { CircleSpinner } from './pages/common/CircleSpinner';
import { axiosConfig } from './AxiosConfig';

ReactDOM.render(
    <div className="container">
        <div className="mt-3">
            <div className="text-center">
                <CircleSpinner />
                <div id="loading">Loading...</div>
            </div>
        </div>
    </div>
    ,
    document.getElementById('root'));

axiosConfig.configure();
axios.get(getWellknownUrl()).then(res => {
    setAppConfig(res.data);

    ReactDOM.render(
        <div>
            <App />
        </div>
        ,
        document.getElementById('root'));

}).catch(error => {
    const el = document.getElementById('loading');
   if (el) {
      el.innerHTML = error;
      el.className = 'alert alert-danger';
   }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
