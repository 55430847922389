import React from "react";
import { ApiCallStatusEnum } from "../common/enums/ApiCallStatusEnum";
import { IDelayRequestDto } from "../../apis/interfaces/IDelayRequestDto";
import { RouteComponentProps } from "react-router-dom";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import * as apiErrorHandler from "../../apis/ApiErrorHandler";
import * as delayRequestListApi from "../../apis/DelayRequestListApi";
import { CircleSpinner } from "../common/CircleSpinner";
import { ErrorAlert } from "../common/ErrorAlert";
import { RequestListGrid } from "../RequestList/RequestListGrid";

interface IManualVerifyDetailComponentState {
    apiCallStatus: ApiCallStatusEnum;
    VerificationRequests: IDelayRequestDto[];
    errorMessage: string;
}

interface IManualVerifyDetailComponentProps extends RouteComponentProps<{ serviceDate: string }> {

}


export class ManualVerifyDetailComponent extends React.Component<IManualVerifyDetailComponentProps, IManualVerifyDetailComponentState> {
    constructor(props: any){
        super(props);
        this.state = {
            apiCallStatus: ApiCallStatusEnum.NotStarted,
            VerificationRequests: [],
            errorMessage: ""
        }
    }

    public async componentDidMount() {
        if (this.props.match.params.serviceDate) {
            try {
                this.setState({ apiCallStatus: ApiCallStatusEnum.Started, VerificationRequests: [], errorMessage: "" });
                const requestData = await delayRequestListApi.GetManualVerificationDetails(this.props.match.params.serviceDate);
                this.setState({ apiCallStatus: ApiCallStatusEnum.Success, VerificationRequests: requestData, errorMessage: "" });
            } catch (err) {
                const errMsg = apiErrorHandler.GetApiErrorMessage(err);
                this.setState({ apiCallStatus: ApiCallStatusEnum.Failed, VerificationRequests: [], errorMessage: errMsg });
            }
        }
    }

    public render() {
        return (
            <div>
                <NavbarComponent activeCheck="idOpenRequests"/>
                <div className="container-fluid">
                    <div className="mt-4">
                        <h3>Request Pending Manual Verification</h3>
                        <h4>Serivce Date: {this.props.match.params.serviceDate}</h4>
                        {this.renderResults()}
                    </div>
                </div>
            </div>
        )
    }

    public renderResults = () => {
        if (this.state.apiCallStatus === ApiCallStatusEnum.Started) {
            return (
                <CircleSpinner />
            )
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Failed) {
            return <ErrorAlert alertText={this.state.errorMessage} />
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Success && this.state.VerificationRequests) {
            return (
                <RequestListGrid RequestDtoList={this.state.VerificationRequests} />
            );
        }
        return <div></div>
    }
}