import axios from "axios";
import { IDelayRequestDto } from "./interfaces/IDelayRequestDto";
import { IRefNumbersDto } from "./interfaces/IRefNumbersDto";
import { IRequstSummaryByStatusDto } from "./interfaces/IRequstSummaryByStatusDto";
import { IDelayRequestAnalyticsDto } from "./interfaces/IDelayRequestAnalyticsDto";

export async function GetRequests(startDateIso: string, endDateIso: string) {
    const url = `/delay-request-list/GetByRequestDate/?startDate=${startDateIso}&endDate=${endDateIso}`;
    const response = await axios.get<IDelayRequestDto[]>(url);
    return response.data;
}

export async function GetDispositionRequests(startDateIso: string, endDateIso: string) {
    const url = `/delay-request-list/GetByDispositionDate/?startDate=${startDateIso}&endDate=${endDateIso}`;
    const response = await axios.get<IDelayRequestDto[]>(url);
    return response.data;
}

export async function GetRequestsByEmail(emailAddress: string, firstName: string, lastName: string) {
    const url = `/delay-request-list/GetByEmailAddress/?emailAddress=${emailAddress}&firstName=${firstName}&lastName=${lastName}`;
    const response = await axios.get<IDelayRequestDto[]>(url);
    return response.data;
}

export async function GetHardCopy(requestId: string) {
    var refDto: IRefNumbersDto = {
        RefNumbers: requestId.split(",")
    }
    const url = `/delay-request-list/GetHardCopyNotification/`
    const response = await axios.post(url, refDto, { responseType: 'arraybuffer' });
    return response.data;
}

export async function GetManualVerificationSummary() {
    const url = `/delay-request-list/GetManualVerificationSummary`;
    const response = await axios.get<IRequstSummaryByStatusDto[]>(url);
    return response.data;
}

export async function GetManualVerificationDetails(serviceDate: string) {
    const url = `/delay-request-list/GetManualVerificationDetails?serviceDate=${serviceDate}`;
    const response = await axios.get<IDelayRequestDto[]>(url);
    return response.data;
}

export async function GetOpenRequests() {
    const url = `/delay-request-list/GetOpenRequestDetails`;
    const response = await axios.get<IDelayRequestDto[]>(url);
    return response.data;
}

export async function GetHardCopyRequests() {
    const url = `/delay-request-list/GetHardCopyRequestDetails`;
    const response = await axios.get<IDelayRequestDto[]>(url);
    return response.data;
}

export async function GetAutoverifiedRequests() {
    const url = `/delay-request-list/GetAutoverifiedRequestDetails`;
    const response = await axios.get<IDelayRequestDto[]>(url);
    return response.data;
}

export async function GetRequestInfoByDate(startDateIso: string, endDateIso: string) {
    const url = `/delay-request-list/GetRequestInfoByDate/?startDate=${startDateIso}&endDate=${endDateIso}`;
    const response = await axios.get<IDelayRequestAnalyticsDto[]>(url);
    return response.data;
}

