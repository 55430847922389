export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'dvadmin-access-token',
  REFRESH_TOKEN: 'dvadmin-refresh-token',
  OAUTH_CODE_CHALLENGE: 'dvadmin-code-challenge',
  OAUTH_CODE_VERIFIER: 'dvadmin-code-verifier',
  OAUTH_STATE: 'dvadmin-oauth-state'
}
export const ROUTE_PATH = {
  home: '/home',
  login: '/login',
  logout: '/logout',
  openRequest: '/openRequest',
  hardRequest: '/HardCopyEmail',
  searchRequest: '/searchDashboard',
  autoverifiedRequest: '/autoverifiedRequest'
}

export const NEW_USER_ID = 0;
export const APP_CURRENT_USERID_KEY = "app_current_user_id";
export const SKIP_AXIOS_INTERCEPT = "X-SKIP-AXIOS-INTERCEPT";

export const BannerMessage = process.env.REACT_APP_BannerMessage;
export const BaseApiUrl = process.env.REACT_APP_ApiBaseUrl;

export const RequestDispostionList = [
  { value: "1", displayLabel: "New" },
  { value: "2", displayLabel: "Approved" },
  { value: "3", displayLabel: "Denied" },
  { value: "4", displayLabel: "CannotVerify" },
]

export const RequestStatusList = [
  { value: "1", displayLabel: "New" },
  { value: "2", displayLabel: "AutoVerified" },
  { value: "3", displayLabel: "PendingManualVerify" },
  { value: "4", displayLabel: "ManualVerified" },
  { value: "5", displayLabel: "WaitingOpsData" },
]

export const newDispositionId = "1";
export const newRequestStatusId = "1";
export const manualVerifiedRequestStatusId = "4";
export const pendingManualVerifyStatusId = "3";
