import React from "react";
import { IUserRoleDto } from "../../apis/interfaces/IUserRoleDto";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { CircleSpinner } from "../common/CircleSpinner";
import { ErrorAlert } from "../common/ErrorAlert";

import * as userRolesApi from "../../apis/UserRolesApi";
import * as viewUserObjectHandler from "./ViewUserObjectHandler";
import { ViewUserFinalDispositionForm } from "./ViewUserFinalDispositionForm";
import { IViewUserDto } from "./interfaces/IViewUserDto";
import { Link } from "react-router-dom";

export interface IViewUserFinalDispositionComponentProps {
    userid: string;
    pathname: string;
}

interface IViewUserFinalDispositionComponentState {
    UserRoleList: IUserRoleDto[];
    lookupLoadInProgress: boolean;
    lookupLoadSuccess: boolean;
    lookupLoadFailed: boolean;
    UserInfo: IViewUserDto | null
}

export class ViewUserFinalDispositionComponent extends React.Component<IViewUserFinalDispositionComponentProps, IViewUserFinalDispositionComponentState> {
    // do not put nested objects here. If you do make sure to update the code below
    // to avoid mutation
    private intitialState: IViewUserFinalDispositionComponentState = {
        UserRoleList: [],
        UserInfo: null,
        lookupLoadFailed: false,
        lookupLoadInProgress: true,
        lookupLoadSuccess: false,
    };
    constructor(props: IViewUserFinalDispositionComponentProps) {
        super(props);
        this.state = {
            ...this.intitialState
        }
    }
    public async componentWillMount() {
        await this.initializeComponent();
    }

    public render() {
        if (this.state.lookupLoadInProgress) {
            return (
                <div>
                    <NavbarComponent activeCheck="idUsers" />
                    <div className="container mt-2">
                        <CircleSpinner />
                    </div>
                </div>
            )
        } else if (this.state.lookupLoadSuccess && this.state.UserInfo) {
            return (
                <div>
                    <NavbarComponent activeCheck="idUsers" />
                    <div className="container mt-2">
                        {this.renderUserInfo()}

                    </div>
                </div>
            )

        } else {
            return (
                <div>
                    <NavbarComponent activeCheck="idUsers" />
                    <div className="container mt-2">
                        <ErrorAlert alertText="Couldn't connect to the server. Confirm that you are connected to the network" />
                    </div>
                </div>
            )
        }
    }

    private renderUserInfo() {
        if (this.state.UserInfo) {
            return (
                <div>
                    <h4 className="mt-2 mb-2">
                        User Information
                    </h4>
                    <form>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group ">
                                    <label >First Name</label>
                                    <input type="text"
                                        className="form-control"
                                        value={this.state.UserInfo.firstName}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <label >Last Name</label>
                                <input type="text"
                                    className="form-control"
                                    value={this.state.UserInfo.lastName}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <label >Email</label>
                                <input type="text"
                                    className="form-control"
                                    value={this.state.UserInfo.emailAddress}
                                    readOnly={true}
                                />
                            </div>
                            <div className="col-lg-6">
                                <label >BSC ID</label>
                                <input type="text"
                                    className="form-control"
                                    value={this.state.UserInfo.bscId}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <label >Roles</label>
                                <input type="text"
                                    className="form-control"
                                    value={this.state.UserInfo.roleList.map(a => this.state.UserRoleList.filter(b => b.id === a).map(c => c.roleName)).join(',')}
                                    readOnly={true}
                                />
                            </div>
                            <div className="col-lg-6">
                                <label >User Status</label>
                                <input type="text"
                                    className="form-control"
                                    value={this.state.UserInfo.isActive === true ? "Active" : "InActive"}
                                    readOnly={true}
                                />
                            </div>

                        </div>
                        <p />
                        {this.getVerifiedRequestsLinks()}
                    </form>
                </div>
            )
        }

    }

    private getVerifiedRequestsLinks = () => {
        if (this.state.UserInfo !== null && this.state.UserInfo.verifiedRequests.length > 0) {
            var referenceRequestTags = this.state.UserInfo.verifiedRequests
                .map((rec, index) => {
                    var dispositionLink = `/AdminDispositionList/${rec.approvedDate}/${rec.approvedDate}`;
                    return (
                        <div className="card-body" key={index}>
                            <h6 className="card-subtitle mb-2 text-muted"><Link to={dispositionLink}><b> {rec.approvedDate}</b></Link> <span className="badge badge-secondary">{rec.verifiedRequestInfos.length}</span></h6>
                            <ViewUserFinalDispositionForm VerifiedRequests={rec.verifiedRequestInfos} />
                        </div>
                    );
                });

            return (
                // 
                <div className="card">
                    <div className="card-body">
                        <h4 className="mt-2 mb-2"> Recent Finalized Requests</h4>
                        <div className="row mt-4">
                            <div className="col-lg-4 mb-2" >
                                <h6 className="card-subtitle mb-2 text-muted"><b>Approved Count</b> <span className="badge badge-secondary">{this.state.UserInfo.approvedRequestCount}</span></h6>
                            </div>
                            <div className="col-lg-4 mb-2" >
                                <h6 className="card-subtitle mb-2 text-muted"><b>Denied Count</b> <span className="badge badge-secondary">{this.state.UserInfo.deniedRequestCount}</span></h6>
                            </div>
                            <div className="col-lg-4 mb-2" >
                                <h6 className="card-subtitle mb-2 text-muted"><b>Cannot Verify Count</b> <span className="badge badge-secondary">{this.state.UserInfo.cannotVerifyRequestCount}</span></h6>
                            </div>
                        </div>
                    </div>

                    {referenceRequestTags}
                </div>

            )
        }
    }

    private initializeComponent = async () => {
        this.setState({ ...this.intitialState, lookupLoadInProgress: true, lookupLoadFailed: false, lookupLoadSuccess: false });
        try {
            const userRoles = await userRolesApi.GetUserRoleList();
            const userObj = await viewUserObjectHandler.getViewUserObj(this.props.userid);
            this.setState({
                lookupLoadFailed: false,
                lookupLoadSuccess: true,
                lookupLoadInProgress: false,
                UserRoleList: userRoles,
                UserInfo: userObj,
            })
        } catch (error) {
            this.setState({ lookupLoadFailed: true, lookupLoadSuccess: false, lookupLoadInProgress: false })
        }
    }

}