import React from "react";
import { IRequestFormState } from "./interfaces/IRequestFormState";
import * as RequestFormValidator from "./RequestFormValidator";
import { FormInputControl } from "../common/FormInputControl";
import { FormTextAreaControl } from "../common/FormTextAreaControl";
import { FormNumberControl } from "../common/FormNumberControl";
import { RouteAddCard } from "./RouteAddCard";
import { IDelayRouteUi } from "./interfaces/IDelayRouteUi";
import { DelayRouteCardList } from "./DelayRouteList/DelayRouteCardList";
import * as DelayRequestMapper from "./Mappers/DelayRequestMapper";
import * as DelayRequestApi from "../../apis/DelayRequestApi";
import { ErrorAlert } from "../common/ErrorAlert";
import { CircleSpinner } from "../common/CircleSpinner";
import { SuccessMessage } from "./SuccessMessage";
import { ServiceDatePickerControl } from "./Controls/ServiceDatePickerControl";
import * as ApiErrorHandler from "../../apis/ApiErrorHandler";
import { HardCopyAddressInput } from "./HardCopyAddressInput";

export class RequestForm extends React.Component<{}, IRequestFormState> {
    constructor(props: any) {
        super(props);
        this.state = this.getInitState();
    }

    public render() {
        if (this.state.saveApiCallStarted) {
            return (
                <CircleSpinner />
            )
        } if (this.state.saveApiCallSuccess) {
            return (<SuccessMessage referenceNumber={this.state.referenceNum} 
                onAnotherRequestClick ={ this.AnotherRequestClickHandler}
            />)
        } else {
            return (
                <div >
                    {this.state.saveApiCallError && <ErrorAlert alertText={this.state.saveErrorMessage} />}
                    <form>
                        <div className="row">
                            <div className="col-lg-6">
                                <FormInputControl
                                    type="text"
                                    placeHolder=""
                                    label="First Name"
                                    changeHandler={this.handleInputChange("firstName")}
                                    value={this.state.firstName}
                                    isValid={!this.state.isTriedToSubmit || this.state.isFirstNameValid}
                                    autoCompleteHint="given-name"
                                />
                            </div>
                            <div className="col-lg-6">
                                <FormInputControl
                                    type="text"
                                    placeHolder=""
                                    label="Last Name"
                                    changeHandler={this.handleInputChange("lastName")}
                                    value={this.state.lastName}
                                    isValid={!this.state.isTriedToSubmit || this.state.isLastNameValid}
                                    autoCompleteHint="family-name"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <FormInputControl
                                    type="email"
                                    placeHolder=""
                                    label="Email"
                                    changeHandler={this.handleInputChange("emailAddress")}
                                    value={this.state.emailAddress}
                                    isValid={this.state.isEmailValid || this.state.requestingHardCopyResponse}
                                    validationMessage = {this.state.emailAddressValidationMessage}
                                    autoCompleteHint="email"
                                />
                            </div>
                            <div className="col-lg-6">
                                <FormInputControl
                                    type="email"
                                    placeHolder=""
                                    label="Confirm Email"
                                    changeHandler={this.handleInputChange("emailAddressConfirm")}
                                    value={this.state.emailAddressConfirm}
                                    isValid={this.state.isConfirmEmailValid || this.state.requestingHardCopyResponse}
                                    validationMessage={this.state.emailAddressConfirmValidationMessage}
                                    autoCompleteHint="email"
                                />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <ServiceDatePickerControl
                                    label="Date of Service"
                                    value={this.state.dateOfService}
                                    changeHandler={this.handleInputChange("dateOfService")}
                                    isValid={this.state.isServiceDateValid}
                                    disablePicker={this.state.disableDateOfService}
                                    disabledMessage="Cannot change this field once journey legs have been added"
                                    selectablePastDays={181}
                                    validationMessage = {this.state.serviceDateValidation}
                                />
                            </div>
                            <div className="col-lg-6">
                                <FormNumberControl
                                    type="number"
                                    placeHolder=""
                                    label="Total minutes delayed (minimum: 10 mins.)"
                                    changeHandler={this.handleInputChange("lateMinutes")}
                                    value={this.state.lateMinutes+""}
                                    validationMessage="Delay must be 10 minutes or higher"
                                    isValid={this.state.isDelayValid}
                                />
                            </div>
                        </div>
                        <HardCopyAddressInput
                            addressStreet1 = {this.state.addressStreet1}
                            addressStreet2 = {this.state.addressStreet2}
                            addressCity= {this.state.addressCity}
                            addressState = {this.state.addressState}
                            addressZip={this.state.addressZip}
                            requestingHardCopyResponse={this.state.requestingHardCopyResponse}
                            isAddressStreet1Valid = {this.state.isAddressStreet1Valid}
                            isAddressStreet2Valid = {this.state.isAddressStreet2Valid}
                            isAddressCityValid ={this.state.isAddressCityValid}
                            isAddressStateValid = {this.state.isAddressStateValid}
                            isAddressZipValid = {this.state.isAddressZipValid}
                            isTriedToSubmit = {this.state.isTriedToSubmit}
                            handleInputChange = {this.handleInputChange}
                        />
                    </form >
                    <DelayRouteCardList delayRouteList={this.state.routeData}
                        removeLastRouteLeg={this.removeLastRouteLeg} />
                    <div className="row mt-2">
                        <div className="col-lg-6">
                            <RouteAddCard IsAddButtonsEnabled={this.state.isDateOfServiceValid}
                                dateofService={this.state.dateOfService}
                                addToDelayRouteList={this.addToDelayRouteList}
                                isRouteDataValid={!this.state.isTriedToSubmit || this.state.isRouteDataValid}
                                lastDelayRouteRec={this.getLastDelayRoutedRec()}
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-lg-12">
                                <FormTextAreaControl
                                    label="Trip Comments"
                                    rowcount={3}
                                    changeHandler={this.handleInputChange("delayComment")}
                                    value={this.state.delayComment}
                                    isValid={this.state.isUserCommentsValid}
                                    validationMessage="Maximum 1000 characters allowed"
                                />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <button
                                type="button"
                                className="btn btn-primary float-right"
                                onClick={this.submitDelayRequest}
                            >Submit</button>
                        </div>
                    </div>
                    <br></br>
                </div>

            )
        }
    }

    private handleInputChange = (fieldName: string) => (value: any) => {
        if (fieldName === "emailAddress") {
            this.setState({
                isEmailValid: false
            });
        } else if (fieldName === "emailAddressConfirm") {
            this.setState({
                isConfirmEmailValid: false
            });
        } else if (fieldName === "dateOfService") {
            this.setState({
                isServiceDateValid: false
            });
        } else if (fieldName === "lateMinutes") {
            this.setState({
                isDelayValid: false
            });
        } else if (fieldName === "delayComment") {
            this.setState({
                isUserCommentsValid: false
            });
        }
        this.setState({
            [fieldName]: value
        } as any, this.validateFields);
    }

    private validateFields = () => {
        const validationObj = RequestFormValidator.validateRequestForm(this.state);
        this.setState(validationObj);
        if (!this.state.isEmailValid) {
            this.setState({
                isEmailValid: validationObj.isEmailAddressValid
            });
        }
        if (!this.state.isConfirmEmailValid) {
            if (validationObj.isEmailAddressMatchWithConfirm && validationObj.isConfirmEmailAddressValid) {
                this.setState({
                    isConfirmEmailValid: validationObj.isConfirmEmailAddressValid
                });
            }
        }
        if (!this.state.isServiceDateValid) {
            this.setState({
                isServiceDateValid: validationObj.isDateOfServiceValid
            });
        }
        if (!this.state.isDelayValid) {
            this.setState({
                isDelayValid: validationObj.isLateMinutesValid
            });
        }
        if (!this.state.isUserCommentsValid) {
            this.setState({
                isUserCommentsValid: validationObj.isUserCommentsValid
            });
        }
    }

    private addToDelayRouteList = (newTravelRoute: IDelayRouteUi) => {
        this.setState((currentState) => {
            var routeList = [...currentState.routeData];
            newTravelRoute.legNumber = routeList.length + 1;
            routeList.push(newTravelRoute);
            return { routeData: routeList, isRouteDataValid: true, disableDateOfService: true }
        })
    }

    private submitDelayRequest = async () => {
        // Cannot call validateFields() here, due to the asyc nature of setState..
        const validationObj = RequestFormValidator.validateRequestForm(this.state);
        this.setState(validationObj);
        this.setState({
            isEmailValid: validationObj.isEmailAddressValid,
            isServiceDateValid: validationObj.isDateOfServiceValid,
            isDelayValid: validationObj.isLateMinutesValid
        });
        if (validationObj.isConfirmEmailAddressValid && validationObj.isEmailAddressMatchWithConfirm) {
            this.setState({
                isConfirmEmailValid: validationObj.isConfirmEmailAddressValid
            });
        } else {
            this.setState({
                isConfirmEmailValid: false
            })
        }

        if (!validationObj.isFormValid) {
            this.setState({ isTriedToSubmit: true });
            if (!validationObj.isServiceValid && validationObj.isDateOfServiceValid && validationObj.isRouteDataValid){
                this.setState({
                    saveApiCallError: true, saveErrorMessage: "Your delay verification request cannot be submitted for a trip in the future"
                })
            } else if (validationObj.isServiceValid && validationObj.isDateOfServiceValid && validationObj.isRouteDataValid){
                this.setState({
                    saveApiCallError: false, saveErrorMessage: ''
                })
            }
        } else {
            try {
                this.setState({ saveApiCallStarted: true, saveApiCallSuccess: false, saveApiCallError: false, saveErrorMessage: "" });
                var requestToSave = DelayRequestMapper.MapToDelayRequest(this.state);
                var addRequest = await DelayRequestApi.AddDelayRequest(requestToSave);
                this.setState({ saveApiCallStarted: false, saveApiCallSuccess: true, saveApiCallError: false, saveErrorMessage: "", referenceNum: addRequest.referenceNumber });
            } catch (err) {
                const errMsg = ApiErrorHandler.GetApiErrorMessage(err);
                this.setState({ saveApiCallStarted: false, saveApiCallSuccess: false, saveApiCallError: true, saveErrorMessage: errMsg });
            }
        }
    }

    private getLastDelayRoutedRec = () => {
        const routeDateLen = this.state.routeData.length;
        if (routeDateLen > 0) {
            return this.state.routeData[routeDateLen - 1];
        }
        return null;
    }

    private removeLastRouteLeg = () => {
        const newRouteList: IDelayRouteUi[] = [];
        for (let i = 0; i < this.state.routeData.length - 1; i++) {
            newRouteList.push(this.state.routeData[i]);
        }
        this.setState({ routeData: newRouteList });
        if (this.state.routeData.length <= 1){
            this.setState({
                disableDateOfService: false,
                saveApiCallError: false,
                saveErrorMessage: ''
            });
        }
    }

    private AnotherRequestClickHandler = ()=>{
        const newState = this.getInitState();
        this.setState({...newState});
    }

    private getInitState = () => {
        const initState:IRequestFormState =  {
            firstName: "",
            lastName: "",
            emailAddress: "",
            emailAddressConfirm: "",
            dateOfService: undefined,
            lateMinutes: undefined,
            delayComment: "",
            routeData: [],
            addressStreet1: "",
            addressStreet2: "",
            addressCity: "",
            addressState: "",
            addressZip: "",
            requestingHardCopyResponse: false,
            isTriedToSubmit: false,
            isFirstNameValid: false,
            isLastNameValid: false,
            isEmailAddressValid: false,
            isEmailAddressMatchWithConfirm: false,
            emailAddressValidationMessage: "",
            emailAddressConfirmValidationMessage: "",
            isDateOfServiceValid: false,
            isLateMinutesValid: false,
            isRouteDataValid: false,
            isAddressStreet1Valid: false,
            isAddressStreet2Valid: false,
            isAddressCityValid: false,
            isAddressStateValid: false,
            isAddressZipValid: false,
            isFormValid: false,
            saveApiCallStarted: false,
            saveApiCallSuccess: false,
            saveApiCallError: false,
            saveErrorMessage: "",
            disableDateOfService: false,
            referenceNum: undefined,
            isServiceValid: false,
            serviceDateValidation: "",
            isEmailValid: true,
            isConfirmEmailValid: true,
            isServiceDateValid: true,
            isDelayValid: true,
            isConfirmEmailAddressValid: false,
            isUserCommentsValid: true,
        };

        return {...initState};
    }

}