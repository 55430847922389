import React from "react";
import { NavbarComponent } from "../Navbar/NavbarComponent";
import { DelayRequestAnalyticsForm } from "./DelayRequestAnalyticsForm";
import * as DelayRequestListApi from "../../apis/DelayRequestListApi";
import { CircleSpinner } from "../common/CircleSpinner";
import { ErrorAlert } from "../common/ErrorAlert";
import { Link, RouteComponentProps } from "react-router-dom";
import * as ApiErrorHandler from "../../apis/ApiErrorHandler";
import { IDelayRequestAnalyticsDto } from "../../apis/interfaces/IDelayRequestAnalyticsDto";
import moment from "moment";

interface IDelayRequestAnalyticsSearchComponentState {
    requestGetStarted: boolean;
    requestGetFailed: boolean;
    requestGetSuccess: boolean;
    SubmittedRequests: IDelayRequestAnalyticsDto[];
    errorMessage: string;
}

interface IDelayRequestAnalyticsSearchComponentProps extends RouteComponentProps<{ startDate: string, endDate: string }> {

}

export class DelayRequestAnalyticsComponent extends React.Component<IDelayRequestAnalyticsSearchComponentProps, IDelayRequestAnalyticsSearchComponentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            requestGetStarted: false,
            requestGetFailed: false,
            requestGetSuccess: false,
            SubmittedRequests: [],
            errorMessage: "",
        }
    }
    public async componentDidMount() {
        if (this.props.match.params.startDate && this.props.match.params.endDate) {
            try {
                let fromDateString = this.props.match.params.startDate;
                let toDateIsoString = this.props.match.params.endDate;
                this.setState({ requestGetStarted: true, requestGetFailed: false, requestGetSuccess: false, SubmittedRequests: [], errorMessage: "" });
                var requestList = await DelayRequestListApi.GetRequestInfoByDate(fromDateString, toDateIsoString);
                this.setState({ requestGetStarted: false, requestGetFailed: false, requestGetSuccess: true, SubmittedRequests: requestList, errorMessage: "" });
            } catch (err) {
                const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
                this.setState({ requestGetStarted: false, requestGetFailed: true, requestGetSuccess: false, SubmittedRequests: [], errorMessage });
            }
        }
    }
    public render() {
        const toDate = moment().toISOString();
        const fromDate = moment().add(-1, "d").toISOString();
        return (
            <div>
                <NavbarComponent activeCheck="idAnalytics" />
                <div className="container-fluid">
                    <DelayRequestAnalyticsForm onReportGet={this.onReportGet} startDate={fromDate}
                        endDate={toDate} />
                    {this.renderResults()}
                </div>
            </div>
        )
    }

    private onReportGet = async (fromDateString: string, toDateIsoString: string) => {
        try {
            this.setState({ requestGetStarted: true, requestGetFailed: false, requestGetSuccess: false, SubmittedRequests: [], errorMessage: "" });
            var requestList = await DelayRequestListApi.GetRequestInfoByDate(fromDateString, toDateIsoString);
            this.setState({ requestGetStarted: false, requestGetFailed: false, requestGetSuccess: true, SubmittedRequests: requestList, errorMessage: "" });
            localStorage.setItem('delayRequestAnalyticsGrid', `/Analytics/${fromDateString}/${toDateIsoString}`);
        } catch (err) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ requestGetStarted: false, requestGetFailed: true, requestGetSuccess: false, SubmittedRequests: [], errorMessage });
        }

        this.props.history.push(`/Analytics/${fromDateString}/${toDateIsoString}`)
    }

    private renderResults = () => {
        if (this.state.requestGetStarted) {
            return <CircleSpinner />
        } else if (this.state.requestGetFailed) {
            return <ErrorAlert alertText={this.state.errorMessage} />
        } else if (this.state.requestGetSuccess) {
            return (this.getRequestsLinks()
            );
        }
        return <div></div>

    }

    private getRequestsLinks = () => {
        if (this.state.SubmittedRequests.length > 0) {
            var referenceRequestTags = this.state.SubmittedRequests
                .map((rec, index) => {
                    var requestDateLink = `/RequestList/${moment(rec.requestDate).toISOString()}/${moment(rec.requestDate).toISOString()}`;
                    return (
                        <div className="card-body" key={index}>
                            <h5 className="card-subtitle mb-2 text-muted"><Link to={requestDateLink}><b>{moment(rec.requestDate).format("MM-DD-yyyy")}</b></Link></h5>
                            <div className="row">
                                <div className="col-lg-3">Total Requests: {rec.totalRequests}</div>
                                <div className="col-lg-3">AutoVerified: {rec.autoVerifiedrequests}</div>
                                <div className="col-lg-3">Manual Verified: {rec.manualVerifiedRequests}</div>
                                <div className="col-lg-3">Hard Copy Count: {rec.hardCopyRequests}</div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">Open Request Count: {rec.pendingManualRequests}</div>
                                <div className="col-lg-3">Waiting OPS Count: {rec.waitingOPSDataRequests}</div>
                                <div className="col-lg-3">Email Sent Count: {rec.emailSentRequests}</div>
                                <div className="col-lg-3">New/Unprocessed Requests: {rec.newRequests}</div>
                            </div>
                        </div>
                    );
                });

            return (
                <div className="card">
                    {referenceRequestTags}
                </div>

            )
        } else if (this.state.SubmittedRequests.length === 0 && this.state.requestGetSuccess === true) {
            return(
                <div className="row">
                    There are no requests submitted during specified dates
                </div>
            )
        }
    }
}