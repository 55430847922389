import React from "react";
import { IUserDto } from "../../apis/interfaces/IUserDto";
import { Link } from "react-router-dom";

export interface IUserCardProps {
    userInfo: IUserDto;
}

export class UserCard extends React.Component<IUserCardProps, {}> { 

    public render(){
        const userInfo = this.props.userInfo;
        const editUrl = "/editUser/" + this.props.userInfo.id;
        return (
            <div className="card mt-2 " >
            <div className="card-body">
                <h5 className="card-title">{userInfo.firstName} {userInfo.lastName} {this.getUserStatus()}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{userInfo.emailAddress}</h6>
                {this.getRoleList()}
                <Link to={editUrl} className="btn btn-primary" replace={true}>Edit</Link>
                {this.getUserVerifiedRequests()}
            </div>
        </div>
        )
    }

    private getUserStatus = () => {
        if (!this.props.userInfo.isActive) {
            return (
                <span className="badge badge-secondary">Inactive</span>
            )
        }
    }

    private getUserVerifiedRequests = () => {
        if (this.props.userInfo.verifiedRequests.length > 0 || this.props.userInfo.hasVerifiedRequests === true) {
        const viewUrl = "/viewUser/" + this.props.userInfo.id;
            return (
                <Link to={viewUrl} style={{ marginLeft: 5 }} className="btn btn-primary" replace={true}>View Submitted Disposition</Link>
            )
        }
    }

    private getRoleList = () => {
        const roleList = this.props.userInfo.roleList.map((rec, index) => {
            return (
                <span className="badge badge-info mr-1" key={index}>{rec.roleName}</span>
            )
        })
        return (
            <div className="card-text mb-2">
                {roleList}
            </div>
        )
    }

}