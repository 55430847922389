import axios from "axios";
import { IBusTrainStop } from "./interfaces/IBusTrainStop";

export async function getStops(routeid: string,  serviceDate: string) {
    const url = `/Stops/list?routeId=${routeid}&date=${serviceDate}`;
    const response = await axios.get<IBusTrainStop[]>(url);
    return response.data;
}

export async function getNearbyStops(routeid: string, serviceDate: string, lon: number, lat: number) {
    const url = `/Stops/list-nearby?routeId=${routeid}&date=${serviceDate}&lon=${lon}&lat=${lat}`;
    const response = await axios.get<IBusTrainStop[]>(url);
    return response.data;
}
